<div
  class="flex items-center p-4 mb-4 text-sm text-red-900 bg-red-100 rounded-lg"
  [class.!bg-orange-100]="isWarning"
  [class.!text-orange-900]="isWarning"
  role="alert"
>
  <mat-icon class="mr-2 shrink-0">info</mat-icon>
  <span class="sr-only">Alert</span>

  <div>
    <ng-content></ng-content>
  </div>
</div>
