import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],
})
export class GetStartedComponent implements OnInit {
  @Input() iconName: string = 'feedback';
  @Input() description: string | undefined;
  @Input() buttonText: string | undefined;

  @Output() takeAction = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  handleClick() {
    this.takeAction.emit();
  }
}
