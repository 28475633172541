import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from '../../auth/auth.state';
import { CompanyFeature } from '../../auth/interfaces/company-feature.enum';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard implements CanActivate, CanActivateChild {
  private store = inject(Store);
  private router = inject(Router);

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (!route.data) {
      return true;
    }

    const features = route.data['features'];
    if (!features) return true;

    const allowedFeatures = this.store.selectSnapshot(AuthState.features);

    const canAccessFeature = features.some((feature: CompanyFeature) =>
      (allowedFeatures || []).includes(feature)
    );

    if (!canAccessFeature) {
      this.router.navigate(['not-found'], { skipLocationChange: true });
      return false;
    }

    return true;
  }
}
