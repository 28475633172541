<app-login-screen>
  <!-- generate OTP form (email and password) -->
  <form
    *ngIf="!generatedOtp"
    [formGroup]="loginForm"
    (ngSubmit)="generateOtp()"
    class="space-y-4"
  >
    <app-login-intro>
      <ng-container slot="title">Welcome</ng-container>
      <ng-container slot="subtitle">It's great to see you again</ng-container>
    </app-login-intro>

    <mat-form-field>
      <mat-label>{{ "LOGIN.EMAIL" | translate }}</mat-label>
      <input
        id="email"
        type="text"
        matInput
        formControlName="email"
        [placeholder]="'LOGIN.EMAIL_PLACEHOLDER' | translate"
        spellcheck="false"
      />
      <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
        {{ "LOGIN.EMAIL_REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "LOGIN.PASSWORD" | translate }}</mat-label>
      <input
        id="password"
        type="password"
        matInput
        formControlName="password"
      />
      <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
        {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>

    <app-api-error [error]="authError$ | async"></app-api-error>

    <div class="flex items-center justify-between">
      <a mat-button [routerLink]="'/auth/forgot-password'">
        {{ "LOGIN.FORGOT_PASSWORD" | translate }}
      </a>
      <button
        id="login-button"
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="generatingOtp$ | async"
      >
        <span>{{ "LOGIN.SIGN_IN" | translate }}</span>
        <app-loading-spinner
          *ngIf="generatingOtp$ | async"
        ></app-loading-spinner>
      </button>
    </div>

    <ng-container
      *ngIf="(details$ | async)?.entrypoint && (details$ | async) as details"
    >
      <a
        *ngIf="details['entrypoint']"
        href="/api/v1/auth/saml/login"
        class="block mt-4"
      >
        <div
          *ngIf="details.samlLogo; else defaultSaml"
          class="flex justify-center h-10"
        >
          <img src="{{ makeSamlLogo(details.samlLogo) }}" />
        </div>
        <ng-template #defaultSaml>
          <div
            matRipple
            class="px-4 py-2 text-sm text-center text-white no-underline border rounded bg-primary-500"
          >
            <ng-container *ngIf="details.samlName; else defaultSamlText">
              Sign in with {{ details.samlName }}
            </ng-container>
            <ng-template #defaultSamlText> Single Sign-On </ng-template>
          </div>
        </ng-template>
      </a>
    </ng-container>
  </form>

  <!-- enter OTP form (OTP code) -->
  <form
    *ngIf="generatedOtp"
    [formGroup]="otpForm"
    (ngSubmit)="logIn()"
    class="space-y-4"
  >
    <app-login-intro>
      <ng-container slot="title">Enter One Time PIN</ng-container>
      <ng-container slot="subtitle">{{
        "LOGIN.OTP_INSTRUCTIONS" | translate
      }}</ng-container>
    </app-login-intro>

    <mat-form-field>
      <mat-label>{{ "LOGIN.OTP" | translate }}</mat-label>
      <input
        id="otp-field"
        type="text"
        matInput
        formControlName="otpCode"
        [mask]="otpMask"
        (ngModelChange)="transformOtp()"
        placeholder="123-456"
      />
      <mat-error *ngIf="otpForm.controls['otpCode'].hasError('required')">
        {{ "LOGIN.OTP_REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>

    <app-api-error [error]="authError$ | async"></app-api-error>

    <div class="flex items-center justify-between">
      <button
        id="otp-button"
        mat-button
        type="button"
        (click)="generateOtp()"
        [disabled]="generatingOtp$ | async"
      >
        <span>{{ "LOGIN.RESEND_OTP" | translate }}</span>
        <app-loading-spinner
          *ngIf="generatingOtp$ | async"
        ></app-loading-spinner>
      </button>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="loggingIn$ | async"
      >
        <span>{{ "LOGIN.SIGN_IN" | translate }}</span>
        <app-loading-spinner *ngIf="loggingIn$ | async"></app-loading-spinner>
      </button>
    </div>
  </form>
</app-login-screen>
