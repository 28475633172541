import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { PaymentInformation } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { ChangeRequest } from '../interfaces/change-request.interface';
import {
  PaymentInformationCreate,
  PaymentInformationUpdate,
} from '../interfaces/payment-information.interface';
import { uploadSupportingDocumentation } from '../util/supporting-docs-upload';
import { UpsertChangeRequests } from './change-request.state';

export interface PaymentInformationStateModel extends BaseStateModel {
  paymentInformation: PaymentInformation[];
  bankNames: string[];
}

export class LoadPaymentInformation {
  static readonly type = '[PaymentInformation] LoadPaymentInformation';
}

export class LoadBankNames {
  static readonly type = '[PaymentInformation] LoadBankNames';
}

export class UpdatePaymentInformation {
  static readonly type = '[PaymentInformation] UpdatePaymentInformation';
  constructor(
    public paymentInformation: PaymentInformationUpdate,
    public files: File[] = []
  ) {}
}

export class CreatePaymentInformation {
  static readonly type = '[PaymentInformation] CreatePaymentInformation';
  constructor(
    public paymentInformation: PaymentInformationCreate,
    public files: File[] = []
  ) {}
}

export class DeletePaymentInformation {
  static readonly type = '[PaymentInformation] DeletePaymentInformation';
  constructor(public paymentInformationId: string, public files: File[] = []) {}
}

@State<PaymentInformationStateModel>({
  name: 'paymentInformation',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    paymentInformation: [],
    bankNames: [],
  },
})
@Injectable()
export class PaymentInformationState {
  http = inject(HttpClient);

  @Selector()
  static loading(state: PaymentInformationStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: PaymentInformationStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: PaymentInformationStateModel) {
    return state.error;
  }

  @Selector()
  static paymentInformation(state: PaymentInformationStateModel) {
    return state.paymentInformation;
  }

  @Selector()
  static bankNames(state: PaymentInformationStateModel) {
    return state.bankNames;
  }

  @Action(LoadPaymentInformation)
  async loadPaymentInformation(
    ctx: StateContext<PaymentInformationStateModel>
  ) {
    ctx.patchState({ loading: true });
    try {
      const paymentInformation = await lastValueFrom(
        this.http.get<PaymentInformation[]>(
          `${environment.apiUrl}/v1/payment-information`
        )
      );

      const bankNames = await lastValueFrom(
        this.http.get<string[]>(
          `${environment.apiUrl}/v1/payment-information/bank-names`
        )
      );

      ctx.patchState({
        paymentInformation: paymentInformation,
        bankNames: bankNames,
      });
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(UpdatePaymentInformation)
  async updatePaymentInformation(
    ctx: StateContext<PaymentInformationStateModel>,
    action: UpdatePaymentInformation
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await delayRequest(
        lastValueFrom(
          this.http.put<ChangeRequest>(
            `${environment.apiUrl}/v1/payment-information/${action.paymentInformation.id}`,
            action.paymentInformation
          )
        )
      );

      uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(CreatePaymentInformation)
  async createPaymentInformation(
    ctx: StateContext<PaymentInformationStateModel>,
    action: CreatePaymentInformation
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await delayRequest(
        lastValueFrom(
          this.http.post<ChangeRequest>(
            `${environment.apiUrl}/v1/payment-information`,
            action.paymentInformation
          )
        )
      );

      uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(DeletePaymentInformation)
  async deletePaymentInformation(
    ctx: StateContext<PaymentInformationStateModel>,
    action: DeletePaymentInformation
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await delayRequest(
        lastValueFrom(
          this.http.delete<ChangeRequest>(
            `${environment.apiUrl}/v1/payment-information/${action.paymentInformationId}`
          )
        )
      );

      uploadSupportingDocumentation(action, changeRequest, this.http);
      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }
}
