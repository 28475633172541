import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, firstValueFrom, map, tap } from 'rxjs';
import { matches } from '../../../shared/form-validators/matches.validator';
import { passwordValidator } from '../../../shared/form-validators/password.validator';
import { AuthState, ResetPassword } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  authError$ = new BehaviorSubject(null);

  @Select(AuthState.resettingPassword)
  resettingPassword$!: Observable<boolean>;

  hasResetPassword = false;

  email: string | null = null;
  email$ = this.route.queryParamMap.pipe(
    map((params) => params.get('email')),
    tap((email) => (this.email = email))
  );
  token$ = this.route.paramMap.pipe(map((params) => params.get('token')));

  resetForm = new FormGroup(
    {
      password: new FormControl(
        '',
        [Validators.required],
        [passwordValidator(async () => firstValueFrom(this.email$))]
      ),
      passwordRepeat: new FormControl('', [Validators.required]),
    },
    {
      validators: [matches('password', 'passwordRepeat')],
    }
  );

  constructor(private store: Store, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  async resetPassword() {
    this.resetForm.markAsTouched();
    this.resetForm.markAsDirty();
    if (!this.resetForm.valid) return;

    const token = await firstValueFrom(this.token$);

    this.store
      .dispatch(
        new ResetPassword({
          password: this.resetForm.value.password as string,
          token: token as string,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.hasResetPassword = true;
          this.authError$.next(null);
        },
        error: (err) => {
          this.authError$.next(err);
        },
      });
  }
}
