<app-login-screen>
  <app-login-intro>
    <ng-container slot="title">Let's get you started!</ng-container>
    <ng-container slot="subtitle"
      >Enter your new login credentials below</ng-container
    >
  </app-login-intro>
  <form
    [formGroup]="registerForm"
    (ngSubmit)="register()"
    *ngIf="!hasRegistered"
    class="space-y-4"
  >
    <mat-form-field>
      <mat-label>{{ "LOGIN.EMAIL" | translate }}</mat-label>
      <input type="email" disabled matInput [value]="email$ | async" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        id="name"
        type="text"
        matInput
        formControlName="contactName"
        placeholder="E.g. John Doe"
      />
      <mat-error
        *ngIf="registerForm.controls['contactName'].hasError('required')"
      >
        This field is required
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "LOGIN.PASSWORD" | translate }}</mat-label>
      <input
        id="password"
        type="password"
        [maxlength]="72"
        matInput
        formControlName="password"
      />
      <mat-error *ngIf="registerForm.controls['password'].hasError('required')">
        {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>

    <app-password-strength
      [zxcvbn]="registerForm.controls['password'].getError('zxcvbn')"
      [password]="registerForm.controls['password'].value"
    ></app-password-strength>

    <mat-form-field>
      <mat-label>{{ "LOGIN.CONFIRM_PASSWORD" | translate }}</mat-label>
      <input
        id="confirm-password"
        type="password"
        [maxlength]="72"
        matInput
        formControlName="passwordRepeat"
      />
      <mat-error *ngIf="registerForm.controls['password'].hasError('required')">
        {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="tos" id="tos-checkbox"
      >I have read and agree to the
      <button class="underline" (click)="showDisclaimer()">
        disclaimer
      </button></mat-checkbox
    >
    <mat-checkbox formControlName="privacy" id="privacy-checkbox"
      >I have read and agree to the
      <button class="underline" (click)="showPrivacyPolicy()">
        privacy policy
      </button></mat-checkbox
    >

    <app-error
      class="mt-4"
      *ngIf="
        registerForm.touched &&
        registerForm.dirty &&
        (registerForm.controls['tos'].hasError('required') ||
          registerForm.controls['tos'].hasError('requiredTrue') ||
          registerForm.controls['privacy'].hasError('required') ||
          registerForm.controls['privacy'].hasError('requiredTrue'))
      "
    >
      Please agree to the License Agreements
    </app-error>

    <app-api-error [error]="authError$ | async"></app-api-error>
    <app-error
      *ngIf="
        registerForm.touched &&
        registerForm.dirty &&
        registerForm.hasError('matches')
      "
    >
      {{ "LOGIN.PASSWORDS_DO_NOT_MATCH" | translate }}</app-error
    >

    <div class="flex items-center justify-end">
      <button
        id="register-button"
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="registering$ | async"
      >
        <span>
          {{ "LOGIN.REGISTER" | translate }}
        </span>
        <app-loading-spinner *ngIf="registering$ | async"></app-loading-spinner>
      </button>
    </div>
  </form>

  <div *ngIf="hasRegistered">
    <p class="pb-4">
      {{ "LOGIN.REGISTRATION_SUCCESS" | translate }}
    </p>
    <a
      mat-flat-button
      color="primary"
      [routerLink]="['/auth/login']"
      class="w-full"
      >{{ "LOGIN.SIGN_IN" | translate }}</a
    >
  </div>
</app-login-screen>
