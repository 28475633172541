<div class="relative flex items-center w-full h-full overflow-hidden">
  <!-- left bg panel -->
  <div
    class="relative flex-col items-center justify-center flex-grow hidden w-1/2 h-full p-8 bg-left bg-cover sm:flex"
    style="background-image: url('assets/images/login-splash.jpg')"
  >
    <!-- gradient -->
    <div
      class="absolute top-0 bottom-0 left-0 right-0 z-0 opacity-80 bg-gradient-to-tr from-blue-900 to-red-300"
    ></div>

    <div class="relative z-10 w-full max-w-xl text-white drop-shadow">
      <!-- hero text -->
      <div class="text-4xl font-bold">
        Empowering Employee Rewards and Beyond Work-Life
      </div>
      <p class="mt-4 leading-7">
        An intuitive solution that removes organizational complexities related
        to data collection from multiple sources and consolidates it into one
        single, easy-to-use portal
      </p>
    </div>
  </div>

  <!-- right panel with login -->
  <div class="relative h-full overflow-y-auto bg-white sm:w-1/2">
    <div
      class="flex flex-col items-center justify-center w-full min-h-full p-8"
    >
      <!-- logos -->
      <div
        *ngIf="logos"
        class="flex items-center justify-center flex-shrink-0 w-full h-24 mb-16 space-x-8"
      >
        <app-company-logo
          *ngIf="details$ | async as details; else defaultLogos"
          [companyId]="details.id"
        ></app-company-logo>
        <ng-template #defaultLogos>
          <img src="assets/images/logo.svg" alt="logo" class="h-10 md:h-14" />
          <img
            src="assets/images/logo-bwl.svg"
            alt="logo"
            class="h-10 md:h-14"
          />
        </ng-template>
      </div>

      <div class="flex-shrink-0 w-full max-w-md bg-white">
        <!-- content -->
        <ng-content></ng-content>
      </div>

      <!-- company-specific details -->
      <div
        *ngIf="details$ | async as details"
        class="mt-20 text-sm text-center text-gray-700"
      >
        <p *ngIf="details.email">
          <a href="mailto:{{ details.email }}" target="_blank">{{
            details.email
          }}</a>
        </p>
        <p *ngIf="details.contactNumber">
          <a href="tel:{{ details.contactNumber }}" target="_blank">{{
            details.contactNumber
          }}</a>
        </p>
        <p *ngIf="details.website">
          <a [href]="details.website" target="_blank" rel="nofollow">{{
            details.website
          }}</a>
        </p>
      </div>

      <!-- copyright -->
      <p class="flex-shrink-0 mt-20 text-xs text-center text-gray-500">
        {{ "copyright" | appConfig | async }}<br />
        <button (click)="showDisclaimer()" class="underline">
          View disclaimer
        </button>
      </p>
    </div>
  </div>
</div>
