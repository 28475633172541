import { NgZone, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { DateTime } from 'luxon';

export const DEFAULT_START_DATE = DateTime.now()
  .minus({ months: 12 })
  .plus({ days: 1 })
  .startOf('day');

export type GlobalDateRangeGranularity =
  | 'PAY_PERIOD'
  | 'MONTH'
  | 'WEEK'
  | 'FINANCIAL_YEAR';

export class SetGlobalDateRange {
  static readonly type = '[Global Date Range] Set';
  constructor(
    public payload: {
      startDate?: Date;
      endDate?: Date;
      granularity?: GlobalDateRangeGranularity;
    }
  ) {}
}

export type GlobalDateRangeStateModel = {
  startDate: Date;
  endDate: Date;
  granularity: GlobalDateRangeGranularity;
};

@State<GlobalDateRangeStateModel>({
  name: 'globalDateRange',
  defaults: {
    startDate: DEFAULT_START_DATE.toJSDate(),
    endDate: DateTime.now().endOf('day').toJSDate(),
    granularity: 'PAY_PERIOD',
  },
})
export class GlobalDateRangeState {
  store = inject(Store);
  router = inject(Router);
  ngZone = inject(NgZone);

  @Selector()
  static startDate(state: GlobalDateRangeStateModel) {
    return state.startDate;
  }

  @Selector()
  static endDate(state: GlobalDateRangeStateModel) {
    return state.endDate;
  }

  @Selector()
  static granularity(state: GlobalDateRangeStateModel) {
    return state.granularity;
  }

  constructor() {
    // synchronize the global date range with query params on load
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.syncWithQueryParams();
      }
    });
  }

  syncWithQueryParams() {
    const queryParams = this.router.parseUrl(this.router.url).queryParams;

    this.store.dispatch(
      new SetGlobalDateRange({
        startDate: queryParams['startDate']
          ? new Date(queryParams['startDate'])
          : undefined,
        endDate: queryParams['endDate']
          ? new Date(queryParams['endDate'])
          : undefined,
        granularity: queryParams['granularity'] as GlobalDateRangeGranularity,
      })
    );
  }

  @Action(SetGlobalDateRange)
  setGlobalDateRange(
    ctx: StateContext<GlobalDateRangeStateModel>,
    action: SetGlobalDateRange
  ) {
    const state = ctx.getState();
    ctx.patchState({
      startDate: action.payload.startDate ?? state.startDate,
      endDate: action.payload.endDate ?? state.endDate,
      granularity: action.payload.granularity ?? state.granularity,
    });

    // patch query params
    this.ngZone.run(() => {
      this.router.navigate([], {
        queryParams: {
          startDate: action.payload.startDate?.toISOString(),
          endDate: action.payload.endDate?.toISOString(),
          granularity: action.payload.granularity,
        },
        queryParamsHandling: 'merge',
      });
    });
  }
}
