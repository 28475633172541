import { Component, Input, OnInit } from '@angular/core';
import { AppRoute } from '../../../../routes';

@Component({
  selector: 'app-sidebar-item-predefined',
  templateUrl: './sidebar-item-predefined.component.html',
  styleUrls: ['./sidebar-item-predefined.component.scss'],
})
export class SidebarItemPredefinedComponent implements OnInit {
  @Input() route!: AppRoute;

  constructor() {}

  ngOnInit(): void {}
}
