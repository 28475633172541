import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { AuthState } from '../../auth/auth.state';
import { UserRole } from '../../auth/interfaces/user-role.enum';

@Pipe({
  name: 'hasPermission',
})
export class HasPermissionPipe implements PipeTransform {
  private role$ = this.store.select(AuthState.role);
  constructor(private store: Store) {}

  transform(roles: UserRole[] | null | undefined): Observable<boolean> {
    return this.role$.pipe(
      map((role) => !roles?.length || roles.includes(role))
    );
  }
}
