import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BaseStateModel,
  BASE_STATE_DEFAULTS,
} from '../../interfaces/base-state-model.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { GlobalWorkLifeBenefit } from '../interfaces/global-work-life-benefit.interface';

export class LoadGlobalWorkLifeBenefits {
  static readonly type = '[GlobalWorkLifeBenefits] Load GlobalWorkLifeBenefits';
}

export class SaveGlobalWorkLifeBenefits {
  static readonly type = '[GlobalWorkLifeBenefits] Save GlobalWorkLifeBenefits';
  constructor(public globalWorkLifeBenefits: GlobalWorkLifeBenefit[]) {}
}

export interface GlobalWorkLifeBenefitsStateModel extends BaseStateModel {
  globalWorkLifeBenefits: GlobalWorkLifeBenefit[];
}

@State<GlobalWorkLifeBenefitsStateModel>({
  name: 'globalWorkLifeBenefits',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    globalWorkLifeBenefits: [],
  },
})
@Injectable()
export class GlobalWorkLifeBenefitsState {
  @Selector()
  static globalWorkLifeBenefits(state: GlobalWorkLifeBenefitsStateModel) {
    return state.globalWorkLifeBenefits;
  }

  @Selector()
  static loading(state: GlobalWorkLifeBenefitsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: GlobalWorkLifeBenefitsStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: GlobalWorkLifeBenefitsStateModel) {
    return state.error;
  }

  constructor(private http: HttpClient) {}

  @Action(LoadGlobalWorkLifeBenefits)
  async loadGlobalWorkLifeBenefits(
    ctx: StateContext<GlobalWorkLifeBenefitsStateModel>
  ) {
    try {
      ctx.patchState({
        loading: true,
      });

      const res = await lastValueFrom(
        this.http.get<GlobalWorkLifeBenefit[]>(
          `${environment.apiUrl}/v1/global-non-monetary-benefits`
        )
      );

      ctx.patchState({
        globalWorkLifeBenefits: res,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  @Action(SaveGlobalWorkLifeBenefits)
  async saveGlobalWorkLifeBenefits(
    ctx: StateContext<GlobalWorkLifeBenefitsStateModel>,
    action: SaveGlobalWorkLifeBenefits
  ) {
    try {
      ctx.patchState({
        loading: true,
      });

      const res = await delayRequest(
        lastValueFrom(
          this.http.put<GlobalWorkLifeBenefit[]>(
            `${environment.apiUrl}/v1/global-non-monetary-benefits`,
            action.globalWorkLifeBenefits
          )
        )
      );

      ctx.patchState({
        globalWorkLifeBenefits: res,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }
}
