import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { externalUrls } from '../../../../environments/constants';
import { AuthState } from '../../auth.state';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss'],
})
export class LoginScreenComponent implements OnInit {
  @Input() logos = true;
  loading = true;

  store = inject(Store);

  details$ = this.store.select(AuthState.publicCompanyDetails);

  constructor(private matDialog: MatDialog, private http: HttpClient) {}

  ngOnInit(): void {}

  showDisclaimer() {
    window.open(externalUrls.disclaimerUrl, '_blank');
  }
}
