import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PaidTimeOffUnit } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GlobalDateRangeState } from '../../global-date-range.state';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import {
  mapCreatedUpdated,
  mostRecentUpdateOfCollection,
} from '../../interfaces/base.interface';
import { isWithinPeriod } from '../../shared/util/is-within-period.util';
import { PaidTimeOff } from '../interfaces/paid-time-off.interface';
import {
  PaidTimeOffConfigState,
  PaidTimeOffConfigStateModel,
} from './paid-time-off-config.state';

export class LoadPaidTimeOff {
  static readonly type = '[PaidTimeOff] Load Paid Time Off';
}

export interface PaidTimeOffStateModel extends BaseStateModel {
  pto: PaidTimeOff[];
}

@State<PaidTimeOffStateModel>({
  name: 'paidTimeOff',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    pto: [],
  },
})
@Injectable()
export class PaidTimeOffState {
  @Selector()
  static loading(state: PaidTimeOffStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: PaidTimeOffStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: PaidTimeOffStateModel) {
    return state.error;
  }

  @Selector()
  static paidTimeOff(state: PaidTimeOffStateModel) {
    return (state.pto || []).slice().sort((a, b) => {
      const rankDiff =
        (a.paidTimeOffConfig?.rank || 0) - (b.paidTimeOffConfig?.rank || 0);
      if (rankDiff !== 0) {
        return rankDiff;
      }
      return b.amount - a.amount || b.annualEntitlement - a.annualEntitlement;
    });
  }

  @Selector([
    PaidTimeOffState.paidTimeOff,
    GlobalDateRangeState.startDate,
    GlobalDateRangeState.endDate,
  ])
  static selectionPaidTimeOff(
    state: PaidTimeOffStateModel,
    pto: PaidTimeOff[],
    startDate: Date,
    endDate: Date
  ) {
    return pto.filter((pto) => {
      return isWithinPeriod({
        eventStartDate: pto.startDate,
        eventEndDate: pto.endDate,
        periodStartDate: startDate,
        periodEndDate: endDate,
      });
    });
  }

  @Selector([PaidTimeOffState.selectionPaidTimeOff])
  static selectionPaidTimeOffTotal(
    state: PaidTimeOffStateModel,
    pto: PaidTimeOff[]
  ) {
    return pto.reduce((acc, curr) => acc + curr.amount, 0);
  }

  @Selector([PaidTimeOffConfigState.config])
  static paidTimeOffUnit(
    state: PaidTimeOffStateModel,
    config: PaidTimeOffConfigStateModel['config']
  ) {
    return (
      state.pto[0]?.paidTimeOffConfig?.unit ??
      config?.at(0)?.unit ??
      PaidTimeOffUnit.DAYS
    );
  }

  @Selector([PaidTimeOffState.paidTimeOff])
  static activePaidTimeOff(state: PaidTimeOffStateModel, pto: PaidTimeOff[]) {
    return pto.filter(
      (pto) => pto.startDate <= new Date() && new Date() <= pto.endDate
    );
  }

  @Selector([PaidTimeOffState.paidTimeOff])
  static lastUpdated(state: PaidTimeOffStateModel, pto: PaidTimeOff[]) {
    return mostRecentUpdateOfCollection(pto);
  }

  @Selector([PaidTimeOffState.activePaidTimeOff])
  static total(state: PaidTimeOffStateModel, pto: PaidTimeOff[]) {
    return pto.reduce((acc, p) => acc + p.amount, 0);
  }

  @Selector([PaidTimeOffState.activePaidTimeOff])
  static totalEntitlement(state: PaidTimeOffStateModel, pto: PaidTimeOff[]) {
    return pto.reduce((acc, p) => acc + p.annualEntitlement, 0);
  }

  constructor(private http: HttpClient) {}

  @Action(LoadPaidTimeOff)
  async loadPaidTimeOff(ctx: StateContext<PaidTimeOffStateModel>) {
    try {
      ctx.patchState({
        loading: true,
        error: null,
      });

      const pto = await lastValueFrom(
        this.http.get<any[]>(`${environment.apiUrl}/v1/paid-time-off`)
      );

      const ptoMapped: PaidTimeOff[] = pto.map((pto) => ({
        ...pto,
        startDate: new Date(pto.startDate),
        endDate: new Date(pto.endDate),
        ...mapCreatedUpdated(pto),
      }));

      ctx.patchState({
        pto: ptoMapped,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }
}
