<footer class="pt-6 mt-16 text-sm text-gray-700 bg-gray-50 print:hidden">
  <div
    class="container flex flex-col items-center max-w-5xl px-8 md:flex-row md:justify-between"
  >
    <!-- logo -->
    <div class="w-40 mb-12 md:mb-0">
      <app-logo [dark]="false"></app-logo>
    </div>
    <div class="space-y-2">
      <!-- links -->
      <div class="flex flex-row justify-end space-x-4 flex-nowrap">
        <a
          class="text-gray-700 no-underline hover:underline"
          href="{{ urls.guideUrl }}"
          target="_blank"
          >Guide</a
        >
        <a
          class="text-gray-700 no-underline hover:underline"
          href="{{ urls.faqUrl }}"
          target="_blank"
          >FAQs</a
        >
        <a
          class="text-gray-700 no-underline cursor-pointer hover:underline"
          [routerLink]="null"
          (click)="helpService.openHelpDialog()"
          >Help</a
        >
        <a
          href="{{ urls.privacyPolicyUrl }}"
          class="text-gray-700 no-underline cursor-pointer hover:underline"
          target="_blank"
          >Privacy Policy</a
        >
        <a
          href="{{ urls.disclaimerUrl }}"
          class="text-gray-700 no-underline cursor-pointer hover:underline"
          target="_blank"
          >Disclaimer</a
        >
      </div>
      <!-- social links -->
      <div class="flex items-center justify-end space-x-4">
        <a
          href="https://www.linkedin.com/showcase/360-suite"
          target="_blank"
          class="text-gray-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
            />
          </svg>
        </a>

        <a
          href="https://www.epiuse.com/360-suite"
          target="_blank"
          class="w-5 h-5 text-gray-500"
        >
          <mat-icon class="w-5 h-5 leading-5 text-center">language</mat-icon>
        </a>
      </div>
    </div>
  </div>
  <div
    class="border-t-[1px] border-gray-200 mt-8 py-4 text-center text-gray-600"
  >
    <!-- copyright -->
    <div>&copy; {{ year }} EPI-USE&trade;. All rights reserved.</div>
  </div>
</footer>
