import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import { ZxcvbnResult, zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';

let setupPromise: Promise<void> | null = null;
export function setUpZxcvbn() {
  if (setupPromise) return setupPromise;
  setupPromise = new Promise(async (resolve, reject) => {
    try {
      const { default: zxcvbnCommonPackage } = await import(
        '@zxcvbn-ts/language-common'
      );
      const { default: zxcvbnEnPackage } = await import(
        '@zxcvbn-ts/language-en'
      );

      const options = {
        translations: zxcvbnEnPackage.translations,
        graphs: zxcvbnCommonPackage.adjacencyGraphs,
        dictionary: {
          ...zxcvbnCommonPackage.dictionary,
          ...zxcvbnEnPackage.dictionary,
        },
      };

      zxcvbnOptions.setOptions(options);

      resolve();
    } catch (err) {
      reject(err);
    }
  });

  return setupPromise;
}

export function passwordValidator(
  getUsername: () => Promise<string | null>
): AsyncValidatorFn {
  const minStrength = 4;
  return async (control: AbstractControl) => {
    if (!control.value?.length) return null;

    await setUpZxcvbn();

    const username = await getUsername();
    if (
      username &&
      username.toLocaleLowerCase().includes(control.value.toLocaleLowerCase())
    ) {
      return {
        zxcvbn: {
          feedback: {
            warning: 'Password is too similar to your username',
          },
          score: 0,
        } as ZxcvbnResult,
      } as ValidationErrors;
    }

    const result = zxcvbn(control.value);
    if (result.score < minStrength) {
      return {
        zxcvbn: result,
      } as ValidationErrors;
    }

    return null;
  };
}
