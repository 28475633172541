import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { TippyModule } from '@ngneat/helipopper';
import { SharedModule } from '../shared/shared.module';
import { HelpFormComponent } from './components/help-form/help-form.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SidebarDropdownComponent } from './components/sidebar/sidebar-dropdown/sidebar-dropdown.component';
import { SidebarItemPredefinedComponent } from './components/sidebar/sidebar-item-predefined/sidebar-item-predefined.component';
import { SidebarItemComponent } from './components/sidebar/sidebar-item/sidebar-item.component';
import { SidebarSeparatorComponent } from './components/sidebar/sidebar-separator/sidebar-separator.component';
import { SidebarSubmenuComponent } from './components/sidebar/sidebar-submenu/sidebar-submenu.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { GlobalErrorHandler } from './error-handlers/global-error-handler';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HasFeaturePipe } from './pipes/has-feature.pipe';
import { HasPermissionPipe } from './pipes/has-permission.pipe';
import { HideAccountNumberPipe } from './pipes/hide-account-number.pipe.pipe';
import {
  FRESHDESK_SERVICE_CONFIG,
  FreshdeskService,
  FreshdeskServiceConfig,
} from './services/freshdesk.service';
import { SidebarItemReportingComponent } from './components/sidebar/sidebar-item-reporting/sidebar-item-reporting.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    SidebarComponent,
    SidebarItemComponent,
    SidebarSeparatorComponent,
    SidebarItemPredefinedComponent,
    HelpFormComponent,
    SidebarDropdownComponent,
    HasPermissionPipe,
    HasFeaturePipe,
    SidebarSubmenuComponent,
    HideAccountNumberPipe,
    SidebarItemReportingComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    MatButtonModule,
    MatFormFieldModule,
    TippyModule,
    MatRippleModule,
    MatInputModule,
  ],
  providers: [
    AuthInterceptor,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    FreshdeskService,
    {
      provide: FRESHDESK_SERVICE_CONFIG,
      useValue: {
        widgetId: '151000000679',
        callback(FreshworksWidget) {
          FreshworksWidget('hide', 'launcher');
        },
      } as FreshdeskServiceConfig,
    },
  ],
  exports: [
    NotFoundComponent,
    SidebarComponent,
    SidebarItemComponent,
    SidebarSeparatorComponent,
    SidebarItemPredefinedComponent,
    HelpFormComponent,
    HasPermissionPipe,
    HasFeaturePipe,
    HideAccountNumberPipe,
  ],
})
export class CoreModule {}
