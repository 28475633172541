import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BaseStateModel,
  BASE_STATE_DEFAULTS,
} from '../../interfaces/base-state-model.interface';
import { mapCreatedUpdated } from '../../interfaces/base.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { toFormData } from '../../shared/util/to-formdata';
import { NewsArticle } from '../interfaces/news.interface';

export class LoadNews {
  static readonly type = '[News] Load News';
  constructor(public payload: { loadAll: boolean } = { loadAll: false }) {}
}

export class CreateNewsArticle {
  static readonly type = '[News] Create News Article';
  constructor(public article: NewsArticle, public file: File) {}
}

export class UpdateNewsArticle {
  static readonly type = '[News] Update News Article';
  constructor(public article: NewsArticle, public file: File) {}
}

export class DeleteNewsArticle {
  static readonly type = '[News] Delete Article';
  constructor(public article: NewsArticle) {}
}

export interface NewsStateModel extends BaseStateModel {
  news: NewsArticle[];
}

@State<NewsStateModel>({
  name: 'news',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    news: [],
  },
})
@Injectable()
export class NewsState {
  @Selector()
  static loading(state: NewsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: NewsStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: NewsStateModel) {
    return state.error;
  }

  @Selector([NewsState.allArticles])
  static articles(state: NewsStateModel, articles: NewsArticle[]) {
    return articles.filter(
      (article) =>
        article.startDate <= new Date() && article.endDate >= new Date()
    );
  }

  @Selector()
  static allArticles(state: NewsStateModel) {
    return state.news
      .slice()
      .sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
  }

  constructor(private http: HttpClient) {}

  @Action(LoadNews)
  async loadNews(ctx: StateContext<NewsStateModel>, action: LoadNews) {
    ctx.patchState({
      loading: true,
      error: null,
    });

    try {
      const news = await lastValueFrom(
        this.http.get<any[]>(`${environment.apiUrl}/v1/news`, {
          params: {
            allArticles: action.payload.loadAll,
          },
        })
      );

      const mappedNews = news.map((article) => ({
        ...article,
        startDate: new Date(article.startDate),
        endDate: new Date(article.endDate),
        ...mapCreatedUpdated(article),
      }));

      ctx.patchState({
        news: mappedNews,
        loading: false,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  @Action(CreateNewsArticle)
  async createNewsArticle(
    ctx: StateContext<NewsStateModel>,
    action: CreateNewsArticle
  ) {
    ctx.patchState({
      loading: true,
    });

    try {
      const data = toFormData({
        ...action.article,
        file: action.file,
      });

      await delayRequest(
        lastValueFrom(
          this.http.post<NewsArticle>(`${environment.apiUrl}/v1/news`, data)
        )
      );

      return ctx.dispatch(new LoadNews());
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(UpdateNewsArticle)
  async updateNewsArticle(
    ctx: StateContext<NewsStateModel>,
    action: UpdateNewsArticle
  ) {
    ctx.patchState({
      loading: true,
    });

    try {
      const data = toFormData({
        ...action.article,
        file: action.file,
      });

      await delayRequest(
        lastValueFrom(
          this.http.put<NewsArticle>(
            `${environment.apiUrl}/v1/news/${action.article.id}`,
            data
          )
        )
      );

      return ctx.dispatch(new LoadNews());
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(DeleteNewsArticle)
  async deleteArticle(
    ctx: StateContext<NewsStateModel>,
    action: DeleteNewsArticle
  ) {
    ctx.patchState({
      loading: true,
    });

    try {
      await delayRequest(
        lastValueFrom(
          this.http.delete(`${environment.apiUrl}/v1/news/${action.article.id}`)
        )
      );

      ctx.setState(
        patch<NewsStateModel>({
          news: removeItem<NewsArticle>(
            (article) => article?.id === action.article.id
          ),
        })
      );
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }
}
