<app-dialog class="dialog">
  <ng-container slot="title">
    {{ options.title }}
  </ng-container>
  <ng-container *ngIf="options.subtitle" slot="subtitle">
    {{ options.subtitle }}
  </ng-container>
  <div class="content" slot="body">
    {{ options.body }}
    <ng-container *ngIf="options.template">
      <ng-container
        *ngTemplateOutlet="options.template; context: { $implicit: this }"
      ></ng-container>
    </ng-container>
    <div *ngIf="options.confirmationValue" class="p-4 my-2 rounded bg-gray-50">
      <p>To confirm this operation, please enter the following text:</p>
      <div class="select-none">
        <pre>{{ options.confirmationValue }}</pre>
      </div>
      <input class="text-input" type="text" [(ngModel)]="confirmationValue" />
    </div>
  </div>
  <ng-container slot="buttons">
    <ng-container *ngFor="let button of options.buttons">
      <button
        mat-flat-button
        *ngIf="button.primary"
        color="primary"
        (click)="buttonClick(button)"
        [disabled]="
          options.confirmationValue &&
          confirmationValue !== options.confirmationValue
        "
      >
        {{ button.text }}
      </button>
      <button
        mat-flat-button
        *ngIf="button.secondary"
        (click)="buttonClick(button)"
      >
        {{ button.text }}
      </button>
    </ng-container>
  </ng-container>
</app-dialog>
