import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, of } from 'rxjs';
import { LoadPublicCompanyDetails } from '../auth.state';

@Injectable({
  providedIn: 'root',
})
export class PublicCompanyDetailsResolverService implements Resolve<any> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store
      .dispatch(new LoadPublicCompanyDetails())
      .pipe(catchError(() => of(null)));
  }
}
