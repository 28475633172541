import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-error',
  templateUrl: './api-error.component.html',
  styleUrls: ['./api-error.component.scss'],
})
export class ApiErrorComponent implements OnInit {
  @Input() error: any | null | undefined = undefined;

  constructor() {}

  ngOnInit(): void {}

  getErrors(): string[] {
    if (!this.error) return [];

    if (this.error instanceof HttpErrorResponse) {
      if (!this.error.status) {
        return [
          'A network error has occurred. Please check your Internet connection.',
        ];
      }

      switch (this.error.status) {
        case HttpStatusCode.InternalServerError:
          return ['An unexpected error occurred'];
        case HttpStatusCode.BadRequest: {
          const message = this.error.error.message;
          if (Array.isArray(message)) {
            return message;
          }

          return [message];
        }
        case HttpStatusCode.TooManyRequests:
          return ['Too many requests. Please try again later.'];
      }

      if (Array.isArray(this.error.error?.message)) {
        return this.error.error.message;
      }

      return [
        this.error.error?.message ||
          this.error.error?.error ||
          this.error.statusText,
      ];
    }

    if (typeof this.error.message === 'string') {
      return [this.error.message];
    }

    if (Array.isArray(this.error.message)) {
      return this.error.message;
    }

    return [];
  }
}
