export interface BaseInterface {
  created: Date;
  updated: Date;
}

/**
 * Utility function to map the created and updated properties of standard entities
 * which extend the base interface (defined above)
 * @param entity
 * @returns
 */
export function mapCreatedUpdated(entity: BaseInterface) {
  return {
    created: new Date(entity.created),
    updated: new Date(entity.updated),
  };
}

/**
 * Utility function to find the most recent update date of a collection of entities
 * @param entities
 * @returns
 */
export function mostRecentUpdateOfCollection(entities: BaseInterface[]) {
  return entities.reduce((latestUpdate: Date | null, entity) => {
    if (entity.updated > new Date()) {
      return latestUpdate;
    }
    if (latestUpdate === null) {
      return entity.updated;
    }
    if (latestUpdate < entity.updated) {
      return entity.updated;
    }
    return latestUpdate;
  }, null);
}
