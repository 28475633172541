import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type {
  Dependent,
  EmergencyContact,
  Employee,
  EmployeeAddress,
  PaymentInformation,
} from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { PersonalInformation } from 'src/app/manage-my-information/interfaces/personal-information.interface';
import { environment } from '../../../environments/environment';
import { Benefit } from '../../benefits/interfaces/benefit.interface';
import { Compensation } from '../../compensation/interfaces/compensation.interface';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { PaidTimeOff } from '../../paid-time-off/interfaces/paid-time-off.interface';

export const ReportName = {
  Compensation: 'compensation',
  AnnualizedCompensation: 'annualized-compensation',
  PaidTimeOff: 'time',
  Benefits: 'benefits',
  Employees: 'employees',
  TotalRewards: 'total-rewards',
  PersonalInformation: 'personal-information',
  Dependents: 'dependents',
  Address: 'address',
  PaymentInformation: 'payment-information',
  EmergencyContact: 'emergency-contact',
} as const;
export type ReportName = typeof ReportName[keyof typeof ReportName];

export class LoadReport {
  static readonly type = '[Reporting] Load Report';
  constructor(
    public readonly payload: {
      report: ReportName;
    }
  ) {}
}

export interface ReportingStateModel extends BaseStateModel {
  data: {
    employees: Employee[];
    compensation: (Compensation & { employee: Employee })[];
    annualizedCompensation: {
      employee: Employee;
      fixedCompensation: number;
      bonusCompensation: number;
      variableCompensation: number;
    }[];
    paidTimeOff: (PaidTimeOff & { employee: Employee })[];
    benefits: (Benefit & { employee: Employee })[];
    totalRewards: {
      employee: Employee;
      totalBenefitsEmployee: number;
      totalBenefitsEmployer: number;
      totalWorkLifeBenefitsEmployee: number;
      totalWorkLifeBenefitsEmployer: number;
      fixedCompensation: number;
      variableCompensation: number;
      bonusCompensation: number;
      totalPaidTimeOff: number;
    }[];
    personalInformation: (PersonalInformation & { employee: Employee })[];
    dependents: (Dependent & { employee: Employee })[];
    address: (EmployeeAddress & { employee: Employee })[];
    paymentInformation: (PaymentInformation & { employee: Employee })[];
    emergencyContact: (EmergencyContact & { employee: Employee })[];
  };
}

@State<ReportingStateModel>({
  name: 'reporting',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    data: {
      employees: [],
      compensation: [],
      annualizedCompensation: [],
      paidTimeOff: [],
      benefits: [],
      totalRewards: [],
      personalInformation: [],
      dependents: [],
      address: [],
      paymentInformation: [],
      emergencyContact: [],
    },
  },
})
@Injectable()
export class ReportingState {
  @Selector()
  static data(state: ReportingStateModel) {
    return state.data;
  }

  @Selector()
  static loading(state: ReportingStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: ReportingStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: ReportingStateModel) {
    return state.error;
  }

  constructor(private http: HttpClient) {}

  @Action(LoadReport)
  async loadReport(
    { patchState, getState }: StateContext<ReportingStateModel>,
    { payload }: LoadReport
  ) {
    patchState({
      loading: true,
      loaded: false,
      error: null,
    });
    try {
      const res = await lastValueFrom(
        this.http.get<any[]>(
          `${environment.apiUrl}/v1/reporting/${payload.report}`
        )
      );
      const typeMap = {
        [ReportName.Compensation]: 'compensation',
        [ReportName.AnnualizedCompensation]: 'annualizedCompensation',
        [ReportName.PaidTimeOff]: 'paidTimeOff',
        [ReportName.Benefits]: 'benefits',
        [ReportName.Employees]: 'employees',
        [ReportName.TotalRewards]: 'totalRewards',
        [ReportName.PersonalInformation]: 'personalInformation',
        [ReportName.Dependents]: 'dependents',
        [ReportName.Address]: 'address',
        [ReportName.PaymentInformation]: 'paymentInformation',
        [ReportName.EmergencyContact]: 'emergencyContact',
      };

      patchState({
        data: {
          ...getState().data,
          [typeMap[payload.report]]: res,
        },
      });
    } catch (err) {
      patchState({
        error: err,
      });
    } finally {
      patchState({
        loading: false,
        loaded: true,
      });
    }
  }
}
