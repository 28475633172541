import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type {
  CompanyPgpKey,
  CompanySamlConfig,
  SuccessFactorsIntegrationConfig,
  WorkForceIntegrationConfig,
} from '@prisma/client';
import { DateTime } from 'luxon';
import { lastValueFrom } from 'rxjs';
import { CompanyWorkForceIntegrationConfigUpdate } from 'src/app/admin/interface/company-workforce-integration-config.interface';
import { environment } from '../../../environments/environment';
import { CompanySamlConfigUpdate } from '../../admin/interface/company-saml-config.interface';
import { CompanySuccessFactorsIntegrationConfigUpdate } from '../../admin/interface/company-successfactors-integration-config.interface';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { mapCreatedUpdated } from '../../interfaces/base.interface';
import { Company } from '../../paid-time-off/interfaces/company.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { SetPalette } from './palette.state';

export interface CompanyYearPeriod {
  yearStartDate: Date;
  yearEndDate: Date;
}

export class LoadCompany {
  static readonly type = '[Company] Load';
}

export class SaveCompany {
  static readonly type = '[Company] Save';
  constructor(public company: Company) {}
}

export class UploadLogo {
  static readonly type = '[Company] Upload Logo';
  constructor(public file: File, public mode: 'light' | 'dark') {}
}

export class LoadCompanySamlConfig {
  static readonly type = '[Company] Load SAML Config';
}

export class SaveCompanySamlConfig {
  static readonly type = '[Company] Save SAML Config';
  constructor(public config: CompanySamlConfigUpdate) {}
}

export class DisableCompanySaml {
  static readonly type = '[Company] Disable SAML';
}

export class LoadCompanySuccessFactorsIntegration {
  static readonly type = '[Company] Load SuccessFactors Integration';
}

export class SaveCompanySuccessFactorsIntegration {
  static readonly type = '[Company] Save SuccessFactors Integration';
  constructor(public config: CompanySuccessFactorsIntegrationConfigUpdate) {}
}

export class LoadCompanyWorkForceIntegration {
  static readonly type = '[Company] Load WorkForce Integration';
}

export class SaveCompanyWorkForceIntegration {
  static readonly type = '[Company] Save WorkForce Integration';
  constructor(public config: CompanyWorkForceIntegrationConfigUpdate) {}
}

export class EnableCompanyPgpAndGenerateKeys {
  static readonly type = '[Company] Enable PGP and Generate Keys';
  constructor(public expires: Date) {}
}

export class LoadCompanyPgpConfig {
  static readonly type = '[Company] Load PGP Config';
}

export class DeletePgpKey {
  static readonly type = '[Company] Disable PGP';
  constructor(public keyId: string) {}
}

export interface CompanyStateModel extends BaseStateModel {
  company: Company | null;
  logoCacheKey: number;
  samlConfigLoading: boolean;
  samlConfig: CompanySamlConfig | null;
  pgpConfigLoading: boolean;
  pgpConfig: CompanyPgpKey[];
  successFactorsIntegrationConfigLoading: boolean;
  companySuccessFactorsIntegration: SuccessFactorsIntegrationConfig | null;
  workForceIntegrationConfigLoading: boolean;
  companyWorkForceIntegration: WorkForceIntegrationConfig | null;
}

@State<CompanyStateModel>({
  name: 'company',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    company: null,
    logoCacheKey: 0,
    samlConfigLoading: false,
    samlConfig: null,
    pgpConfigLoading: false,
    pgpConfig: [],
    successFactorsIntegrationConfigLoading: false,
    companySuccessFactorsIntegration: null,
    workForceIntegrationConfigLoading: false,
    companyWorkForceIntegration: null,
  },
})
@Injectable()
export class CompanyState {
  @Selector()
  static company(state: CompanyStateModel) {
    return state.company;
  }

  @Selector()
  static loading(state: CompanyStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: CompanyStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: CompanyStateModel) {
    return state.error;
  }

  @Selector()
  static yearPeriod(state: CompanyStateModel) {
    if (!state.company) return null;

    const yearEndDate = DateTime.fromJSDate(state.company.yearStartDate)
      .plus({ years: 1 })
      .minus({ milliseconds: 1 })
      .toJSDate();

    const period: CompanyYearPeriod = {
      yearStartDate: state.company.yearStartDate,
      yearEndDate,
    };

    return period;
  }

  @Selector()
  static samlConfig(state: CompanyStateModel) {
    return state.samlConfig;
  }

  @Selector()
  static samlConfigLoading(state: CompanyStateModel) {
    return state.samlConfigLoading;
  }

  @Selector()
  static pgpConfig(state: CompanyStateModel) {
    return state.pgpConfig
      .map((keyPair) => {
        const expired = keyPair.expires < new Date();

        return {
          ...keyPair,
          expired,
        };
      })
      .sort((a, b) => {
        // descending
        return a.created > b.created ? -1 : 1;
      });
  }

  @Selector()
  static pgpConfigLoading(state: CompanyStateModel) {
    return state.pgpConfigLoading;
  }

  @Selector()
  static successFactorsIntegration(state: CompanyStateModel) {
    return state.companySuccessFactorsIntegration;
  }

  @Selector()
  static successFactorsIntegrationLoading(state: CompanyStateModel) {
    return state.successFactorsIntegrationConfigLoading;
  }

  @Selector()
  static workForceIntegration(state: CompanyStateModel) {
    return state.companyWorkForceIntegration;
  }

  @Selector()
  static workForceIntegrationLoading(state: CompanyStateModel) {
    return state.workForceIntegrationConfigLoading;
  }
  constructor(private http: HttpClient) {}

  @Action(LoadCompany)
  async loadCompany(ctx: StateContext<CompanyStateModel>) {
    try {
      ctx.patchState({
        loading: true,
        loaded: false,
        error: null,
      });

      const company = await lastValueFrom(
        this.http.get<Company>(`${environment.apiUrl}/v1/company`)
      );
      ctx.patchState({
        company: this.mapCompanyResponse(company),
      });

      // set the color palette
      ctx.dispatch(new SetPalette(company.palette));
    } catch (error) {
      ctx.patchState({
        error,
      });
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  @Action(SaveCompany)
  async saveCompany(ctx: StateContext<CompanyStateModel>, action: SaveCompany) {
    try {
      ctx.patchState({
        loading: true,
      });
      const company = await delayRequest(
        lastValueFrom(
          this.http.put<Company>(`${environment.apiUrl}/v1/company`, {
            ...action.company,
            yearStartDate: this.handleYearStartDate(
              action.company.yearStartDate
            ),
          })
        )
      );
      ctx.patchState({
        company: this.mapCompanyResponse(company),
      });
    } catch (error) {
      throw error;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(UploadLogo)
  async uploadLogo(
    ctx: StateContext<CompanyStateModel>,
    action: UploadLogo
  ): Promise<void> {
    try {
      ctx.patchState({
        loading: true,
      });
      const formData = new FormData();
      formData.append('file', action.file);
      await delayRequest(
        lastValueFrom(
          this.http.put(
            `${environment.apiUrl}/v1/company/logo/${action.mode}`,
            formData
          )
        )
      );

      ctx.patchState({
        logoCacheKey: Date.now(),
      });
    } catch (error) {
      throw error;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(LoadCompanySamlConfig)
  async loadCompanySamlConfig(ctx: StateContext<CompanyStateModel>) {
    try {
      ctx.patchState({
        samlConfigLoading: true,
      });

      const res = await lastValueFrom(
        this.http.get<CompanySamlConfig>(
          `${environment.apiUrl}/v1/auth/saml/config`
        )
      );

      const mappedRes = {
        ...res,
        ...mapCreatedUpdated(res),
      };

      ctx.patchState({
        samlConfig: mappedRes,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        samlConfigLoading: false,
      });
    }
  }

  @Action(SaveCompanySamlConfig)
  async saveCompanySamlConfig(
    ctx: StateContext<CompanyStateModel>,
    action: SaveCompanySamlConfig
  ) {
    try {
      ctx.patchState({
        samlConfigLoading: true,
      });

      const res = await delayRequest(
        lastValueFrom(
          this.http.put<CompanySamlConfig>(
            `${environment.apiUrl}/v1/auth/saml/config`,
            action.config
          )
        )
      );

      const mappedRes = {
        ...res,
        ...mapCreatedUpdated(res),
      };

      ctx.patchState({
        samlConfig: mappedRes,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        samlConfigLoading: false,
      });
    }
  }

  @Action(DisableCompanySaml)
  async deleteCompanySamlConfig(ctx: StateContext<CompanyStateModel>) {
    try {
      ctx.patchState({
        samlConfigLoading: true,
      });
      const res = await lastValueFrom(
        this.http.delete(`${environment.apiUrl}/v1/auth/saml/config`)
      );
      ctx.patchState({
        samlConfig: null,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        samlConfigLoading: false,
      });
    }
  }

  private mapCompanyResponse(company: Company) {
    return {
      ...company,
      yearStartDate: this.handleYearStartDate(new Date(company.yearStartDate)),
      payPeriodStartDate: new Date(company.payPeriodStartDate),
      ...mapCreatedUpdated(company),
    };
  }

  private handleYearStartDate(date: Date) {
    const dateWithThisYear = new Date(date);
    dateWithThisYear.setFullYear(new Date().getFullYear());

    // case 1: the date is in the future, we can safely update the year
    if (date > new Date()) {
      return dateWithThisYear;
    }

    // case 2: the date is in the past - if the date is greater than now
    // (ignoring the year) then it should be set to last year
    if (dateWithThisYear < new Date()) {
      return dateWithThisYear;
    }

    dateWithThisYear.setFullYear(new Date().getFullYear() - 1);
    return dateWithThisYear;
  }

  @Action(LoadCompanySuccessFactorsIntegration)
  async loadCompanySuccessFactorsIntegration(
    ctx: StateContext<CompanyStateModel>
  ) {
    try {
      ctx.patchState({
        successFactorsIntegrationConfigLoading: true,
      });

      const res = await lastValueFrom(
        this.http.get<SuccessFactorsIntegrationConfig>(
          `${environment.apiUrl}/v1/integrations/successfactors`
        )
      );

      ctx.patchState({
        companySuccessFactorsIntegration: res,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        successFactorsIntegrationConfigLoading: false,
      });
    }
  }

  @Action(LoadCompanyWorkForceIntegration)
  async loadCompanyWorkForceIntegration(ctx: StateContext<CompanyStateModel>) {
    try {
      ctx.patchState({
        workForceIntegrationConfigLoading: true,
      });

      const res = await lastValueFrom(
        this.http.get<WorkForceIntegrationConfig>(
          `${environment.apiUrl}/v1/workforce/config`
        )
      );

      ctx.patchState({
        companyWorkForceIntegration: res,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        workForceIntegrationConfigLoading: false,
      });
    }
  }

  @Action(SaveCompanySuccessFactorsIntegration)
  async saveCompanySuccessFactorsIntegration(
    ctx: StateContext<CompanyStateModel>,
    action: SaveCompanySuccessFactorsIntegration
  ) {
    try {
      ctx.patchState({
        successFactorsIntegrationConfigLoading: true,
      });

      const res = await delayRequest(
        lastValueFrom(
          this.http.put<SuccessFactorsIntegrationConfig>(
            `${environment.apiUrl}/v1/integrations/successfactors`,
            action.config
          )
        )
      );

      ctx.patchState({
        companySuccessFactorsIntegration: res,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        successFactorsIntegrationConfigLoading: false,
      });
    }
  }

  @Action(SaveCompanyWorkForceIntegration)
  async saveCompanyWorkForceIntegration(
    ctx: StateContext<CompanyStateModel>,
    action: SaveCompanyWorkForceIntegration
  ) {
    try {
      ctx.patchState({
        workForceIntegrationConfigLoading: true,
      });

      const res = await delayRequest(
        lastValueFrom(
          this.http.put<WorkForceIntegrationConfig>(
            `${environment.apiUrl}/v1/workforce/config`,
            action.config
          )
        )
      );

      ctx.patchState({
        companyWorkForceIntegration: res,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        workForceIntegrationConfigLoading: false,
      });
    }
  }

  @Action(EnableCompanyPgpAndGenerateKeys)
  async enableCompanyPgpAndGenerateKeys(
    ctx: StateContext<CompanyStateModel>,
    action: EnableCompanyPgpAndGenerateKeys
  ) {
    try {
      ctx.patchState({
        pgpConfigLoading: true,
      });

      const res = await delayRequest(
        lastValueFrom(
          this.http.post<CompanyPgpKey[]>(
            `${environment.apiUrl}/v1/auth/pgp/config`,
            {
              expires: action.expires,
            }
          )
        )
      );

      ctx.patchState({
        pgpConfig: this.mapPgpConfig(res),
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        pgpConfigLoading: false,
      });
    }
  }

  @Action(LoadCompanyPgpConfig)
  async loadCompanyPgpConfig(ctx: StateContext<CompanyStateModel>) {
    try {
      ctx.patchState({
        pgpConfigLoading: true,
      });

      const res = await lastValueFrom(
        this.http.get<CompanyPgpKey[]>(
          `${environment.apiUrl}/v1/auth/pgp/config`
        )
      );

      ctx.patchState({
        pgpConfig: this.mapPgpConfig(res),
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        pgpConfigLoading: false,
      });
    }
  }

  @Action(DeletePgpKey)
  async deleteCompanyPgpKey(
    ctx: StateContext<CompanyStateModel>,
    action: DeletePgpKey
  ) {
    try {
      ctx.patchState({
        pgpConfigLoading: true,
      });

      const res = await lastValueFrom(
        this.http.delete<CompanyPgpKey[]>(
          `${environment.apiUrl}/v1/auth/pgp/config/${action.keyId}`
        )
      );

      ctx.patchState({
        pgpConfig: this.mapPgpConfig(res),
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        pgpConfigLoading: false,
      });
    }
  }

  private mapPgpConfig(config: any[]): CompanyPgpKey[] {
    if (!config) {
      return [];
    }

    return config.map((keyPair) => ({
      ...keyPair,
      expires: new Date(keyPair.expires),
      ...mapCreatedUpdated(keyPair),
    }));
  }
}
