import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { firstValueFrom, map } from 'rxjs';
import { AcceptInvite } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-accept-invite-page',
  template: ` <app-login-screen [logos]="false">
    <div class="flex content-center h-20 mb-10">
      <app-company-logo
        *ngIf="companyId$ | async as companyId"
        [companyId]="companyId"
      ></app-company-logo>
    </div>

    <div *ngIf="!hasAccepted" class="space-y-2">
      <p>Dear {{ employeeName$ | async }}.</p>
      <p>
        You have been invited to join the organization,
        {{ companyName$ | async }}.
      </p>

      <div class="flex justify-center space-x-2">
        <button
          matRipple
          (click)="cancelInvite()"
          class="rounded border border-primary-500 text-primary-500 px-4 py-3 my-6"
        >
          Cancel
        </button>
        <button
          matRipple
          (click)="acceptInvite()"
          class="rounded bg-primary-500 text-white px-4 py-3 my-6"
        >
          Accept invitation
        </button>
      </div>

      <p class="text-gray-500 text-sm">
        If you do not recognize this organization, or did not expect an
        invitation, please ignore this request and leave the page.
      </p>

      <app-api-error [error]="error"></app-api-error>
    </div>

    <div *ngIf="hasAccepted" class="space-y-2 text-center">
      <div>You have been successfully invited.</div>
      <div>You will be redirected in 5 seconds...</div>
      <a [routerLink]="['/auth/login']">Click here if you are not redirected</a>
    </div>
  </app-login-screen>`,
  styles: [],
})
export class AcceptInvitePageComponent implements OnInit {
  store = inject(Store);
  route = inject(ActivatedRoute);

  hasAccepted = false;
  error = null;

  employeeName$ = this.route.queryParamMap.pipe(
    map((params) => params.get('employeeName'))
  );
  companyId$ = this.route.queryParamMap.pipe(
    map((params) => params.get('companyId'))
  );
  companyName$ = this.route.queryParamMap.pipe(
    map((params) => params.get('companyName'))
  );
  token$ = this.route.paramMap.pipe(map((params) => params.get('token')));

  ngOnInit(): void {}

  async acceptInvite() {
    const token = await firstValueFrom(this.token$);

    if (!token) {
      return;
    }

    this.store
      .dispatch(
        new AcceptInvite({
          inviteToken: token,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.error = null;
          this.hasAccepted = true;

          setTimeout(() => {
            window.location.href = '/auth/tenant-selection';
          }, 5000);
        },
        error: (err) => {
          this.error = err?.error;
        },
      });
  }

  async cancelInvite() {
    window.location.href = '/auth/login';
  }
}
