import type { CompanyText as CompanyTextPrisma } from '@prisma/client';
import { Optional } from 'utility-types';

export type CompanyText = Optional<
  Omit<CompanyTextPrisma, 'companyId'>,
  'updated' | 'created'
> & {
  textType: CompanyTextType;
  textArea: CompanyPolicyType | CompanyStatementType;
};

export enum CompanyTextType {
  POLICY = 'policy',
  STATEMENT = 'statement',
}

export enum CompanyPolicyType {
  OVERVIEW = 'overview',
  BENEFITS = 'benefits',
  WELCOME = 'welcome',
  WORK_LIFE_BENEFITS = 'workLifeBenefits',
  COMPENSATION = 'compensation',
  PAID_TIME_OFF = 'paidTimeOff',
  CONTACT_INFORMATION = 'contactInformation',
  NEWS = 'news',
  MANAGE_MY_INFORMATION = 'manageMyInformation',
  PERSONAL_INFORMATION = 'personalInformation',
  DEPENDENTS = 'dependents',
  PAYMENT_INFORMATION = 'paymentInformation',
  EMERGENCY_CONTACTS = 'emergencyContacts',
  ADDRESS = 'address',
}

export enum CompanyStatementType {
  INTRO = 'intro',
  SIGNATURE = 'signature',
}
