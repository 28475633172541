import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '../../auth/auth.state';
import { CompanyFeature } from '../../auth/interfaces/company-feature.enum';
import { AppRoute, NEWS, OVERVIEW, WELCOME } from '../../routes';

export type FeatureRedirects = {
  [feature in CompanyFeature]?: any[];
};

/**
 * Homepage redirect based on the feature flags the user has access to
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagRedirectGuard implements CanActivate {
  private store = inject(Store);
  private router = inject(Router);

  private readonly preferredRouteOrder: AppRoute[] = [OVERVIEW, WELCOME, NEWS];

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const allowedFeatures = this.store.selectSnapshot(AuthState.features);
    const allowedRoles = this.store.selectSnapshot(AuthState.role);

    // find the first route in the preferred route order that the user is allowed to access
    const firstAllowedRoute = this.preferredRouteOrder.find(
      (route) =>
        (route.requiredFeatures || []).some((feature) =>
          (allowedFeatures || []).includes(feature)
        ) &&
        (route.roles || []).some((role) => (allowedRoles || []).includes(role))
    );

    if (!firstAllowedRoute) return true;

    this.router.navigate([firstAllowedRoute.path]);
    return true;
  }
}
