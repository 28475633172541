import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-powered-by-epi-use',
  templateUrl: './powered-by-epi-use.component.html',
  styleUrls: ['./powered-by-epi-use.component.scss'],
})
export class PoweredByEpiUseComponent implements OnInit {
  /// enable dark mode
  @Input() dark = false;

  constructor() {}

  ngOnInit(): void {}
}
