<app-sidebar-item
  [link]="['/' + route.path]"
  [icon]="route.icon"
  *ngIf="
    (route.roles | hasPermission | async) &&
    (route.requiredFeatures | hasFeature | async)
  "
>
  <ng-container slot="title">
    {{ route.name }}
  </ng-container>
</app-sidebar-item>
