import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TippyModule } from '@ngneat/helipopper';
import { QueryParamModule } from '@ngqp/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgLetModule } from 'ng-let';
import { NgxFilesizeModule } from 'ngx-filesize';
import { NgxMaskModule } from 'ngx-mask';
import { TimeagoModule } from 'ngx-timeago';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { AlertComponent } from './components/alert/alert.component';
import { ApiErrorComponent } from './components/api-error/api-error.component';
import { CompanyLogoComponent } from './components/company-logo/company-logo.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ContributionTableBarComponent } from './components/contribution-table-bar/contribution-table-bar.component';
import { CountryDropdownComponent } from './components/country-dropdown/country-dropdown.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ErrorComponent } from './components/error/error.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormApiErrorComponent } from './components/form-api-error/form-api-error.component';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { InlineHelpComponent } from './components/inline-help/inline-help.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LogoComponent } from './components/logo/logo.component';
import { MultipleFileUploaderComponent } from './components/multiple-file-uploader/multiple-file-uploader.component';
import { NavigationElementComponent } from './components/navigation-element/navigation-element.component';
import { PageComponent } from './components/page/page.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PoweredByEpiUseComponent } from './components/powered-by-epi-use/powered-by-epi-use.component';
import { PrintButtonComponent } from './components/print-button/print-button.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { SimpleFormErrorComponent } from './components/simple-form-error/simple-form-error.component';
import { TotalHeaderComponent } from './components/total-header/total-header.component';
import { WeeHelpIconComponent } from './components/wee-help-icon/wee-help-icon.component';
import { SimpleFormErrorDirective } from './directives/simple-form-error.directive';
import { AppConfigPipe } from './pipes/app-config.pipe';
import { AppCurrencyPipe } from './pipes/currency.pipe';
import { ErrorPipe } from './pipes/error.pipe';
import { FnAsPipePipe } from './pipes/fn-as-pipe.pipe';
import { PtoEntitlementPipe } from './pipes/pto-entitlement.pipe';
import { PrintService } from './services/print.service';

@NgModule({
  declarations: [
    LogoComponent,
    AppConfigPipe,
    ErrorPipe,
    ErrorComponent,
    ApiErrorComponent,
    LoadingSpinnerComponent,
    PageComponent,
    DialogComponent,
    ConfirmationDialogComponent,
    FnAsPipePipe,
    SearchbarComponent,
    LoaderComponent,
    GetStartedComponent,
    TotalHeaderComponent,
    ContributionTableBarComponent,
    InlineHelpComponent,
    FileUploaderComponent,
    CompanyLogoComponent,
    PoweredByEpiUseComponent,
    FooterComponent,
    AlertComponent,
    MultipleFileUploaderComponent,
    FormApiErrorComponent,
    PaginatorComponent,
    PrintButtonComponent,
    AppCurrencyPipe,
    AddressFormComponent,
    SimpleFormErrorDirective,
    SimpleFormErrorComponent,
    CountryDropdownComponent,
    NavigationElementComponent,
    PtoEntitlementPipe,
    WeeHelpIconComponent,
    DateRangePickerComponent,
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    RouterModule,

    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatDatepickerModule,
    MatOptionModule,
    MatSelectModule,

    NgxMaskModule.forRoot({}),
    TippyModule,
    NgLetModule,
    QueryParamModule,
    TimeagoModule,
    TranslateModule,

    NgxFilesizeModule,
    MatRippleModule,
    MatRadioModule,
  ],
  providers: [PrintService],
  exports: [
    TranslateModule,
    MatIconModule,
    NgLetModule,
    QueryParamModule,
    LogoComponent,
    AppConfigPipe,
    ErrorPipe,
    ErrorComponent,
    ApiErrorComponent,
    LoadingSpinnerComponent,
    PageComponent,
    DialogComponent,
    ConfirmationDialogComponent,
    FnAsPipePipe,
    SearchbarComponent,
    LoaderComponent,
    GetStartedComponent,
    TotalHeaderComponent,
    ContributionTableBarComponent,
    InlineHelpComponent,
    FileUploaderComponent,
    CompanyLogoComponent,
    PoweredByEpiUseComponent,
    FooterComponent,
    AlertComponent,
    MultipleFileUploaderComponent,
    FormApiErrorComponent,
    MatRippleModule,
    PaginatorComponent,
    PrintButtonComponent,
    AppCurrencyPipe,
    SimpleFormErrorDirective,
    SimpleFormErrorComponent,
    AddressFormComponent,
    NavigationElementComponent,
    PtoEntitlementPipe,
    WeeHelpIconComponent,
    DateRangePickerComponent,
  ],
})
export class SharedModule {
  constructor(private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.setDefaultFontSetClass('material-symbols-rounded');
  }
}
