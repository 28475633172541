import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
// @ts-ignore
import { Observable, switchMap } from 'rxjs';
import {
  CompanyPolicyType,
  CompanyText,
} from '../../../admin/interface/company-text.interface';
import {
  CompanyTextState,
  LoadCompanyText,
} from '../../../admin/state/company-text.state';
import { PrintService } from '../../services/print.service';

@Component({
  selector: 'app-page',
  template: `<!-- page header -->
    <div
      class="flex flex-col min-h-full"
      style="background-color: var(--page-background)"
    >
      <header class="bg-white" [class.print:hidden]="printHideHeader">
        <div
          class="flex flex-col px-4 py-6 space-x-4 space-y-4 print:!px-0 sm:items-end sm:justify-between sm:flex-row sm:px-8 shrink-0"
          style="max-width: var(--page-width)"
        >
          <!-- title and description -->
          <div class="flex flex-col">
            <div class="flex items-center">
              <div>
                <!-- title -->
                <h1 class="text-3xl font-bold tracking-tight">
                  <ng-content select="[slot=title]"></ng-content>
                </h1>
                <!-- subtitle -->
                <div color="text-gray-400" *ngIf="hasSubtitle">
                  <ng-content select="[slot=subtitle]"></ng-content>
                </div>
              </div>
              <!-- print button-->
              <div class="print:hidden">
                <button
                  mat-icon-button
                  *ngIf="printFileName"
                  (click)="print(printFileName)"
                  tippy="Print page"
                  aria-label="Print page"
                >
                  <mat-icon>print</mat-icon>
                </button>
              </div>
            </div>
            <!-- last updated -->
            <div class="text-sm text-gray-400" *ngIf="lastUpdated">
              Updated {{ lastUpdated | date }} ({{ lastUpdated | timeago }})
            </div>
          </div>

          <!-- actions -->
          <div class="flex flex-row items-center space-x-4 flex-nowrap">
            <ng-content select="[slot=actions]"></ng-content>
          </div>
        </div>
      </header>

      <!-- header separator line -->
      <div
        class="h-[2px] bg-gray-300"
        [class.print:hidden]="printHideHeader"
      ></div>

      <!-- page description -->
      <div
        class="px-4 py-4 sm:px-8 bg-gray-50 print:!px-0"
        *ngIf="companyText$ | async as companyText"
      >
        <div
          class="text-base prose text-gray-600 prose-neutral"
          style="max-width: var(--page-width)"
          [innerHTML]="companyText.textBody"
        ></div>
      </div>

      <!-- page content -->
      <main
        class="container relative px-4 print:!px-0 sm:px-8 py-6 min-h-[540px] shrink-0 grow"
        style="max-width: var(--page-width)"
        #body
      >
        <app-loader class="absolute" *ngIf="loading"></app-loader>
        <ng-container *ngIf="!loading && loaded && error">
          <app-get-started
            *ngIf="loaded && !loading && error"
            iconName="error_outline"
            description="An error occurred while loading this page. Please try again."
            buttonText="Reload"
            (takeAction)="refresh.emit()"
          ></app-get-started>
        </ng-container>
        <ng-container *ngIf="!error">
          <ng-content select="[slot=body]"></ng-content>
        </ng-container>
      </main>

      <app-footer *ngIf="footer" class="shrink-0"></app-footer>
    </div> `,
  styles: [
    `
      :host {
        display: contents;
        print-color-adjust: exact; // ensure that background colors are included in print
        --page-width: 64rem;
      }
    `,
  ],
})
export class PageComponent implements OnInit {
  @Input() printFileName: string | null = null;
  @Input() printHideHeader: boolean = false;
  @Input() lastUpdated: Date | undefined | null = null;

  @Input() footer = true;

  @Input() loading: boolean | undefined | null = false;
  @Input() loaded: boolean | undefined | null;
  @Input() error: any;
  @Output() refresh = new EventEmitter();

  @Input() hasSubtitle: boolean = false;

  @Input() policyType: CompanyPolicyType | undefined;

  @ViewChild('body') body: ElementRef<HTMLElement> | undefined;

  companyText$: Observable<CompanyText | null> | undefined;

  constructor(private printService: PrintService, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(new LoadCompanyText({ forceLoad: false }));

    if (this.policyType) {
      this.companyText$ = this.store
        .select(CompanyTextState.policy(this.policyType))
        .pipe(
          switchMap((companyText) =>
            this.store.select(CompanyTextState.formatPolicy(companyText))
          )
        );
    }
  }

  async print(documentName: string) {
    this.printService.print(documentName);
  }
}
