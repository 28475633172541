import {
  Compensation as CompensationPrisma,
  PayComponentType as PayComponentTypePrisma,
} from '@prisma/client';

export type Compensation = Omit<CompensationPrisma, 'paymentDate'> & {
  paymentDate: Date;
  payComponentType: PayComponentType;

  estimatedAnnualizedValue?: number;
  /// annualizationFactor * payComponentValue
  amount: number;
  payComponent: {
    payComponentCode: string;
    payComponentDescription: string;
    payComponentType: PayComponentType;
    rank: number;
  };
};

export const PayComponentType = PayComponentTypePrisma;
export type PayComponentType = PayComponentTypePrisma;
