<div class="flex items-center justify-center py-20 bg-slate-50 rounded-xl">
  <div class="flex flex-col items-center space-y-4">
    <mat-icon class="icon" class="!w-12 !h-12 !text-[48px] text-slate-400">
      {{ iconName }}
    </mat-icon>
    <div class="text-slate-500">
      {{ description }}
    </div>
    <button
      mat-flat-button
      color="primary"
      *ngIf="buttonText"
      (click)="handleClick()"
    >
      {{ buttonText }}
    </button>
  </div>
</div>
