import { HttpClient } from '@angular/common/http';
import type { ChangeRequest } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CreateAddress,
  DeleteAddress,
  UpdateAddress,
} from '../state/address.state';
import {
  CreateDependent,
  DeleteDependent,
  UpdateDependent,
} from '../state/dependent.state';
import {
  CreateEmergencyContact,
  DeleteEmergencyContact,
  UpdateEmergencyContact,
} from '../state/emergency-contact.state';
import {
  CreatePaymentInformation,
  DeletePaymentInformation,
  UpdatePaymentInformation,
} from '../state/payment-information.state';
import {
  CreatePersonalInformation,
  CreateProofOfLife,
  UpdatePersonalInformation,
  UpdateProofOfLife,
} from '../state/personal-information.state';

export async function uploadSupportingDocumentation(
  action:
    | UpdatePaymentInformation
    | CreatePaymentInformation
    | DeletePaymentInformation
    | UpdatePersonalInformation
    | CreatePersonalInformation
    | UpdatePersonalInformation
    | UpdateEmergencyContact
    | CreateEmergencyContact
    | DeleteEmergencyContact
    | UpdateDependent
    | CreateDependent
    | DeleteDependent
    | UpdateAddress
    | CreateAddress
    | DeleteAddress
    | CreateProofOfLife
    | UpdateProofOfLife,
  changeRequest: ChangeRequest,
  http: HttpClient
) {
  if (!action.files?.length) return;

  const data = new FormData();

  for (const file of action.files) {
    data.append('files', file);
  }

  return await lastValueFrom(
    http.post(
      `${environment.apiUrl}/v1/change-request/${changeRequest.id}/documents`,
      data
    )
  );
}
