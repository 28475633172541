import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum DialogConfirmationButtonRole {
  Cancel = 'cancel',
  Accept = 'accept',
}

export interface Button {
  role: DialogConfirmationButtonRole | string;
  text: string;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
}

export interface ConfirmationDialogOptions {
  title: string;
  subtitle?: string;
  body: string;
  template?: TemplateRef<any>;
  confirmationValue?: string;
  loading?: boolean;
  buttons?: Button[];
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  options: ConfirmationDialogOptions = {
    title: 'Modal title',
    body: 'Modal body',
    loading: false,
    buttons: [
      {
        role: DialogConfirmationButtonRole.Cancel,
        text: 'Cancel',
        secondary: true,
      },
      {
        role: DialogConfirmationButtonRole.Accept,
        text: 'Accept',
        primary: true,
      },
    ],
  };

  confirmationValue = null;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmationDialogOptions
  ) {
    this.options = {
      ...this.options,
      ...dialogData,
    };

    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    // this.dialogRef.addPanelClass('modal-no-padding');
  }

  buttonClick(button: Button) {
    this.dialogRef.close(button.role);
  }
}
