import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthState, AuthStateModel } from '../../auth/auth.state';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import {
  CompanyPolicyType,
  CompanyStatementType,
  CompanyText,
  CompanyTextType,
} from '../interface/company-text.interface';

export class LoadCompanyText {
  static readonly type = '[Company Text] Load';
  constructor(public payload: { forceLoad: boolean }) {}
}

export class SaveCompanyText {
  static readonly type = '[Company Text] Save';
  constructor(public payload: CompanyText) {}
}

export interface CompanyTextStateModel extends BaseStateModel {
  policies: {
    [name in CompanyPolicyType]: CompanyText | null;
  };
  statement: {
    [name in CompanyStatementType]: CompanyText | null;
  };
}

/**
 * Company Text describes a set of strings defined by administrators for the company.
 * These strings are used for
 *  - Policy text: the text displayed at the top of each page
 *  - 360º Statement: text pieces (e.g. intro, signature)
 */
@State<CompanyTextStateModel>({
  name: 'companyText',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    policies: {
      [CompanyPolicyType.OVERVIEW]: null,
      [CompanyPolicyType.WELCOME]: null,
      [CompanyPolicyType.BENEFITS]: null,
      [CompanyPolicyType.WORK_LIFE_BENEFITS]: null,
      [CompanyPolicyType.COMPENSATION]: null,
      [CompanyPolicyType.PAID_TIME_OFF]: null,
      [CompanyPolicyType.CONTACT_INFORMATION]: null,
      [CompanyPolicyType.NEWS]: null,
      [CompanyPolicyType.MANAGE_MY_INFORMATION]: null,
      [CompanyPolicyType.PERSONAL_INFORMATION]: null,
      [CompanyPolicyType.DEPENDENTS]: null,
      [CompanyPolicyType.PAYMENT_INFORMATION]: null,
      [CompanyPolicyType.EMERGENCY_CONTACTS]: null,
      [CompanyPolicyType.ADDRESS]: null,
    },
    statement: {
      [CompanyStatementType.INTRO]: null,
      [CompanyStatementType.SIGNATURE]: null,
    },
    loading: false,
    loaded: false,
  },
})
@Injectable()
export class CompanyTextState {
  static policy(name: CompanyPolicyType) {
    return createSelector(
      [CompanyTextState],
      (state: CompanyTextStateModel) => {
        return state.policies[name] || null;
      }
    );
  }

  static statement(name: CompanyStatementType) {
    return createSelector(
      [CompanyTextState],
      (state: CompanyTextStateModel) => {
        return state.statement[name] || null;
      }
    );
  }

  public static formatPolicy(policy: CompanyText | null) {
    return createSelector(
      [AuthState.userDetails, AuthState.fullName],
      (userDetails: AuthStateModel['userDetails'], fullName: string) => {
        if (!policy) {
          return null;
        }
        return {
          ...policy,
          textBody: (policy.textBody || '')
            .replace(/\{firstName\}/g, userDetails?.firstName || '')
            .replace(/\{lastName\}/g, userDetails?.lastName || '')
            .replace(/\{fullName\}/g, fullName || ''),
        };
      }
    );
  }

  @Selector()
  static loading(state: CompanyTextStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: CompanyTextStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: CompanyTextStateModel) {
    return state.error;
  }

  constructor(private http: HttpClient) {}

  @Action(LoadCompanyText)
  async loadCompanyText(
    ctx: StateContext<CompanyTextStateModel>,
    { payload }: LoadCompanyText
  ) {
    ctx.patchState({
      loading: true,
    });

    // don't re-load if already loaded
    if (!payload.forceLoad && ctx.getState().loaded) {
      return;
    }

    try {
      const res = await lastValueFrom(
        this.http.get<CompanyText[]>(`${environment.apiUrl}/v1/company/text`)
      );

      const grouped = res.reduce(
        (acc: { policies: any; statement: any }, text) => {
          if (text.textType === CompanyTextType.STATEMENT) {
            acc.statement[text.textArea] = text;
            return acc;
          }

          if (text.textType === CompanyTextType.POLICY) {
            acc.policies[text.textArea] = text;
            return acc;
          }

          return acc;
        },
        {
          policies: {},
          statement: {},
        }
      );

      ctx.patchState({
        policies: grouped.policies,
        statement: grouped.statement,
        loaded: true,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loaded: true,
        loading: false,
      });
    }
  }

  @Action(SaveCompanyText)
  async saveCompanyText(
    ctx: StateContext<CompanyTextStateModel>,
    payload: SaveCompanyText
  ) {
    ctx.patchState({
      loading: true,
    });

    try {
      const res = await delayRequest(
        lastValueFrom(
          this.http.put<CompanyText>(
            `${environment.apiUrl}/v1/company/text`,
            payload.payload
          )
        )
      );

      if (res.textType === CompanyTextType.STATEMENT) {
        ctx.patchState({
          statement: {
            ...ctx.getState().statement,
            [res.textArea]: res,
          },
        });
      } else if (res.textType === CompanyTextType.POLICY) {
        ctx.patchState({
          policies: {
            ...ctx.getState().policies,
            [res.textArea]: res,
          },
        });
      }
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }
}
