import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { EmployeeAddress } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { AddressCreate, AddressUpdate } from '../interfaces/address.interface';
import { ChangeRequest } from '../interfaces/change-request.interface';
import { uploadSupportingDocumentation } from '../util/supporting-docs-upload';
import { UpsertChangeRequests } from './change-request.state';

export interface AddressStateModel extends BaseStateModel {
  address: EmployeeAddress | null;
}

export class LoadAddress {
  static readonly type = '[EmployeeAddress] LoadAddress';
}

export class UpdateAddress {
  static readonly type = '[EmployeeAddress] UpdateAddress';
  constructor(public address: AddressUpdate, public files: File[] = []) {}
}

export class CreateAddress {
  static readonly type = '[EmployeeAddress] CreateAddress';
  constructor(public address: AddressCreate, public files: File[] = []) {}
}

export class DeleteAddress {
  static readonly type = '[EmployeeAddress] DeleteAddress';
  constructor(public addressId: string, public files: File[] = []) {}
}

@State<AddressStateModel>({
  name: 'address',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    address: null,
  },
})
@Injectable()
export class AddressState {
  http = inject(HttpClient);

  @Selector()
  static loading(state: AddressStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: AddressStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: AddressStateModel) {
    return state.error;
  }

  @Selector()
  static address(state: AddressStateModel) {
    return state.address;
  }

  @Action(LoadAddress)
  async loadAddress(ctx: StateContext<AddressStateModel>) {
    ctx.patchState({ loading: true });
    try {
      const address = await lastValueFrom(
        this.http.get<EmployeeAddress>(`${environment.apiUrl}/v1/address`)
      );

      ctx.patchState({ address });
    } catch (error) {
      ctx.patchState({ error });
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(UpdateAddress)
  async updateAddress(
    ctx: StateContext<AddressStateModel>,
    action: UpdateAddress
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await delayRequest(
        lastValueFrom(
          this.http.put<ChangeRequest>(
            `${environment.apiUrl}/v1/address/${action.address.id}`,
            action.address
          )
        )
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(CreateAddress)
  async createAddress(
    ctx: StateContext<AddressStateModel>,
    action: CreateAddress
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await delayRequest(
        lastValueFrom(
          this.http.post<ChangeRequest>(
            `${environment.apiUrl}/v1/address`,
            action.address
          )
        )
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(DeleteAddress)
  async deleteAddress(
    ctx: StateContext<AddressStateModel>,
    action: DeleteAddress
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await lastValueFrom(
        this.http.delete<ChangeRequest>(
          `${environment.apiUrl}/v1/address/${action.addressId}`
        )
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }
}
