<app-page
  [loading]="userDetailsLoading$ | async"
  [loaded]="userDetailsLoaded$ | async"
  [error]="userDetailsError$ | async"
  (refresh)="refresh()"
  [footer]="false"
  style="--page-width: 100%"
>
  <ng-container slot="title">My Account</ng-container>
  <ng-container slot="actions">
    <button mat-flat-button (click)="dialogRef.close()">Close</button>
  </ng-container>

  <div slot="body" class="flex flex-col space-y-4 overflow-y-auto">
    <!-- change email -->
    <div
      *ngIf="
        (userDetails$ | async)?.user && (userDetails$ | async) as userDetails
      "
    >
      <h2 class="mb-4">Change my email address</h2>

      <form
        class="flex flex-col space-y-4 md:max-w-lg"
        [formGroup]="emailChangeForm"
        (ngSubmit)="changeEmail()"
      >
        <mat-form-field>
          <mat-label>Current email address</mat-label>
          <input matInput disabled [value]="userDetails.user?.email" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>New email address</mat-label>
          <input
            matInput
            placeholder="new.email@example.com"
            type="email"
            formControlName="newEmail"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Confirm new email address</mat-label>
          <input
            matInput
            placeholder="new.email@example.com"
            type="email"
            formControlName="newEmailRepeat"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password" />
        </mat-form-field>
        <app-inline-help
          >For security, your password is required to verify that you are making
          the change.</app-inline-help
        >

        <mat-checkbox formControlName="confirmCorrectEmail">
          <label class="whitespace-normal">
            I confirm that the new email address above is correct</label
          ></mat-checkbox
        >
        <mat-checkbox formControlName="confirmBelongsToMe"
          ><label class="whitespace-normal"
            >I confirm that the new email address belongs to me</label
          ></mat-checkbox
        >
        <mat-checkbox formControlName="confirmUnderstand"
          ><label class="whitespace-normal"
            >I understand that I will only be able to use the new email address
            to sign in</label
          ></mat-checkbox
        >

        <div class="flex justify-end mb-4">
          <button
            type="submit"
            mat-flat-button
            color="primary"
            [disabled]="emailChangeLoading$ | async"
          >
            <span>Update email</span>
            <app-loading-spinner
              *ngIf="emailChangeLoading$ | async"
            ></app-loading-spinner>
          </button>
        </div>

        <app-api-error [error]="emailChangeError$ | async"></app-api-error>

        <app-error
          *ngIf="
            emailChangeForm.touched &&
            emailChangeForm.dirty &&
            emailChangeForm.hasError('matches')
          "
        >
          Email addresses do not match
        </app-error>

        <app-error
          *ngIf="
            emailChangeForm.touched &&
            emailChangeForm.dirty &&
            (emailChangeForm.controls['confirmCorrectEmail'].hasError(
              'required'
            ) ||
              emailChangeForm.controls['confirmBelongsToMe'].hasError(
                'required'
              ) ||
              emailChangeForm.controls['confirmUnderstand'].hasError(
                'required'
              ))
          "
        >
          Please ensure that you have read and checked all of the checkboxes
          above
        </app-error>
      </form>

      <hr />
    </div>

    <!-- Change password -->
    <div>
      <h2 class="mb-4">Change my password</h2>

      <form
        class="flex flex-col space-y-4 md:max-w-lg"
        [formGroup]="passwordChangeForm"
        (ngSubmit)="changePassword()"
      >
        <mat-form-field>
          <mat-label>Current password</mat-label>
          <input matInput type="password" formControlName="currentPassword" />
          <mat-error
            *ngIf="
              passwordChangeForm.controls['currentPassword'].hasError(
                'required'
              )
            "
          >
            {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>

        <hr />

        <mat-form-field>
          <mat-label>New password</mat-label>
          <input matInput type="password" formControlName="newPassword" />
          <mat-error
            *ngIf="
              passwordChangeForm.controls['newPassword'].hasError('required')
            "
          >
            {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
        <app-password-strength
          [zxcvbn]="
            passwordChangeForm.controls['newPassword'].getError('zxcvbn')
          "
          [password]="passwordChangeForm.controls['newPassword'].value"
        ></app-password-strength>

        <mat-form-field>
          <mat-label>Repeat New password</mat-label>
          <input matInput type="password" formControlName="newPasswordRepeat" />
          <mat-error
            *ngIf="
              passwordChangeForm.controls['newPasswordRepeat'].hasError(
                'required'
              )
            "
          >
            {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="confirmPassword"
          ><label class="whitespace-normal"
            >I confirm that I wish to change my password</label
          ></mat-checkbox
        >
        <mat-checkbox formControlName="confirmUnderstand"
          ><label class="whitespace-normal"
            >I understand that I will only be able to use the new password to
            sign in</label
          ></mat-checkbox
        >

        <div>
          This will redirect you to the login page after successfully changing
          your password
        </div>

        <div class="flex justify-end mb-4">
          <button
            type="submit"
            mat-flat-button
            color="primary"
            [disabled]="emailChangeLoading$ | async"
          >
            <span>Update password</span>
            <app-loading-spinner
              *ngIf="emailChangeLoading$ | async"
            ></app-loading-spinner>
          </button>
        </div>

        <app-api-error [error]="passwordChangeError$ | async"></app-api-error>

        <app-error
          *ngIf="
            passwordChangeForm.touched &&
            passwordChangeForm.dirty &&
            passwordChangeForm.hasError('matches')
          "
        >
          New Passwords do not match
        </app-error>

        <app-error
          *ngIf="
            passwordChangeForm.touched &&
            passwordChangeForm.dirty &&
            passwordChangeForm.hasError('differs')
          "
        >
          New Password cannot be the same as the old password
        </app-error>

        <app-error
          *ngIf="
            passwordChangeForm.touched &&
            passwordChangeForm.dirty &&
            (passwordChangeForm.controls['confirmPassword'].hasError(
              'required'
            ) ||
              passwordChangeForm.controls['confirmUnderstand'].hasError(
                'required'
              ))
          "
        >
          Please ensure that you have read and checked all of the checkboxes
          above
        </app-error>
      </form>

      <hr />
    </div>

    <!-- Request Personal Data -->
    <div class="prose">
      <h2 class="mb-4">Request my data</h2>
      <p>
        You can request a payload containing all metadata related to your user
        by clicking on the button below. This will include data related to your
        employee profile, Compensation, Benefits, Paid Time Off and other types
        of data related to your user.
      </p>

      <button
        mat-flat-button
        color="primary"
        (click)="requestAllMyData()"
        [disabled]="fetchingUserInformation$ | async"
      >
        <span>Request my data</span>
        <app-loading-spinner
          *ngIf="fetchingUserInformation$ | async"
        ></app-loading-spinner>
      </button>

      <p>
        All your documents should be visible to you from this website, and are
        included in the data request. If you would like to request any
        additional information relating to documents (payslips, tax forms, etc.)
        please contact your company administrator using the help form.
      </p>

      <p>
        If you would like to delete any information, please contact your company
        administrator using the help form.
      </p>
    </div>

    <hr />

    <section class="prose" *ngIf="(userDetails$ | async)?.user">
      <h2>Delete my user account</h2>
      <p>You can delete your user account by clicking on the button below.</p>
      <p>
        You will be logged out and no longer be able to sign back in to the
        system.
      </p>
      <p>
        Your data will <b>not</b> be automatically deleted from the system. Your
        company administrators will be notified that you wish for your data to
        be removed.
      </p>

      <form
        (ngSubmit)="requestAccountDeletion()"
        [formGroup]="deleteAccountForm"
      >
        <mat-form-field>
          <mat-label>Confirm your password</mat-label>
          <input matInput type="password" formControlName="password" />
        </mat-form-field>
        <div class="flex justify-end">
          <button mat-flat-button color="primary" type="submit">
            Request account deletion
          </button>
        </div>
      </form>

      <hr />
    </section>

    <ng-container *ngIf="userDetails$ | async as userDetails">
      <h2>Account identifiers</h2>
      <pre class="whitespace-normal">{{ userDetails.companyId }}</pre>
      <pre class="whitespace-normal">{{ userDetails.id }}</pre>
      <pre class="whitespace-normal">{{ userDetails.user?.id }}</pre>
      <pre class="whitespace-normal">{{ userDetails.user?.email }}</pre>
    </ng-container>
  </div>
</app-page>
