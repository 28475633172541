<ng-content select="[slot=dropdown-header]"></ng-content>

<app-sidebar-item (click)="toggleExpanded()" [icon]="icon">
  <ng-container slot="title">
    {{ heading }}
  </ng-container>
  <ng-container slot="right">
    <mat-icon>{{ expanded ? "expand_less" : "expand_more" }}</mat-icon>
  </ng-container>
</app-sidebar-item>

<div
  class="overflow-y-hidden bg-gray-900 rounded-lg"
  style="--sidebar-item-padding-factor: 1"
  [style.height]="expanded ? 'auto' : '0'"
>
  <ng-content select="[slot=dropdown-items]"></ng-content>
</div>
