import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  download(url: string, fileName: string) {
    // create a temporary link and click it to download the file
    const anchor = document.createElement('a');
    anchor.download = fileName ?? 'download.pdf';
    anchor.target = '_blank';
    anchor.href = url;
    anchor.click();

    // clean up
    anchor.remove();
  }

  downloadBlob(data: Blob, fileName: string) {
    const url = window.URL.createObjectURL(data);

    return this.download(url, fileName);
  }

  downloadPlainText(text: string, fileName: string) {
    const url = `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`;

    return this.download(url, fileName);
  }
}
