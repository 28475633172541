import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthState } from '../../auth/auth.state';
import { GlobalDateRangeState } from '../../global-date-range.state';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import {
  mapCreatedUpdated,
  mostRecentUpdateOfCollection,
} from '../../interfaces/base.interface';
import { dataByPayPeriod } from '../../shared/util/data-by-pay-period.util';
import { isWithinPeriod } from '../../shared/util/is-within-period.util';
import {
  Compensation,
  PayComponentType,
} from '../interfaces/compensation.interface';

export class LoadCompensation {
  static readonly type = '[Compensation] Load';
}

export interface CompensationStateModel extends BaseStateModel {
  compensation: Compensation[];
  history: Compensation[];
}

@State<CompensationStateModel>({
  name: 'compensation',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    compensation: [],
    history: [],
  },
})
@Injectable()
export class CompensationState {
  @Selector()
  static compensation(state: CompensationStateModel) {
    return state.compensation.slice().sort((a, b) => {
      return a.payComponent.rank - b.payComponent.rank;
    });
  }

  @Selector()
  static loading(state: CompensationStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: CompensationStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: CompensationStateModel) {
    return state.error;
  }

  @Selector()
  static history(state: CompensationStateModel) {
    return state.history;
  }

  @Selector([AuthState.payPeriod])
  static historyByPayPeriod(
    state: CompensationStateModel,
    payPeriod: ReturnType<typeof AuthState.payPeriod>
  ) {
    if (!payPeriod) return [];
    return dataByPayPeriod(state.history, payPeriod);
  }

  @Selector([GlobalDateRangeState.startDate, GlobalDateRangeState.endDate])
  static selectionHistory(
    state: CompensationStateModel,
    startDate: Date,
    endDate: Date
  ) {
    return state.history.filter((comp) => {
      return isWithinPeriod({
        eventStartDate: comp.paymentDate,
        eventEndDate: comp.paymentDate,
        periodStartDate: startDate,
        periodEndDate: endDate,
      });
    });
  }

  @Selector([CompensationState.selectionHistory])
  static selectionHistoryTotal(
    state: CompensationStateModel,
    history: Compensation[]
  ) {
    return history.reduce((acc, curr) => acc + curr.amount, 0);
  }

  @Selector([CompensationState.selectionHistory])
  static selectionHistoryFixed(
    state: CompensationStateModel,
    history: Compensation[]
  ) {
    return history.filter(
      (comp) => comp.payComponent?.payComponentType === PayComponentType.FIXED
    );
  }

  @Selector([CompensationState.selectionHistoryFixed])
  static selectionHistoryFixedTotal(
    state: CompensationStateModel,
    compensation: Compensation[]
  ) {
    return compensation.reduce((acc, comp) => acc + comp.amount, 0);
  }

  @Selector([CompensationState.selectionHistory])
  static selectionHistoryVariable(
    state: CompensationStateModel,
    history: Compensation[]
  ) {
    return history.filter(
      (comp) =>
        comp.payComponent?.payComponentType === PayComponentType.VARIABLE
    );
  }

  @Selector([CompensationState.selectionHistoryVariable])
  static selectionHistoryVariableTotal(
    state: CompensationStateModel,
    compensation: Compensation[]
  ) {
    return compensation.reduce((acc, comp) => acc + comp.amount, 0);
  }

  @Selector([CompensationState.selectionHistory])
  static selectionHistoryBonus(
    state: CompensationStateModel,
    history: Compensation[]
  ) {
    return history.filter(
      (comp) => comp.payComponent?.payComponentType === PayComponentType.BONUS
    );
  }

  @Selector([CompensationState.selectionHistoryBonus])
  static selectionHistoryBonusTotal(
    state: CompensationStateModel,
    compensation: Compensation[]
  ) {
    return compensation.reduce((acc, comp) => acc + comp.amount, 0);
  }

  @Selector([CompensationState.selectionHistoryFixed])
  static selectionHistoryFixedAggregated(
    state: CompensationStateModel,
    history: Compensation[]
  ) {
    return this.aggregateCompensation(history);
  }

  @Selector([CompensationState.selectionHistoryVariable])
  static selectionHistoryVariableAggregated(
    state: CompensationStateModel,
    history: Compensation[]
  ) {
    return this.aggregateCompensation(history);
  }

  @Selector([CompensationState.selectionHistoryBonus])
  static selectionHistoryBonusAggregated(
    state: CompensationStateModel,
    history: Compensation[]
  ) {
    return this.aggregateCompensation(history);
  }

  private static aggregateCompensation(compensation: Compensation[]) {
    const grouped = compensation.reduce((acc, c) => {
      acc[c.payComponentCode] = acc[c.payComponentCode] || {
        amount: 0,
        payComponent: c.payComponent,
      };
      acc[c.payComponentCode].amount += c.amount;
      return acc;
    }, {} as Record<string, Pick<Compensation, 'amount' | 'payComponent'>>);

    return Object.values(grouped);
  }

  @Selector([CompensationState.compensation])
  static lastUpdated(
    state: CompensationStateModel,
    compensation: Compensation[]
  ) {
    return mostRecentUpdateOfCollection(compensation);
  }

  constructor(private http: HttpClient) {}

  @Action(LoadCompensation)
  async loadCompensation(ctx: StateContext<CompensationStateModel>) {
    try {
      ctx.patchState({
        loading: true,
        error: null,
      });

      const compensation = await lastValueFrom(
        this.http.get<any[]>(`${environment.apiUrl}/v1/compensation`)
      );
      const mappedCompensation: Compensation[] = compensation.map((comp) =>
        this.mapCompensation(comp)
      );
      ctx.patchState({
        compensation: mappedCompensation,
      });

      const compensationHistory = await lastValueFrom(
        this.http.get<any[]>(`${environment.apiUrl}/v1/compensation/history`)
      );
      const mappedCompensationHistory: Compensation[] = compensationHistory.map(
        (comp) => this.mapCompensation(comp)
      );
      const mergedCompensationHistory =
        this.mergeCompensationForTheSamePayComponentAndDate(
          mappedCompensationHistory
        );

      ctx.patchState({
        history: mergedCompensationHistory,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  private mapCompensation(comp: any): Compensation {
    return {
      ...comp,
      startDate: new Date(comp.startDate),
      endDate: new Date(comp.endDate),
      paymentDate: new Date(comp.paymentDate),
      ...mapCreatedUpdated(comp),
    };
  }

  /**
   * We may get multiple compensation records for the same pay component and date.
   * This method merges them into a single record by summing the amounts.
   * @param compensation
   * @returns
   */
  private mergeCompensationForTheSamePayComponentAndDate(
    compensation: Compensation[]
  ) {
    const groupedByPayComponentAndDate = compensation.reduce((acc, c) => {
      const key = `${c.payComponentCode}-${c.paymentDate}`;
      if (acc[key]) {
        acc[key].push(c);
      } else {
        acc[key] = [c];
      }
      return acc;
    }, {} as Record<string, Compensation[]>);

    return Object.values(groupedByPayComponentAndDate)
      .map((payComponentComps) => {
        const [firstComp] = payComponentComps;
        return {
          ...firstComp,
          amount: payComponentComps.reduce((acc, c) => acc + c.amount, 0),
        };
      })
      .sort((a, b) => {
        return (
          (a.paymentDate ?? a.endDate).getTime() -
          (b.paymentDate ?? b.endDate).getTime()
        );
      });
  }
}
