import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject, Observable, firstValueFrom, map } from 'rxjs';
import { externalUrls } from '../../../../environments/constants';
import { matches } from '../../../shared/form-validators/matches.validator';
import { passwordValidator } from '../../../shared/form-validators/password.validator';
import { AuthState, Register } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  @Select(AuthState.registering)
  registering$!: Observable<boolean>;

  registerForm = new FormGroup(
    {
      contactName: new FormControl('', [Validators.required]),
      password: new FormControl(
        '',
        [Validators.required],
        [passwordValidator(() => firstValueFrom(this.email$))]
      ),
      passwordRepeat: new FormControl('', [Validators.required]),
      tos: new FormControl(false, [Validators.requiredTrue]),
      privacy: new FormControl(false, [Validators.requiredTrue]),
    },
    {
      validators: [matches('password', 'passwordRepeat')],
    }
  );

  hasRegistered = false;

  email$ = this.route.queryParamMap.pipe(map((params) => params.get('email')));
  token$ = this.route.paramMap.pipe(map((params) => params.get('token')));

  authError$ = new BehaviorSubject(null);

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {}

  async register() {
    this.registerForm.markAsTouched();
    this.registerForm.markAsDirty();
    if (this.registerForm.invalid) return;

    const token = await firstValueFrom(this.token$);

    this.store
      .dispatch(
        new Register({
          contactName: this.registerForm.value.contactName as string,
          password: this.registerForm.value.password as string,
          inviteToken: token as string,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.authError$.next(null);
          this.hasRegistered = true;
        },
        error: (err) => {
          this.authError$.next(err);
        },
      });
  }

  showDisclaimer() {
    window.open(externalUrls.disclaimerUrl, '_blank');
  }

  showPrivacyPolicy() {
    window.open(externalUrls.privacyPolicyUrl, '_blank');
  }
}
