import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BaseStateModel,
  BASE_STATE_DEFAULTS,
} from '../../interfaces/base-state-model.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { PaidTimeOffConfig } from '../interfaces/paid-time-off-config.interface';

export class LoadPaidTimeOffConfig {
  static readonly type = '[PaidTimeOffConfig] Load';
}

export class SavePaidTimeOffConfig {
  static readonly type = '[PaidTimeOffConfig] Save';
  constructor(public payload: { config: PaidTimeOffConfig[] }) {}
}

export interface PaidTimeOffConfigStateModel extends BaseStateModel {
  config: PaidTimeOffConfig[];
}

@State<PaidTimeOffConfigStateModel>({
  name: 'paidTimeOffConfig',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    config: [],
  },
})
@Injectable()
export class PaidTimeOffConfigState {
  @Selector()
  static config(state: PaidTimeOffConfigStateModel) {
    return state.config;
  }

  @Selector()
  static loading(state: PaidTimeOffConfigStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: PaidTimeOffConfigStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: PaidTimeOffConfigStateModel) {
    return state.error;
  }

  constructor(private http: HttpClient) {}

  @Action(LoadPaidTimeOffConfig)
  async loadPaidTimeOffConfig(ctx: StateContext<PaidTimeOffConfigStateModel>) {
    try {
      ctx.patchState({
        loading: true,
        error: null,
      });
      const config = await lastValueFrom(
        this.http.get<any>(`${environment.apiUrl}/v1/paid-time-off-config`)
      );

      ctx.patchState({
        config,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loaded: true,
        loading: false,
      });
    }
  }

  @Action(SavePaidTimeOffConfig)
  async savePaidTimeOffConfig(
    ctx: StateContext<PaidTimeOffConfigStateModel>,
    { payload }: SavePaidTimeOffConfig
  ) {
    const { config } = payload;

    try {
      ctx.patchState({
        loading: true,
      });

      const updatedConfig = await delayRequest(
        lastValueFrom(
          this.http.put<any>(
            `${environment.apiUrl}/v1/paid-time-off-config`,
            config
          )
        )
      );

      ctx.patchState({
        config: updatedConfig,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }
}
