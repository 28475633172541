<app-login-screen>
  <form
    [formGroup]="resetForm"
    (ngSubmit)="resetPassword()"
    *ngIf="!hasSentResetLink"
  >
    <app-login-intro>
      <ng-container slot="title">Forgot password?</ng-container>
      <ng-container slot="subtitle">{{
        "LOGIN.RESET_INSTRUCTIONS" | translate
      }}</ng-container>
    </app-login-intro>

    <div class="mb-6">
      <mat-form-field>
        <mat-label>{{ "LOGIN.EMAIL" | translate }}</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          [placeholder]="'LOGIN.EMAIL_PLACEHOLDER' | translate"
        />
        <mat-error *ngIf="resetForm.controls['email'].hasError('email')">
          {{ "ERRORS.INVALID_EMAIL" | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <app-api-error [error]="authError$ | async"></app-api-error>

    <app-error
      *ngIf="
        resetForm.touched && resetForm.dirty && resetForm.hasError('atLeastOne')
      "
    >
      {{ "ERRORS.AT_LEAST_ONE" | translate }}
    </app-error>

    <div class="flex items-center justify-between">
      <a mat-button [routerLink]="'/auth/login'">
        {{ "CANCEL" | translate }}
      </a>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="sendingResetLink$ | async"
      >
        <span>
          {{ "LOGIN.SEND_RESET_LINK" | translate }}
        </span>
        <app-loading-spinner
          *ngIf="sendingResetLink$ | async"
        ></app-loading-spinner>
      </button>
    </div>
  </form>

  <p *ngIf="hasSentResetLink">
    {{ "LOGIN.RESET_LINK_SENT" | translate }}
  </p>
</app-login-screen>
