import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inline-help',
  templateUrl: './inline-help.component.html',
  styleUrls: ['./inline-help.component.scss']
})
export class InlineHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
