import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { AuthState, ForgotPassword } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  authError$ = new BehaviorSubject(null);

  sendingResetLink$ = this.store.select(AuthState.sendingResetLink);

  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  hasSentResetLink = false;

  constructor(private store: Store, private fb: FormBuilder) {}

  ngOnInit(): void {}

  resetPassword() {
    this.resetForm.markAsDirty();
    this.resetForm.markAsTouched();
    if (!this.resetForm.valid) return;

    this.authError$.next(null);

    this.store
      .dispatch(
        new ForgotPassword({
          email: this.resetForm.controls['email'].value,
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.hasSentResetLink = true;
        },
        error: (err) => {
          this.authError$.next(err);
        },
      });
  }
}
