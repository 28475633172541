import { PayPeriodUnit } from '@prisma/client';
import * as d3 from 'd3';

type HasPaymentDate = {
  paymentDate: Date;
};

export function dataByPayPeriod<T extends HasPaymentDate>(
  data: T[],
  payPeriod: {
    payPeriodStartDate: Date;
    payPeriodUnit: PayPeriodUnit;
  }
) {
  const interval = payPeriodIntervalFactory(payPeriod);
  return dataByInterval(data, interval);
}

export function dataByFinancialYear<T extends HasPaymentDate>(
  data: T[],
  financialYear: {
    yearStartDate: Date;
  }
) {
  const interval = financialYearIntervalFactory(financialYear);
  return dataByInterval(data, interval);
}

export function dataByInterval<T extends HasPaymentDate>(
  data: T[],
  interval: d3.TimeInterval
) {
  if (!data.length) {
    return [];
  }
  data = data.sort((a, b) => a.paymentDate.getTime() - b.paymentDate.getTime());

  // list of all pay periods between min and max date
  const payPeriodMap: Record<
    string,
    {
      date: Date;
      startDate: Date;
      endDate: Date;
      data: T[];
    }
  > = {};

  for (const el of data) {
    const payPeriods = [interval.floor(el.paymentDate)];

    for (const date of payPeriods) {
      const key = date.toISOString();
      if (!payPeriodMap[key]) {
        payPeriodMap[key] = {
          date: date,
          startDate: date,
          endDate: interval.offset(date, 1),
          data: [],
        };
      }
      payPeriodMap[key].data.push(el);
    }
  }

  return Object.values(payPeriodMap);
}

export function payPeriodIntervalFactory(payPeriod: {
  payPeriodUnit: PayPeriodUnit;
  payPeriodStartDate: Date;
}) {
  if (payPeriod.payPeriodUnit === PayPeriodUnit.MONTHLY) {
    // customized utcMonthly with month start date
    return d3.timeInterval(
      (date) => {
        // floor
        date.setUTCDate(payPeriod.payPeriodStartDate.getDate());
        date.setUTCHours(0, 0, 0, 0);
      },
      (date, step) => {
        // offset
        date.setUTCMonth(date.getUTCMonth() + step);
      },
      (start, end) => {
        // count
        return (
          end.getUTCMonth() -
          start.getUTCMonth() +
          (end.getUTCFullYear() - start.getUTCFullYear()) * 12
        );
      },
      (date) => {
        return date.getUTCMonth();
      }
    );
  } else if (
    payPeriod.payPeriodUnit === PayPeriodUnit.WEEKLY ||
    payPeriod.payPeriodUnit === PayPeriodUnit.BIWEEKLY
  ) {
    let interval;
    switch (payPeriod.payPeriodStartDate.getDay()) {
      case 0:
        interval = d3.utcSunday;
        break;
      case 1:
        interval = d3.utcMonday;
        break;
      case 2:
        interval = d3.utcTuesday;
        break;
      case 3:
        interval = d3.utcWednesday;
        break;
      case 4:
        interval = d3.utcThursday;
        break;
      case 5:
        interval = d3.utcFriday;
        break;
      case 6:
        interval = d3.utcSaturday;
        break;
      default:
        interval = d3.utcMonday;
        break;
    }

    if (payPeriod.payPeriodUnit === PayPeriodUnit.WEEKLY) {
      return interval.every(1) as d3.TimeInterval;
    } else {
      return interval.every(2) as d3.TimeInterval; // biweekly
    }
  } else {
    return d3.utcDay.every(1) as d3.TimeInterval;
  }
}

export function financialYearIntervalFactory(yearPeriod: {
  yearStartDate: Date;
}) {
  return d3.timeInterval(
    (date) => {
      // floor
      date.setUTCMonth(
        yearPeriod?.yearStartDate.getUTCMonth() ?? 0,
        yearPeriod?.yearStartDate.getUTCDate() ?? 1
      );
      date.setUTCHours(0, 0, 0, 0);
    },
    (date, step) => {
      // offset
      date.setUTCFullYear(date.getUTCFullYear() + step);
    },
    (start, end) => {
      // count
      return end.getUTCFullYear() - start.getUTCFullYear();
    },
    (date) => {
      // field
      return date.getUTCFullYear();
    }
  );
}
