import * as chroma from 'chroma-js';

export const tailwindColors = [
  {
    name: 'rose',
    shades: [
      { number: 50, hexcode: '#fff1f2' },
      { number: 100, hexcode: '#ffe4e6' },
      { number: 200, hexcode: '#fecdd3' },
      { number: 300, hexcode: '#fda4af' },
      { number: 400, hexcode: '#fb7185' },
      { number: 500, hexcode: '#f43f5e' },
      { number: 600, hexcode: '#e11d48' },
      { number: 700, hexcode: '#be123c' },
      { number: 800, hexcode: '#9f1239' },
      { number: 900, hexcode: '#881337' },
    ],
  },
  {
    name: 'pink',
    shades: [
      { number: 50, hexcode: '#fdf2f8' },
      { number: 100, hexcode: '#fce7f3' },
      { number: 200, hexcode: '#fbcfe8' },
      { number: 300, hexcode: '#f9a8d4' },
      { number: 400, hexcode: '#f472b6' },
      { number: 500, hexcode: '#ec4899' },
      { number: 600, hexcode: '#db2777' },
      { number: 700, hexcode: '#be185d' },
      { number: 800, hexcode: '#9d174d' },
      { number: 900, hexcode: '#831843' },
    ],
  },
  {
    name: 'fuchsia',
    shades: [
      { number: 50, hexcode: '#fdf4ff' },
      { number: 100, hexcode: '#fae8ff' },
      { number: 200, hexcode: '#f5d0fe' },
      { number: 300, hexcode: '#f0abfc' },
      { number: 400, hexcode: '#e879f9' },
      { number: 500, hexcode: '#d946ef' },
      { number: 600, hexcode: '#c026d3' },
      { number: 700, hexcode: '#a21caf' },
      { number: 800, hexcode: '#86198f' },
      { number: 900, hexcode: '#701a75' },
    ],
  },
  {
    name: 'purple',
    shades: [
      { number: 50, hexcode: '#faf5ff' },
      { number: 100, hexcode: '#f3e8ff' },
      { number: 200, hexcode: '#e9d5ff' },
      { number: 300, hexcode: '#d8b4fe' },
      { number: 400, hexcode: '#c084fc' },
      { number: 500, hexcode: '#a855f7' },
      { number: 600, hexcode: '#9333ea' },
      { number: 700, hexcode: '#7e22ce' },
      { number: 800, hexcode: '#6b21a8' },
      { number: 900, hexcode: '#581c87' },
    ],
  },
  {
    name: 'violet',
    shades: [
      { number: 50, hexcode: '#f5f3ff' },
      { number: 100, hexcode: '#ede9fe' },
      { number: 200, hexcode: '#ddd6fe' },
      { number: 300, hexcode: '#c4b5fd' },
      { number: 400, hexcode: '#a78bfa' },
      { number: 500, hexcode: '#8b5cf6' },
      { number: 600, hexcode: '#7c3aed' },
      { number: 700, hexcode: '#6d28d9' },
      { number: 800, hexcode: '#5b21b6' },
      { number: 900, hexcode: '#4c1d95' },
    ],
  },
  {
    name: 'indigo',
    shades: [
      { number: 50, hexcode: '#eef2ff' },
      { number: 100, hexcode: '#e0e7ff' },
      { number: 200, hexcode: '#c7d2fe' },
      { number: 300, hexcode: '#a5b4fc' },
      { number: 400, hexcode: '#818cf8' },
      { number: 500, hexcode: '#6366f1' },
      { number: 600, hexcode: '#4f46e5' },
      { number: 700, hexcode: '#4338ca' },
      { number: 800, hexcode: '#3730a3' },
      { number: 900, hexcode: '#312e81' },
    ],
  },
  {
    name: 'blue',
    shades: [
      { number: 50, hexcode: '#eff6ff' },
      { number: 100, hexcode: '#dbeafe' },
      { number: 200, hexcode: '#bfdbfe' },
      { number: 300, hexcode: '#93c5fd' },
      { number: 400, hexcode: '#60a5fa' },
      { number: 500, hexcode: '#3b82f6' },
      { number: 600, hexcode: '#2563eb' },
      { number: 700, hexcode: '#1d4ed8' },
      { number: 800, hexcode: '#1e40af' },
      { number: 900, hexcode: '#1e3a8a' },
    ],
  },
  {
    name: 'sky',
    shades: [
      { number: 50, hexcode: '#f0f9ff' },
      { number: 100, hexcode: '#e0f2fe' },
      { number: 200, hexcode: '#bae6fd' },
      { number: 300, hexcode: '#7dd3fc' },
      { number: 400, hexcode: '#38bdf8' },
      { number: 500, hexcode: '#0ea5e9' },
      { number: 600, hexcode: '#0284c7' },
      { number: 700, hexcode: '#0369a1' },
      { number: 800, hexcode: '#075985' },
      { number: 900, hexcode: '#0c4a6e' },
    ],
  },
  {
    name: 'cyan',
    shades: [
      { number: 50, hexcode: '#ecfeff' },
      { number: 100, hexcode: '#cffafe' },
      { number: 200, hexcode: '#a5f3fc' },
      { number: 300, hexcode: '#67e8f9' },
      { number: 400, hexcode: '#22d3ee' },
      { number: 500, hexcode: '#06b6d4' },
      { number: 600, hexcode: '#0891b2' },
      { number: 700, hexcode: '#0e7490' },
      { number: 800, hexcode: '#155e75' },
      { number: 900, hexcode: '#164e63' },
    ],
  },
  {
    name: 'teal',
    shades: [
      { number: 50, hexcode: '#f0fdfa' },
      { number: 100, hexcode: '#ccfbf1' },
      { number: 200, hexcode: '#99f6e4' },
      { number: 300, hexcode: '#5eead4' },
      { number: 400, hexcode: '#2dd4bf' },
      { number: 500, hexcode: '#14b8a6' },
      { number: 600, hexcode: '#0d9488' },
      { number: 700, hexcode: '#0f766e' },
      { number: 800, hexcode: '#115e59' },
      { number: 900, hexcode: '#134e4a' },
    ],
  },
  {
    name: 'emerald',
    shades: [
      { number: 50, hexcode: '#ecfdf5' },
      { number: 100, hexcode: '#d1fae5' },
      { number: 200, hexcode: '#a7f3d0' },
      { number: 300, hexcode: '#6ee7b7' },
      { number: 400, hexcode: '#34d399' },
      { number: 500, hexcode: '#10b981' },
      { number: 600, hexcode: '#059669' },
      { number: 700, hexcode: '#047857' },
      { number: 800, hexcode: '#065f46' },
      { number: 900, hexcode: '#064e3b' },
    ],
  },
  {
    name: 'green',
    shades: [
      { number: 50, hexcode: '#f0fdf4' },
      { number: 100, hexcode: '#dcfce7' },
      { number: 200, hexcode: '#bbf7d0' },
      { number: 300, hexcode: '#86efac' },
      { number: 400, hexcode: '#4ade80' },
      { number: 500, hexcode: '#22c55e' },
      { number: 600, hexcode: '#16a34a' },
      { number: 700, hexcode: '#15803d' },
      { number: 800, hexcode: '#166534' },
      { number: 900, hexcode: '#14532d' },
    ],
  },
  {
    name: 'lime',
    shades: [
      { number: 50, hexcode: '#f7fee7' },
      { number: 100, hexcode: '#ecfccb' },
      { number: 200, hexcode: '#d9f99d' },
      { number: 300, hexcode: '#bef264' },
      { number: 400, hexcode: '#a3e635' },
      { number: 500, hexcode: '#84cc16' },
      { number: 600, hexcode: '#65a30d' },
      { number: 700, hexcode: '#4d7c0f' },
      { number: 800, hexcode: '#3f6212' },
      { number: 900, hexcode: '#365314' },
    ],
  },
  {
    name: 'yellow',
    shades: [
      { number: 50, hexcode: '#fefce8' },
      { number: 100, hexcode: '#fef9c3' },
      { number: 200, hexcode: '#fef08a' },
      { number: 300, hexcode: '#fde047' },
      { number: 400, hexcode: '#facc15' },
      { number: 500, hexcode: '#eab308' },
      { number: 600, hexcode: '#ca8a04' },
      { number: 700, hexcode: '#a16207' },
      { number: 800, hexcode: '#854d0e' },
      { number: 900, hexcode: '#713f12' },
    ],
  },
  {
    name: 'amber',
    shades: [
      { number: 50, hexcode: '#fffbeb' },
      { number: 100, hexcode: '#fef3c7' },
      { number: 200, hexcode: '#fde68a' },
      { number: 300, hexcode: '#fcd34d' },
      { number: 400, hexcode: '#fbbf24' },
      { number: 500, hexcode: '#f59e0b' },
      { number: 600, hexcode: '#d97706' },
      { number: 700, hexcode: '#b45309' },
      { number: 800, hexcode: '#92400e' },
      { number: 900, hexcode: '#78350f' },
    ],
  },
  {
    name: 'orange',
    shades: [
      { number: 50, hexcode: '#fff7ed' },
      { number: 100, hexcode: '#ffedd5' },
      { number: 200, hexcode: '#fed7aa' },
      { number: 300, hexcode: '#fdba74' },
      { number: 400, hexcode: '#fb923c' },
      { number: 500, hexcode: '#f97316' },
      { number: 600, hexcode: '#ea580c' },
      { number: 700, hexcode: '#c2410c' },
      { number: 800, hexcode: '#9a3412' },
      { number: 900, hexcode: '#7c2d12' },
    ],
  },
  {
    name: 'red',
    shades: [
      { number: 50, hexcode: '#fef2f2' },
      { number: 100, hexcode: '#fee2e2' },
      { number: 200, hexcode: '#fecaca' },
      { number: 300, hexcode: '#fca5a5' },
      { number: 400, hexcode: '#f87171' },
      { number: 500, hexcode: '#ef4444' },
      { number: 600, hexcode: '#dc2626' },
      { number: 700, hexcode: '#b91c1c' },
      { number: 800, hexcode: '#991b1b' },
      { number: 900, hexcode: '#7f1d1d' },
    ],
  },
];

/**
 * Use TailwindCSS color palettes as reference to generate a new
 * color palette. Uses the given color to:
 * 1. Find the closest palette in the TailwindCSS palette
 * 2. Find the closes shade in the palette
 * 3. Clone the existing palette and adjust the shade to the new color
 * @param hexcode
 * @returns
 */
export function generatePalette(hexcode: string) {
  try {
    chroma(hexcode);
  } catch (err) {
    return [];
  }

  // calculate the distance between the palette and the given color
  const referenceColorsWithDeltas = tailwindColors.map((palette) => {
    const mapped = {
      ...palette,
      // calculate the delta for each shade
      shades: palette.shades.map((shade) => ({
        ...shade,
        delta: chroma.deltaE(hexcode, shade.hexcode),
        lightnessDiff: null as any,
      })),
      closestShade: null as any,
      closestShadeLightness: null as any,
    };

    // closest shade in the palette to the new color
    mapped.closestShade = mapped.shades.reduce((prev, curr) => {
      return prev.delta < curr.delta ? prev : curr;
    });

    return mapped;
  });

  // find the color family with the lowest delta
  const closestPalette = referenceColorsWithDeltas.reduce((prev, curr) => {
    return prev.closestShade.delta < curr.closestShade.delta ? prev : curr;
  });

  // calculate the lightness difference for each shade between the shade
  // and the new color
  closestPalette.shades = closestPalette.shades.map((shade) => ({
    ...shade,
    lightnessDiff: Math.abs(
      chroma(shade.hexcode).get('hsl.l') - chroma(hexcode).get('hsl.l')
    ),
  }));

  // choose the closest shade based on the lightness
  // later, this will be used to ensure the exact color given is in the palette
  closestPalette.closestShadeLightness = closestPalette.shades.reduce(
    (prev, curr) => {
      return prev.lightnessDiff < curr.lightnessDiff ? prev : curr;
    }
  );

  // calculate hue difference
  const inputHue = chroma(hexcode).get('hsl.h');
  const matchHue = chroma(closestPalette.closestShadeLightness.hexcode).get(
    'hsl.h'
  );

  const hueDifference = inputHue - (matchHue || 0);
  const hueOffset =
    hueDifference === 0
      ? matchHue.toString()
      : hueDifference > 0
      ? `+${hueDifference}`
      : hueDifference.toString();

  // calculate saturation ratio
  const saturationRatio =
    chroma(hexcode).get('hsl.s') /
    chroma(closestPalette.closestShadeLightness.hexcode).get('hsl.s');

  // generate the new palette by cloning each shade and adjusting
  const generatedShades = closestPalette.shades.map((shade) => {
    const newSaturation = chroma(shade.hexcode).get('hsl.s') * saturationRatio;

    let newColor = chroma(shade.hexcode).set('hsl.s', newSaturation).hex();
    newColor = chroma(newColor).set('hsl.h', hueOffset).hex();

    // ensure that there is at least the one color in the palette that is the same as the input color
    if (shade.number === closestPalette.closestShadeLightness.number) {
      newColor = chroma(hexcode).hex();
    }

    return {
      number: shade.number,
      hexcode: newColor,
    };
  });

  return generatedShades;
}
