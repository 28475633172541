import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-separator',
  templateUrl: './sidebar-separator.component.html',
  styleUrls: ['./sidebar-separator.component.scss'],
})
export class SidebarSeparatorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
