import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, of } from 'rxjs';
import { LoadCompany } from '../../core/state/company.state';

@Injectable({
  providedIn: 'root',
})
export class CompanyResolverService {
  constructor(private store: Store) {}

  resolve() {
    return this.store.dispatch(new LoadCompany()).pipe(catchError(() => of()));
  }
}
