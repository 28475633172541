import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  dialogRef = inject(MatDialogRef);

  ngOnInit(): void {
    this.dialogRef.addPanelClass('no-padding-dialog');
  }
}
