import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { CompanyLogoType } from '@prisma/client';
import { CompanyState } from 'src/app/core/state/company.state';

enum Logo {
  REWARDS = 'logo',
  REWARDS_DARK = 'logo-dark',
  BWL = 'logo-bwl',
  BWL_DARK = 'logo-bwl-dark',
}

@UntilDestroy()
@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit, OnChanges {
  public static getLogoUrl(darkMode = false, bwlFlag = false): string {
    let logo = '';
    if (bwlFlag) {
      logo = darkMode ? Logo.BWL_DARK : Logo.BWL;
    } else {
      logo = darkMode ? Logo.REWARDS_DARK : Logo.REWARDS;
    }
    return `assets/images/${logo}.svg`;
  }

  @Input() dark = false;

  url = LogoComponent.getLogoUrl();
  company$ = this.store.select(CompanyState.company);
  bwlFlag = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.company$.pipe(untilDestroyed(this)).subscribe((data) => {
      // move to logo component
      if (data?.companyLogoType === CompanyLogoType.BEYOND_WORK_LIFE) {
        this.bwlFlag = true;
      }
      this.url = LogoComponent.getLogoUrl(this.dark, this.bwlFlag);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.url = LogoComponent.getLogoUrl(this.dark, this.bwlFlag);
  }
}
