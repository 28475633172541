<app-page>
  <ng-container slot="title">Change email</ng-container>
  <ng-container slot="body">
    <app-loader *ngIf="(loaded$ | async) === false"></app-loader>
    <ng-container *ngIf="loaded$ | async">
      <ng-container *ngIf="error$ | async; else success">
        <app-api-error [error]="error$ | async"></app-api-error>
      </ng-container>
      <ng-template #success>
        <div class="flex justify-center">
          <div class="max-w-md px-5 py-4 mt-20 bg-gray-100 rounded-lg">
            <p class="font-bold">Your email was successfully changed</p>
            <p>You will be redirected in 5 seconds...</p>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</app-page>
