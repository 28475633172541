import { Injectable } from '@angular/core';

window.onbeforeprint = () => {
  window.resizeBy(0, 0);
};

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  print(fileName: string, appendDate = true) {
    if (appendDate) {
      const date = `${new Date().getFullYear()}-${new Date()
        .getMonth()
        .toLocaleString(undefined, {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}-${new Date().getDate().toLocaleString(undefined, {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}`;
      fileName = `${fileName}_${date}`;
    }

    // set the page title to set the file name
    const title = document.title;
    document.title = fileName;
    // open the print dialog (this is not async)
    window.print();
    // once the dialog is closed, return the title to its previous value
    document.title = title;
  }
}
