<app-login-screen>
  <form
    [formGroup]="resetForm"
    (ngSubmit)="resetPassword()"
    *ngIf="!hasResetPassword"
  >
    <div class="mb-6">
      <mat-form-field>
        <mat-label>{{ "LOGIN.EMAIL" | translate }}</mat-label>
        <input type="text" disabled matInput [value]="email$ | async" />
      </mat-form-field>
    </div>

    <div class="mb-6">
      <mat-form-field>
        <mat-label>{{ "LOGIN.PASSWORD" | translate }}</mat-label>
        <input type="password" matInput formControlName="password" />
        <mat-error *ngIf="resetForm.controls['password'].hasError('required')">
          {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
        </mat-error>
      </mat-form-field>

      <app-password-strength
        [zxcvbn]="resetForm.controls['password'].getError('zxcvbn')"
        [password]="resetForm.controls['password'].value"
      ></app-password-strength>
    </div>

    <div class="mb-6">
      <mat-form-field>
        <mat-label>{{ "LOGIN.CONFIRM_PASSWORD" | translate }}</mat-label>
        <input type="password" matInput formControlName="passwordRepeat" />
        <mat-error *ngIf="resetForm.controls['password'].hasError('required')">
          {{ "LOGIN.PASSWORD_REQUIRED" | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <app-api-error [error]="authError$ | async"></app-api-error>

    <app-error
      *ngIf="
        resetForm.touched && resetForm.dirty && resetForm.hasError('matches')
      "
    >
      {{ "LOGIN.PASSWORDS_DO_NOT_MATCH" | translate }}
    </app-error>

    <div class="flex items-center justify-between">
      <a mat-button [routerLink]="'/auth/login'">
        {{ "CANCEL" | translate }}
      </a>
      <button
        mat-flat-button
        color="primary"
        type="submit"
        [disabled]="resettingPassword$ | async"
      >
        <span>{{ "LOGIN.SET_PASSWORD" | translate }}</span>
        <app-loading-spinner
          *ngIf="resettingPassword$ | async"
        ></app-loading-spinner>
      </button>
    </div>
  </form>

  <ng-container *ngIf="hasResetPassword">
    <p>
      {{ "LOGIN.PASSWORD_RESET_SUCCESS" | translate }}
    </p>
    <div class="flex justify-center mt-4">
      <button mat-flat-button color="primary" [routerLink]="['/']">
        Return to login
      </button>
    </div>
  </ng-container>
</app-login-screen>
