export interface BaseStateModel {
  loaded: boolean;
  loading: boolean;
  error: Error | null | unknown;
}

export const BASE_STATE_DEFAULTS: BaseStateModel = {
  loaded: false,
  loading: false,
  error: null,
};
