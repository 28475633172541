import { CompanyFeature } from './auth/interfaces/company-feature.enum';
import { UserRole } from './auth/interfaces/user-role.enum';

/**
 * Central definition for different routes. Used to avoid magic strings and
 * to make it easier to change routes in the future.
 */

export interface AppRoute {
  name: string;
  path: string;
  icon: string;
  roles?: UserRole[];
  requiredFeatures?: CompanyFeature[];
}

export const WELCOME: AppRoute = {
  name: 'Welcome',
  icon: 'menu_book',
  path: '/welcome',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.WELCOME],
};

export const OVERVIEW: AppRoute = {
  name: 'Overview',
  icon: 'dashboard',
  path: '/overview',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.OVERVIEW],
};

export const BENEFITS: AppRoute = {
  name: 'Benefits',
  icon: 'handshake',
  path: '/benefits/benefits',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.BENEFITS],
};

export const WORK_LIFE_BENEFITS: AppRoute = {
  name: 'Work-Life Benefits',
  icon: 'volunteer_activism',
  path: '/benefits/work-life-benefits',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.WORK_LIFE_BENEFITS],
};

export const COMPENSATION: AppRoute = {
  name: 'Compensation',
  icon: 'account_balance',
  path: '/compensation',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.COMPENSATION],
};

export const PAID_TIME_OFF: AppRoute = {
  name: 'Paid Time Off',
  icon: 'schedule',
  path: '/paid-time-off',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.PAID_TIME_OFF],
};

export const CONTACT_INFORMATION: AppRoute = {
  name: 'Contact Information',
  icon: 'alternate_email',
  path: '/contact-information',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.CONTACTS],
};

export const STATEMENT: AppRoute = {
  name: '360º Statement',
  icon: 'fact_check',
  path: '/statement',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.STATEMENT],
};

export const NEWS: AppRoute = {
  name: '360º News',
  icon: 'history_edu',
  path: '/news',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.NEWS],
};

export const PAYSLIPS: AppRoute = {
  name: 'Payslips',
  icon: 'receipt_long',
  path: '/payslips',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.PAYSLIPS],
};

export const TAX_FORMS: AppRoute = {
  name: 'Tax Forms',
  icon: 'assignment',
  path: '/tax-forms',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.TAX_FORMS],
};

export const USER_MANAGEMENT: AppRoute = {
  name: 'Manage Users',
  icon: 'people',
  path: '/admin/employees',
  roles: [UserRole.ADMIN],
};

export const COMPENSATION_SETTINGS: AppRoute = {
  name: COMPENSATION.name,
  icon: COMPENSATION.icon,
  path: '/admin/compensation',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.COMPENSATION],
};

export const PAID_TIME_OFF_SETTINGS: AppRoute = {
  name: PAID_TIME_OFF.name,
  icon: PAID_TIME_OFF.icon,
  path: '/admin/paid-time-off',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.PAID_TIME_OFF],
};

export const BENEFITS_SETTINGS: AppRoute = {
  name: BENEFITS.name,
  icon: BENEFITS.icon,
  path: '/admin/benefits',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.BENEFITS],
};

export const WORK_LIFE_BENEFITS_SETTINGS: AppRoute = {
  name: WORK_LIFE_BENEFITS.name,
  icon: WORK_LIFE_BENEFITS.icon,
  path: '/admin/work-life-benefits',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.WORK_LIFE_BENEFITS],
};

export const OVERVIEW_SETTINGS: AppRoute = {
  name: OVERVIEW.name,
  icon: OVERVIEW.icon,
  path: '/admin/overview',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.OVERVIEW],
};

export const CONTACT_INFORMATION_SETTINGS: AppRoute = {
  name: CONTACT_INFORMATION.name,
  icon: CONTACT_INFORMATION.icon,
  path: '/admin/contact-information',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.CONTACTS],
};

export const STATEMENT_SETTINGS: AppRoute = {
  name: STATEMENT.name,
  icon: STATEMENT.icon,
  path: '/admin/statement',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.STATEMENT],
};

export const COMPANY_SETTINGS: AppRoute = {
  name: 'Company',
  icon: 'business',
  path: '/admin/company',
  roles: [UserRole.ADMIN],
};

export const NEWS_SETTINGS: AppRoute = {
  name: NEWS.name,
  icon: NEWS.icon,
  path: '/admin/news',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.NEWS],
};

export const WELCOME_SETTINGS: AppRoute = {
  name: WELCOME.name,
  icon: WELCOME.icon,
  path: '/admin/welcome',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.WELCOME],
};

export const PROCESSING_LOGS: AppRoute = {
  name: 'Processing Logs',
  icon: 'view_timeline',
  path: '/admin/processing-logs',
  roles: [UserRole.ADMIN],
};

export const USER_LOGS: AppRoute = {
  name: 'Access Logs',
  icon: 'history',
  path: '/admin/access-logs',
  roles: [UserRole.ADMIN],
};

export const MANAGE_MY_INFORMATION: AppRoute = {
  name: 'Manage My Information',
  icon: 'account_circle',
  path: '/manage-my-information',
  roles: [UserRole.ADMIN, UserRole.USER],
  requiredFeatures: [CompanyFeature.MANAGE_MY_INFORMATION],
};

export const MANAGE_MY_INFORMATION_SETTINGS: AppRoute = {
  name: MANAGE_MY_INFORMATION.name,
  icon: MANAGE_MY_INFORMATION.icon,
  path: '/admin/manage-my-information',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.MANAGE_MY_INFORMATION],
};

export const CHANGE_APPROVAL: AppRoute = {
  name: 'Change Approval',
  icon: 'inventory',
  path: '/admin/change-approval',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.MANAGE_MY_INFORMATION],
};

export const REPORTING: AppRoute = {
  name: 'Reporting',
  icon: 'analytics',
  path: '/reporting',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.ADMIN_REPORTING],
};

export const REPORTING_TOTAL_REWARDS: AppRoute = {
  name: 'Total Rewards',
  icon: 'dashboard',
  path: '/reporting/total-rewards',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.OVERVIEW],
};

export const REPORTING_BENEFITS: AppRoute = {
  name: 'Benefits',
  icon: 'handshake',
  path: '/reporting/benefits',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.BENEFITS],
};

export const REPORTING_COMPENSATION: AppRoute = {
  name: 'Compensation',
  icon: 'account_balance_wallet',
  path: '/reporting/compensation',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.COMPENSATION],
};

export const REPORTING_ANNUALIZED_COMPENSATION: AppRoute = {
  name: 'Annualized Compensation',
  icon: 'account_balance',
  path: '/reporting/annualized-compensation',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.COMPENSATION],
};

export const REPORTING_PAID_TIME_OFF: AppRoute = {
  name: 'Paid Time Off',
  icon: 'schedule',
  path: '/reporting/time',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.PAID_TIME_OFF],
};

export const REPORTING_PERSONAL_INFORMATION: AppRoute = {
  name: 'Personal Information',
  icon: 'contact_page',
  path: '/reporting/personal-information',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.PERSONAL_INFORMATION],
};

export const REPORTING_PAYMENT_INFORMATION: AppRoute = {
  name: 'Payment Information',
  icon: 'payments',
  path: '/reporting/payment-information',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.PAYMENT_INFORMATION],
};

export const REPORTING_DEPENDENTS: AppRoute = {
  name: 'Dependents',
  icon: 'groups_2',
  path: '/reporting/dependents',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.DEPENDENTS],
};

export const REPORTING_ADDRESS: AppRoute = {
  name: 'Address',
  icon: 'home',
  path: '/reporting/address',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.ADDRESS],
};

export const REPORTING_EMERGENCY_CONTACTS: AppRoute = {
  name: 'Emergency Contacts',
  icon: 'contact_emergency',
  path: '/reporting/emergency-contact',
  roles: [UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.EMERGENCY_CONTACTS],
};

export const MANAGE_MY_PERSONAL_INFORMATION: AppRoute = {
  name: 'Personal Information',
  icon: 'contact_page',
  path: '/manage-my-information/personal-information',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.PERSONAL_INFORMATION],
};

export const MANAGE_MY_ADDRESS: AppRoute = {
  name: 'Address',
  icon: 'home',
  path: '/manage-my-information/address',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.ADDRESS],
};

export const MANAGE_MY_DEPENDENTS: AppRoute = {
  name: 'Dependents',
  icon: 'groups_2',
  path: '/manage-my-information/dependents',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.DEPENDENTS],
};

export const MANAGE_MY_PAYMENT_INFORMATION: AppRoute = {
  name: 'Payment Information',
  icon: 'payments',
  path: '/manage-my-information/payment-information',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.PAYMENT_INFORMATION],
};

export const MANAGE_MY_EMERGENCY_CONTACTS: AppRoute = {
  name: 'Emergency Contacts',
  icon: 'contact_emergency',
  path: '/manage-my-information/emergency-contacts',
  roles: [UserRole.USER, UserRole.ADMIN],
  requiredFeatures: [CompanyFeature.EMERGENCY_CONTACTS],
};

export const APP_ROUTES = {
  WELCOME,
  OVERVIEW,
  BENEFITS,
  WORK_LIFE_BENEFITS,
  COMPENSATION,
  PAID_TIME_OFF,
  CONTACT_INFORMATION,
  STATEMENT,
  NEWS,
  PAYSLIPS,
  TAX_FORMS,
  USER_MANAGEMENT,
  COMPENSATION_SETTINGS,
  PAID_TIME_OFF_SETTINGS,
  BENEFITS_SETTINGS,
  WORK_LIFE_BENEFITS_SETTINGS,
  OVERVIEW_SETTINGS,
  CONTACT_INFORMATION_SETTINGS,
  WELCOME_SETTINGS,
  STATEMENT_SETTINGS,
  COMPANY_SETTINGS,
  NEWS_SETTINGS,
  PROCESSING_LOGS,
  USER_LOGS,
  MANAGE_MY_INFORMATION,
  CHANGE_APPROVAL,
  REPORTING,
  MANAGE_MY_ADDRESS,
  MANAGE_MY_PERSONAL_INFORMATION,
  MANAGE_MY_EMERGENCY_CONTACTS,
  MANAGE_MY_PAYMENT_INFORMATION,
  MANAGE_MY_DEPENDENTS,
  MANAGE_MY_INFORMATION_SETTINGS,
  REPORTING_TOTAL_REWARDS,
  REPORTING_ADDRESS,
  REPORTING_ANNUALIZED_COMPENSATION,
  REPORTING_BENEFITS,
  REPORTING_COMPENSATION,
  REPORTING_DEPENDENTS,
  REPORTING_EMERGENCY_CONTACTS,
  REPORTING_PAID_TIME_OFF,
  REPORTING_PAYMENT_INFORMATION,
  REPORTING_PERSONAL_INFORMATION,
};
