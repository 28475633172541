import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import {
  mapCreatedUpdated,
  mostRecentUpdateOfCollection,
} from '../../interfaces/base.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { Contact, CreateContact } from '../interfaces/contact.interface';

export class LoadContacts {
  static readonly type = '[Contacts] Load Contacts';
}

export class SaveContacts {
  static readonly type = '[Contacts] Save Contacts';
  constructor(public contacts: CreateContact[]) {}
}
export class updateContactsOrder {
  static readonly type = '[Contacts] Update Contacts Order';
  constructor(public contact: any[]) {}
}

export interface ContactsStateModel extends BaseStateModel {
  contacts: Contact[];
}

@State<ContactsStateModel>({
  name: 'contacts',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    contacts: [],
  },
})
@Injectable()
export class ContactsState {
  @Selector()
  static contacts(state: ContactsStateModel) {
    return state.contacts;
  }

  @Selector()
  static loading(state: ContactsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: ContactsStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: ContactsStateModel) {
    return state.error;
  }

  @Selector([ContactsState.contacts])
  static lastUpdated(state: ContactsStateModel, contacts: Contact[]) {
    return mostRecentUpdateOfCollection(contacts);
  }

  constructor(private http: HttpClient) {}

  @Action(LoadContacts)
  async loadContacts(ctx: StateContext<ContactsStateModel>) {
    ctx.patchState({
      loading: true,
      error: null,
    });

    try {
      const contacts = await lastValueFrom(
        this.http.get<Contact[]>(`${environment.apiUrl}/v1/contacts`)
      );

      const mappedContacts = contacts.map((contact) =>
        this.mapContact(contact)
      );

      ctx.patchState({
        contacts: mappedContacts,
      });
    } catch (error) {
      ctx.patchState({
        error,
      });
      throw error;
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  @Action(SaveContacts)
  async saveContacts(
    ctx: StateContext<ContactsStateModel>,
    { contacts }: SaveContacts
  ) {
    ctx.patchState({
      loading: true,
    });

    try {
      const res = await delayRequest(
        lastValueFrom(
          this.http.put<Contact[]>(
            `${environment.apiUrl}/v1/contacts`,
            contacts
          )
        )
      );

      const mappedContacts = res.map((contact) => this.mapContact(contact));

      ctx.patchState({
        contacts: mappedContacts,
      });
    } catch (error) {
      throw error;
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  @Action(updateContactsOrder)
  async updateContactsOrder(
    ctx: StateContext<ContactsStateModel>,
    { contact }: updateContactsOrder
  ) {
    ctx.patchState({
      loading: true,
    });

    try {
      ctx.patchState({
        contacts: contact,
      });
    } catch (error) {
      throw error;
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  private mapContact(contact: Contact) {
    return {
      ...contact,
      ...mapCreatedUpdated(contact),
    };
  }
}
