import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { Company } from 'src/app/paid-time-off/interfaces/company.interface';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { generatePalette } from '../util/palette-generator';

export class SetPalette {
  static readonly type = '[Palette] Set Palette';
  constructor(public paletteRaw: Company['palette']) {}
}

export class SavePalette {
  static readonly type = '[Palette] Save';
  constructor(public name: string, public hex: string) {}
}

export type PaletteName = 'primary' | 'secondary';
const allPalettes: Array<PaletteName> = ['primary', 'secondary'];

export interface PaletteStateModel extends BaseStateModel {
  palettes: {
    [key in PaletteName]: string | null;
  };
}

export const colorMap: { [key in PaletteName]: string } = {
  primary: 'blue',
  secondary: 'rose',
};

const STATE_PALETTE_DEFAULTS = {
  primary: null,
  secondary: null,
  warning: null,
};

@State<PaletteStateModel>({
  name: 'palette',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    palettes: STATE_PALETTE_DEFAULTS,
  },
})
@Injectable()
export class PaletteState {
  @Selector()
  static loading(state: PaletteStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: PaletteStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: PaletteStateModel) {
    return state.error;
  }

  static paletteColor(name: PaletteName) {
    return createSelector(
      [PaletteState],
      (state: PaletteStateModel) => state.palettes[name]
    );
  }

  constructor(private http: HttpClient) {}

  @Action(SavePalette)
  async savePalette(ctx: StateContext<PaletteStateModel>, action: SavePalette) {
    ctx.patchState({
      loading: true,
    });

    try {
      const palettes: { [key in PaletteName]: string | null | undefined } = {
        ...ctx.getState().palettes,
        [action.name]: action.hex,
      };

      await lastValueFrom(
        this.http.put<{ [key in PaletteName]: string | null }>(
          `${environment.apiUrl}/v1/company/palette`,
          palettes
        )
      );

      ctx.patchState({
        palettes: {
          ...ctx.getState().palettes,
          [action.name]: action.hex,
        },
      });

      this.injectPalettes(palettes);
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  /**
   * Inject the palette into the DOM
   */
  private injectPalettes(palettes: {
    [key in PaletteName]: string | null | undefined;
  }) {
    const appRoot = document.body;

    for (const paletteName of allPalettes) {
      let palette = {} as any;
      if (palettes[paletteName]) {
        // generate palette as a key-value map
        palette = generatePalette(palettes[paletteName]!).reduce(
          (acc, shade) => {
            acc[shade.number] = shade.hexcode;
            return acc;
          },
          {} as any
        );
      }

      [50, 100, 200, 300, 400, 500, 600, 700, 800, 900].forEach((shadeCode) => {
        const color = palette[shadeCode];
        const colorName = `--color-${paletteName}-${shadeCode}`;

        if (!color) {
          appRoot.style.removeProperty(colorName);
        } else {
          appRoot.style.setProperty(colorName, color);
        }
      });
    }
  }

  @Action(SetPalette)
  setPalette(ctx: StateContext<PaletteStateModel>, action: SetPalette) {
    if (!action.paletteRaw?.length) {
      ctx.patchState({
        palettes: STATE_PALETTE_DEFAULTS,
      });
      return;
    }
    const palettes = JSON.parse(action.paletteRaw);

    ctx.patchState({
      palettes,
    });

    this.injectPalettes(palettes);
  }
}
