import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export type AppConfigPipeInput = 'appName' | 'isBwl' | 'copyright';

@Pipe({
  name: 'appConfig',
})
export class AppConfigPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(
    value: AppConfigPipeInput,
    ...args: unknown[]
  ): Promise<any> | Observable<any> {
    if (value === 'appName') {
      const appKey = 'APP_NAME.REWARDS';

      return this.translate.get(appKey);
    }

    if (value === 'copyright') {
      const appName = new AppConfigPipe(this.translate).transform('appName');
      const year = new Date().getFullYear();

      return this.translate.get('APP_COPYRIGHT', { appName, year });
    }

    throw new Error('Unknown app config pipe input');
  }
}
