import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';
import { AuthState } from '../../auth/auth.state';
import { CompanyFeature } from '../../auth/interfaces/company-feature.enum';

/**
 * Checks if the current user's company has access to a feature
 */
@Pipe({
  name: 'hasFeature',
})
export class HasFeaturePipe implements PipeTransform {
  private features$ = this.store.select(AuthState.features);
  constructor(private store: Store) {}

  transform(
    features: CompanyFeature[] | null | undefined
  ): Observable<boolean> {
    return this.features$.pipe(
      map(
        (allowedFeatures) =>
          !features?.length ||
          features?.some((feature) => {
            return (allowedFeatures || []).includes(feature);
          })
      )
    );
  }
}
