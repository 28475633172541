import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import {
  BaseStateModel,
  BASE_STATE_DEFAULTS,
} from 'src/app/interfaces/base-state-model.interface';
import {
  mapCreatedUpdated,
  mostRecentUpdateOfCollection,
} from 'src/app/interfaces/base.interface';
import { environment } from 'src/environments/environment';
import { Payslip } from '../interfaces/payslip.interface';

export class LoadPayslips {
  static readonly type = '[Payslip] Load Payslips';
}

export interface PayslipStateModel extends BaseStateModel {
  payslips: Payslip[];
}

@State<PayslipStateModel>({
  name: 'payslip',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    payslips: [],
  },
})
@Injectable()
export class PayslipState {
  @Selector()
  static payslips(state: PayslipStateModel): Payslip[] {
    return state.payslips.slice().sort((a, b) => (a.date < b.date ? 1 : -1));
  }

  @Selector()
  static error(state: PayslipStateModel) {
    return state.error;
  }

  @Selector()
  static loading(state: PayslipStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: PayslipStateModel) {
    return state.loaded;
  }

  @Selector([PayslipState.payslips])
  static lastUpdated(state: PayslipStateModel, payslips: Payslip[]) {
    return mostRecentUpdateOfCollection(payslips);
  }

  constructor(private readonly http: HttpClient) {}

  @Action(LoadPayslips)
  async loadPayslips(ctx: StateContext<PayslipStateModel>) {
    ctx.patchState({ loading: true, error: null });
    try {
      const payslips = await lastValueFrom(
        this.http.get<Payslip[]>(`${environment.apiUrl}/v1/payslips`)
      );

      const mappedPayslips = payslips.map((payslip) => ({
        ...payslip,
        date: new Date(payslip.date),
        ...mapCreatedUpdated(payslip),
      }));

      ctx.patchState({ payslips: mappedPayslips });
    } catch (error) {
      console.error(error);
      ctx.patchState({ error });
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }
}
