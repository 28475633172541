import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject, catchError, map, of } from 'rxjs';
import { ConfirmEmailChange } from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent implements OnInit {
  error$ = new BehaviorSubject(null);
  loaded$ = new BehaviorSubject(false);

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        untilDestroyed(this),
        map((params) => params.get('token'))
      )
      .subscribe((token) => {
        if (!token) return;
        this.submitToken(token);
      });
  }

  private submitToken(token: string) {
    this.store
      .dispatch(new ConfirmEmailChange({ token }))
      .pipe(
        catchError((err) => {
          this.error$.next(err);
          this.loaded$.next(true);
          return of();
        })
      )
      .subscribe(() => {
        this.loaded$.next(true);

        setTimeout(() => {
          this.router.navigate(['/']);
        }, 5000);
      });
  }
}
