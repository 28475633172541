import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcceptInvitePageComponent } from './components/accept-invite-page/accept-invite-page.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { EpiUseSupportAssumeEmployeeComponent } from './components/epi-use-support-assume-employee/epi-use-support-assume-employee.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SsoCallbackPageComponent } from './components/sso-callback-page/sso-callback-page.component';
import { TenantSelectionPageComponent } from './components/tenant-selection-page/tenant-selection-page.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    title: 'Log in',
    canActivate: [LoginGuard],
  },
  {
    path: 'tenant-selection',
    component: TenantSelectionPageComponent,
    title: 'Choose account',
    canActivate: [AuthGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotComponent,
    title: 'Forgot password',
    canActivate: [LoginGuard],
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    title: 'Reset password',
    canActivate: [LoginGuard],
  },
  {
    path: 'sso-callback',
    component: SsoCallbackPageComponent,
    title: 'Logging in...',
    canActivate: [LoginGuard],
  },
  {
    path: 'register/:token',
    component: RegisterComponent,
    title: 'Register account',
    canActivate: [LoginGuard],
  },
  {
    path: 'accept-invite/:token',
    component: AcceptInvitePageComponent,
    title: 'Accept invitation',
  },
  {
    path: 'change-email/:token',
    component: ChangeEmailComponent,
    title: 'Email changed',
  },
  {
    path: 'logout',
    component: LogoutComponent,
    title: 'Logging out',
  },
  {
    path: 'epi-use-support-assume-employee/:token',
    component: EpiUseSupportAssumeEmployeeComponent,
    title: 'EPI-USE Support: Assume Employee',
    canActivate: [LoginGuard],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
