/**
 * Whether an event overlaps or is contained within a given period.
 * Year:     -------|----------------|------
 * Case 0:              |-------|             covered by cases 1 & 2
 * Case 1:                    |----------|
 * Case 2:    |-----------|
 * Case 3:       |---------------------|
 * @param options
 */
export function isWithinPeriod({
  eventStartDate,
  eventEndDate,
  periodStartDate,
  periodEndDate,
}: {
  eventStartDate: Date;
  eventEndDate: Date;
  periodStartDate: Date;
  periodEndDate: Date;
}) {
  return eventStartDate <= periodEndDate && eventEndDate >= periodStartDate;

  // const eventStartsInPeriod =
  //   periodStartDate <= eventStartDate && eventStartDate <= periodEndDate;
  // const eventEndsInPeriod =
  //   periodStartDate <= eventEndDate && eventEndDate <= periodEndDate;
  // const eventOverlapsPeriod =
  //   eventStartDate <= periodStartDate && periodEndDate <= eventEndDate;
  // return eventStartsInPeriod || eventEndsInPeriod || eventOverlapsPeriod;
}
