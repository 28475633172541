/**
 * Delay a user request to give the perception that the app is doing something.
 * Users are more likely to believe that their request has been processed if
 * it was not immediate.
 *
 * This function will wrap the request callback and delay it to a minimum of
 * 800 milliseconds.
 *
 * This should not be used for GET requests, unless refreshing the content.
 */
export async function delayRequest<T>(
  callback: Promise<T>,
  time = 400
): Promise<T> {
  const [res] = await Promise.allSettled([
    callback,
    new Promise((resolve) => setTimeout(resolve, time)),
  ]);

  if (res.status === 'rejected') {
    throw res.reason;
  }

  return res.value;
}
