import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import {
  CompensationConfig,
  CreateCompensationConfig,
} from '../interfaces/compensation-config.interface';

export class LoadCompensationConfig {
  static readonly type = '[CompensationConfig] Load';
}

export class LoadUnConfiguredCompensationData {
  static readonly type = '[CompensationConfig] Load Un-configured Data';
}

export class LoadCompensationPeriods {
  static readonly type = '[CompensationConfig] Load Periods';
}

export class DeleteCompensationForPeriods {
  static readonly type = '[CompensationConfig] Delete Periods';
  constructor(
    public payload: {
      periods: string[];
      payComponentCodes: string[];
      employeeNumbers: string[];
      dryRun: boolean;
    }
  ) {}
}

export class SaveCompensationConfig {
  static readonly type = '[CompensationConfig] Save';
  constructor(
    public payload: { compensationConfig: CreateCompensationConfig[] }
  ) {}
}

export interface CompensationConfigStateModel extends BaseStateModel {
  compensationConfig: CompensationConfig[];
  configWithoutData: string[];
  dataWithoutConfig: string[];
  periods: string[];
  deletePeriodsResult:
    | {
        period: string;
        payComponentCode: string;
        employeeNumber: string;
        deletedCount: number;
      }[]
    | null;
}

@State<CompensationConfigStateModel>({
  name: 'compensationConfig',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    compensationConfig: [],
    configWithoutData: [],
    dataWithoutConfig: [],
    periods: [],
    deletePeriodsResult: null,
  },
})
@Injectable()
export class CompensationConfigState {
  @Selector()
  static compensationConfig(state: CompensationConfigStateModel) {
    return state.compensationConfig;
  }

  @Selector()
  static loading(state: CompensationConfigStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: CompensationConfigStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: CompensationConfigStateModel) {
    return state.error;
  }

  @Selector()
  static configWithoutData(state: CompensationConfigStateModel) {
    return state.configWithoutData;
  }

  @Selector()
  static dataWithoutConfig(state: CompensationConfigStateModel) {
    return state.dataWithoutConfig;
  }

  @Selector()
  static periods(state: CompensationConfigStateModel) {
    return state.periods;
  }

  @Selector()
  static deletePeriodsResult(state: CompensationConfigStateModel) {
    return state.deletePeriodsResult;
  }

  constructor(private http: HttpClient) {}

  @Action(LoadCompensationConfig)
  async loadCompensationConfig(
    ctx: StateContext<CompensationConfigStateModel>
  ) {
    try {
      ctx.patchState({
        loading: true,
      });
      const compensationConfig = await lastValueFrom(
        this.http.get<CompensationConfig[]>(
          `${environment.apiUrl}/v1/compensation-config`
        )
      );

      ctx.patchState({
        compensationConfig,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loaded: true,
        loading: false,
      });
    }
  }

  @Action(SaveCompensationConfig)
  async saveCompensationConfig(
    ctx: StateContext<CompensationConfigStateModel>,
    { payload }: SaveCompensationConfig
  ) {
    const { compensationConfig } = payload;

    try {
      ctx.patchState({
        loading: true,
        error: null,
      });

      const config = await delayRequest(
        lastValueFrom(
          this.http.put<CompensationConfig[]>(
            `${environment.apiUrl}/v1/compensation-config`,
            compensationConfig
          )
        )
      );

      ctx.patchState({
        compensationConfig: config,
      });
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(LoadUnConfiguredCompensationData)
  async loadUnConfiguredCompensationData(
    ctx: StateContext<CompensationConfigStateModel>
  ) {
    try {
      ctx.patchState({
        loading: true,
      });

      const res = await lastValueFrom(
        this.http.get<{
          configWithNoData: string[];
          dataWithoutConfig: string[];
        }>(`${environment.apiUrl}/v1/compensation-config/missing-config`)
      );

      ctx.patchState({
        dataWithoutConfig: res.dataWithoutConfig,
        configWithoutData: res.configWithNoData,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loaded: true,
        loading: false,
      });
    }
  }

  @Action(LoadCompensationPeriods)
  async loadCompensationPeriods(
    ctx: StateContext<CompensationConfigStateModel>
  ) {
    try {
      ctx.patchState({
        loading: true,
      });

      const res = await lastValueFrom(
        this.http.get<string[]>(
          `${environment.apiUrl}/v1/compensation-config/periods`
        )
      );

      ctx.patchState({
        periods: res,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loaded: true,
        loading: false,
      });
    }
  }

  @Action(DeleteCompensationForPeriods)
  async deleteCompensationForPeriods(
    ctx: StateContext<CompensationConfigStateModel>,
    { payload }: DeleteCompensationForPeriods
  ) {
    const { periods, payComponentCodes, employeeNumbers, dryRun } = payload;

    try {
      ctx.patchState({
        loading: true,
        error: null,
      });

      const res = await delayRequest(
        lastValueFrom(
          this.http.post<
            {
              period: string;
              payComponentCode: string;
              employeeNumber: string;
              deletedCount: number;
            }[]
          >(`${environment.apiUrl}/v1/compensation-config/delete-periods`, {
            periods,
            payComponentCodes,
            employeeNumbers,
            dryRun,
          })
        )
      );

      ctx.patchState({
        deletePeriodsResult: res,
      });

      return ctx.dispatch(new LoadCompensationPeriods());
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }
}
