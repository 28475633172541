import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { EmergencyContact } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { ChangeRequest } from '../interfaces/change-request.interface';
import {
  EmergencyContactCreate,
  EmergencyContactUpdate,
} from '../interfaces/emergency-contact.interface';
import { uploadSupportingDocumentation } from '../util/supporting-docs-upload';
import { UpsertChangeRequests } from './change-request.state';

export interface EmergencyContactStateModel extends BaseStateModel {
  emergencyContacts: EmergencyContact[];
}

export class LoadEmergencyContacts {
  static readonly type = '[EmergencyContact] LoadEmergencyContacts';
}

export class UpdateEmergencyContact {
  static readonly type = '[EmergencyContact] UpdateEmergencyContact';
  constructor(
    public emergencyContact: EmergencyContactUpdate,
    public files: File[] = []
  ) {}
}

export class CreateEmergencyContact {
  static readonly type = '[EmergencyContact] CreateEmergencyContact';
  constructor(
    public emergencyContact: EmergencyContactCreate,
    public files: File[] = []
  ) {}
}

export class DeleteEmergencyContact {
  static readonly type = '[EmergencyContact] DeleteEmergencyContact';
  constructor(public emergencyContactId: string, public files: File[] = []) {}
}

@State<EmergencyContactStateModel>({
  name: 'emergencyContact',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    emergencyContacts: [],
  },
})
@Injectable()
export class EmergencyContactState {
  http = inject(HttpClient);

  @Selector()
  static loading(state: EmergencyContactStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: EmergencyContactStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: EmergencyContactStateModel) {
    return state.error;
  }

  @Selector()
  static emergencyContacts(state: EmergencyContactStateModel) {
    return state.emergencyContacts;
  }

  @Action(LoadEmergencyContacts)
  async loadEmergencyContacts(ctx: StateContext<EmergencyContactStateModel>) {
    ctx.patchState({ loading: true });
    try {
      const emergencyContacts = await lastValueFrom(
        this.http.get<EmergencyContact[]>(
          `${environment.apiUrl}/v1/emergency-contact`
        )
      );
      ctx.patchState({ emergencyContacts });
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(UpdateEmergencyContact)
  async updateEmergencyContact(
    ctx: StateContext<EmergencyContactStateModel>,
    action: UpdateEmergencyContact
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await lastValueFrom(
        this.http.put<ChangeRequest>(
          `${environment.apiUrl}/v1/emergency-contact/${action.emergencyContact.id}`,
          action.emergencyContact
        )
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(CreateEmergencyContact)
  async createEmergencyContact(
    ctx: StateContext<EmergencyContactStateModel>,
    action: CreateEmergencyContact
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await lastValueFrom(
        this.http.post<ChangeRequest>(
          `${environment.apiUrl}/v1/emergency-contact`,
          action.emergencyContact
        )
      );

      await uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }

  @Action(DeleteEmergencyContact)
  async deleteEmergencyContact(
    ctx: StateContext<EmergencyContactStateModel>,
    action: DeleteEmergencyContact
  ) {
    ctx.patchState({ loading: true });
    try {
      const changeRequest = await lastValueFrom(
        this.http.delete<ChangeRequest>(
          `${environment.apiUrl}/v1/emergency-contact/${action.emergencyContactId}`
        )
      );

      uploadSupportingDocumentation(action, changeRequest, this.http);

      return ctx.dispatch(new UpsertChangeRequests([changeRequest]));
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({ loading: false, loaded: true });
    }
  }
}
