import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  of,
  takeUntil,
} from 'rxjs';
import { otpMask } from '../../../shared/masks/otp.mask';
import { AuthState, GenerateOtp, LogIn } from '../../auth.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  @Input()
  @Select(AuthState.generatingOtp)
  generatingOtp$!: Observable<boolean>;

  @Select(AuthState.loggingIn)
  loggingIn$!: Observable<boolean>;

  details$ = this.store.select(AuthState.publicCompanyDetails);

  authError$ = new BehaviorSubject(null);

  generatedOtp = false;

  otpMask = otpMask;

  loginForm = this.fb.nonNullable.group({
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    password: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  otpForm = this.fb.nonNullable.group({
    otpCode: new FormControl<string>('', {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6),
      ],
    }),
  });

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  onDestroy$ = new Subject<void>();
  onDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Generate OTP code from email and password
   * @returns
   */
  generateOtp() {
    this.loginForm.markAllAsTouched();
    this.loginForm.markAsDirty();
    if (!this.loginForm.valid) {
      return;
    }

    this.authError$.next(null);

    this.store
      .dispatch(
        new GenerateOtp({
          email: this.loginForm.controls['email'].value,
          password: this.loginForm.controls['password'].value,
        })
      )
      .pipe(
        takeUntil(this.onDestroy$),
        catchError((err) => {
          this.authError$.next(err);
          return of();
        })
      )
      .subscribe({
        next: () => {
          this.generatedOtp = true;
          this.authError$.next(null);
        },
      });
  }

  /**
   * Submit the user's OTP code and verify
   * @returns
   */
  logIn() {
    this.otpForm.markAllAsTouched();
    this.otpForm.markAsDirty();
    if (!this.otpForm.valid) {
      return;
    }

    this.authError$.next(null);

    this.store
      .dispatch(
        new LogIn({
          email: this.loginForm.controls['email'].value,
          password: this.loginForm.controls['password'].value,
          otpCode: this.otpForm.controls['otpCode'].value,
        })
      )
      .pipe(
        takeUntil(this.onDestroy$),
        catchError((err) => {
          this.authError$.next(err);
          return of();
        })
      )
      .subscribe({
        next: () => {
          this.router.navigate(['auth/tenant-selection'], {
            queryParamsHandling: 'preserve',
          });
        },
      });
  }

  lastOtpValue = '';
  transformOtp() {
    const control = this.otpForm.controls['otpCode'];
    if (control.value === this.lastOtpValue) {
      return;
    }

    this.lastOtpValue = control.value;
    control.setValue(control.value.toLocaleUpperCase());
  }

  makeSamlLogo(svg: string) {
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  }
}
