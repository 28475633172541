import { Component, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { combineLatest, filter, lastValueFrom, map } from 'rxjs';
import {
  AssumableEmployee,
  AssumeEmployee,
  AuthState,
  LogOut,
} from '../../../auth/auth.state';
import { ProfilePageComponent } from '../../../auth/components/profile-page/profile-page.component';
import { UserRole } from '../../../auth/interfaces/user-role.enum';
import { APP_ROUTES } from '../../../routes';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogOptions,
  DialogConfirmationButtonRole,
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { CompanyState } from '../../state/company.state';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  private store = inject(Store);
  private router = inject(Router);
  private matDialog = inject(MatDialog);
  private toaster = inject(HotToastService);

  isOpen = false;
  keepExpanded = false;

  routes = APP_ROUTES;

  ADMIN = UserRole.ADMIN;

  company$ = this.store.select(CompanyState.company);

  employeeRole$ = this.store.select(AuthState.role);
  employeeName$ = this.store.select(AuthState.fullName);
  employeeNumber$ = this.store.select(AuthState.employeeNumber);
  employeeEmail$ = this.store.select(AuthState.userEmail);
  accessToken$ = this.store.select(AuthState.accessToken);
  isImpersonating$ = this.store.select(AuthState.isImpersonating);

  year = new Date().getFullYear();

  availableAccounts$ = this.store.select(AuthState.associatedEmployees);
  currentAccount$ = combineLatest([
    this.availableAccounts$,
    this.accessToken$,
  ]).pipe(
    map(
      ([accounts, accessToken]) =>
        accessToken &&
        accounts.find(
          (account) =>
            account.companyId === accessToken.companyId &&
            account.id === accessToken.employeeId
        )
    )
  );
  assumableAccounts$ = combineLatest([
    this.availableAccounts$,
    this.currentAccount$,
  ]).pipe(
    map(([accounts, currentAccount]) =>
      accounts.filter(
        (account) => !currentAccount || account.id !== currentAccount.id
      )
    )
  );

  ngOnInit(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.isOpen = false;
      });
  }

  logOut() {
    const confirmationOptions: ConfirmationDialogOptions = {
      title: 'Sign out?',
      body: 'Are you sure you want to sign out?',
      buttons: [
        {
          text: 'Cancel',
          secondary: true,
          role: DialogConfirmationButtonRole.Cancel,
        },
        {
          text: 'Sign out',
          primary: true,
          role: DialogConfirmationButtonRole.Accept,
        },
      ],
    };
    const confirmationDialog = this.matDialog.open(
      ConfirmationDialogComponent,
      {
        data: confirmationOptions,
      }
    );

    confirmationDialog.afterClosed().subscribe((result) => {
      if (result !== DialogConfirmationButtonRole.Accept) return;
      this.store.dispatch(new LogOut());
    });
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  async assumeEmployee(employee: AssumableEmployee) {
    const dialog = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Switch account?',
        body: `Are you sure you want to switch to account ${employee.firstName} ${employee.lastName} from ${employee.company.name}?`,
        buttons: [
          {
            role: DialogConfirmationButtonRole.Cancel,
            text: 'Cancel',
            secondary: true,
          },
          {
            role: DialogConfirmationButtonRole.Accept,
            text: 'Switch account',
            primary: true,
          },
        ],
      } as ConfirmationDialogOptions,
    });

    const res = await lastValueFrom(dialog.afterClosed());
    if (res !== DialogConfirmationButtonRole.Accept) {
      return;
    }

    this.store
      .dispatch(
        new AssumeEmployee({
          employeeId: employee.id,
          companyId: employee.companyId,
        })
      )
      .pipe(
        untilDestroyed(this),
        this.toaster.observe({
          loading: 'Switching accounts...',
          success: 'Account switched',
          error:
            'An error occurred: unable to switch accounts. Please try again.',
        })
      )
      .subscribe(() => {});
  }

  openAccountSettings() {
    this.matDialog.open(ProfilePageComponent, {
      width: '1280px',
      height: '90vh',
      panelClass: 'no-padding-dialog',
    });
  }
}
