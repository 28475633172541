import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { Welcome } from '../interfaces/welcome.interface';

export class LoadWelcome {
  static readonly type = '[Welcome] Load welcome';
}

export interface WelcomeStateModel extends BaseStateModel {
  welcome: Welcome;
}

@State<WelcomeStateModel>({
  name: 'welcome',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    welcome: {
      benefit: 0,
      workLifeBenefit: 0,
      compensation: 0,
      paidTimeOff: 0,
    },
  },
})
@Injectable()
export class WelcomeState {
  @Selector()
  static welcome(state: WelcomeStateModel) {
    return state.welcome;
  }

  @Selector()
  static total(state: WelcomeStateModel) {
    const welcomeData = state.welcome;

    return (
      welcomeData.compensation +
      welcomeData.benefit +
      welcomeData.workLifeBenefit +
      welcomeData.paidTimeOff
    );
  }

  @Selector()
  static loading(state: WelcomeStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: WelcomeStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: WelcomeStateModel) {
    return state.error;
  }

  constructor(private http: HttpClient) {}

  @Action(LoadWelcome)
  async loadWelcome(ctx: StateContext<WelcomeStateModel>) {
    ctx.patchState({
      loading: true,
      error: null,
    });
    try {
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }
}
