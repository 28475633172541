import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { UserRole } from '@prisma/client';
import { combineLatest, take, tap } from 'rxjs';
import { AuthState } from 'src/app/auth/auth.state';
import { HelpFormComponent } from 'src/app/core/components/help-form/help-form.component';
import { FreshdeskService } from 'src/app/core/services/freshdesk.service';

@Injectable({
  providedIn: 'root',
})
export class HelpServiceTsService {
  employeeRole$ = this.store.select(AuthState.role);
  employeeName$ = this.store.select(AuthState.fullName);
  employeeEmail$ = this.store.select(AuthState.userEmail);

  constructor(
    private store: Store,
    private matDialog: MatDialog,
    private freshdesk: FreshdeskService
  ) {}

  openHelpDialog() {
    this.employeeRole$
      .pipe(
        take(1),
        tap((role) => {
          if (role === UserRole.ADMIN) {
            this.openFreshDeskWidget();
          } else {
            this.matDialog.open(HelpFormComponent, {
              disableClose: true,
            });
          }
        })
      )
      .subscribe();
  }

  private openFreshDeskWidget() {
    combineLatest([this.employeeName$, this.employeeEmail$])
      .pipe(take(1))
      .subscribe(async ([name, email]) => {
        const widget = await this.freshdesk.getWidget();
        widget('identify', 'ticketForm', {
          name,
          email,
        });

        widget('open');
      });
  }
}
