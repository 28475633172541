import { Inject, Injectable } from '@angular/core';

export interface FreshdeskServiceConfig {
  widgetId: string;
  locale?: string;
  callback(FreshworksWidget: any): any;
}

export const FRESHDESK_SERVICE_CONFIG = 'FRESHDESK_SERVICE_CONFIG';

@Injectable({
  providedIn: 'root',
})
export class FreshdeskService {
  constructor(
    @Inject(FRESHDESK_SERVICE_CONFIG) private config: FreshdeskServiceConfig
  ) {
    if (!config.widgetId) {
      throw new Error('widgetId is required');
    }
  }

  async getWidget() {
    if (!(window as any).FreshworksWidget) {
      await this.loadWidget();
    }

    return (window as any).FreshworksWidget;
  }

  private loadWidget() {
    return new Promise<void>((resolve, reject) => {
      const scriptEl = document.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.async = true;
      scriptEl.src = `https://widget.freshworks.com/widgets/${this.config.widgetId}.js`;

      (window as any).fwSettings = {
        widget_id: this.config.widgetId,
        locale: this.config.locale || 'en',
      };

      (window as any).FreshworksWidget ||
        (function () {
          if ('function' != typeof (window as any).FreshworksWidget) {
            let n = function () {
              (n as any)['q'].push(arguments);
            };
            ((n as any)['q'] = []), ((window as any).FreshworksWidget = n);
          }
        })();

      scriptEl.onload = () => {
        this.config.callback((window as any).FreshworksWidget);
        resolve();
      };

      scriptEl.onerror = () => {
        reject();
      };

      document.body.append(scriptEl);
    });
  }
}
