import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxsModule } from '@ngxs/store';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthState } from './auth.state';
import { AcceptInvitePageComponent } from './components/accept-invite-page/accept-invite-page.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { LoginIntroComponent } from './components/login-intro/login-intro.component';
import { LoginScreenComponent } from './components/login-screen/login-screen.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TenantSelectionPageComponent } from './components/tenant-selection-page/tenant-selection-page.component';
import { SsoCallbackPageComponent } from './components/sso-callback-page/sso-callback-page.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { EpiUseSupportAssumeEmployeeComponent } from './components/epi-use-support-assume-employee/epi-use-support-assume-employee.component';

@NgModule({
  declarations: [
    LoginComponent,
    ForgotComponent,
    LoginScreenComponent,
    ResetPasswordComponent,
    RegisterComponent,
    ChangeEmailComponent,
    ProfilePageComponent,
    LogoutComponent,
    LoginIntroComponent,
    TenantSelectionPageComponent,
    AcceptInvitePageComponent,
    SsoCallbackPageComponent,
    PasswordStrengthComponent,
    EpiUseSupportAssumeEmployeeComponent,
  ],
  providers: [],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    NgxsModule.forFeature([AuthState]),
    MatCheckboxModule,
    NgxMaskModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
  ],
  exports: [],
})
export class AuthModule {}
