import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from 'src/app/interfaces/base-state-model.interface';
import { delayRequest } from 'src/app/shared/util/delayed-request.util';
import { environment } from 'src/environments/environment';
import {
  BenefitConfig,
  CreateBenefitConfigInput,
} from '../interfaces/benefit-config.interface';

export class LoadBenefitsConfig {
  static readonly type = '[BenefitsConfig] Load BenefitsConfig';
}

export class LoadUnConfiguredBenefitData {
  static readonly type = '[BenefitsConfig] Load Un-configured Data';
}

export class SaveBenefitsConfig {
  static readonly type = '[BenefitsConfig] Save BenefitsConfig';
  constructor(public benefitsConfig: CreateBenefitConfigInput[]) {}
}

export class LoadBenefitPeriods {
  static readonly type = '[BenefitsConfig] Load Periods';
}

export class DeleteBenefitsForPeriods {
  static readonly type = '[BenefitsConfig] Delete Periods';
  constructor(
    public payload: {
      periods: string[];
      benefitCodes: string[];
      employeeNumbers: string[];
      dryRun: boolean;
    }
  ) {}
}

export interface BenefitsConfigStateModel extends BaseStateModel {
  config: BenefitConfig[];
  configWithoutData: string[];
  dataWithoutConfig: string[];
  periods: string[];
  deletePeriodsResult:
    | {
        period: string;
        code: string;
        employeeNumber: string;
        deletedCount: number;
      }[]
    | null;
}

@State<BenefitsConfigStateModel>({
  name: 'benefitsConfig',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    config: [],
    configWithoutData: [],
    dataWithoutConfig: [],
    periods: [],
    deletePeriodsResult: null,
  },
})
@Injectable()
export class BenefitsConfigState {
  @Selector()
  static config(state: BenefitsConfigStateModel) {
    return state.config;
  }

  @Selector()
  static loading(state: BenefitsConfigStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: BenefitsConfigStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: BenefitsConfigStateModel) {
    return state.error;
  }

  @Selector()
  static configWithoutData(state: BenefitsConfigStateModel) {
    return state.configWithoutData;
  }

  @Selector()
  static dataWithoutConfig(state: BenefitsConfigStateModel) {
    return state.dataWithoutConfig;
  }

  @Selector()
  static periods(state: BenefitsConfigStateModel) {
    return state.periods;
  }

  @Selector()
  static deletePeriodsResult(state: BenefitsConfigStateModel) {
    return state.deletePeriodsResult;
  }

  constructor(private readonly http: HttpClient) {}

  @Action(LoadBenefitsConfig)
  async loadBenefitsConfig(ctx: StateContext<BenefitsConfigStateModel>) {
    try {
      ctx.patchState({ loading: true, error: null });
      const benefitsConfig = await lastValueFrom(
        this.http.get<BenefitConfig[]>(
          `${environment.apiUrl}/v1/benefits-config`
        )
      );
      ctx.patchState({ config: benefitsConfig });
    } catch (error) {
      ctx.patchState({ error });
      throw error;
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  @Action(SaveBenefitsConfig)
  async saveBenefitsConfig(
    ctx: StateContext<BenefitsConfigStateModel>,
    { benefitsConfig }: SaveBenefitsConfig
  ) {
    ctx.patchState({ loading: true });
    try {
      const config = await delayRequest(
        lastValueFrom(
          this.http.put<BenefitConfig[]>(
            `${environment.apiUrl}/v1/benefits-config`,
            benefitsConfig
          )
        )
      );
      ctx.patchState({ config });
    } catch (error) {
      throw error;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(LoadUnConfiguredBenefitData)
  async loadUnConfiguredCompensationData(
    ctx: StateContext<BenefitsConfigStateModel>
  ) {
    try {
      ctx.patchState({
        loading: true,
      });

      const res = await lastValueFrom(
        this.http.get<{
          configWithNoData: string[];
          dataWithoutConfig: string[];
        }>(`${environment.apiUrl}/v1/benefits-config/missing-config`)
      );

      ctx.patchState({
        dataWithoutConfig: res.dataWithoutConfig,
        configWithoutData: res.configWithNoData,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loaded: true,
        loading: false,
      });
    }
  }

  @Action(LoadBenefitPeriods)
  async loadBenefitPeriods(ctx: StateContext<BenefitsConfigStateModel>) {
    try {
      ctx.patchState({
        loading: true,
      });

      const res = await lastValueFrom(
        this.http.get<string[]>(
          `${environment.apiUrl}/v1/benefits-config/periods`
        )
      );

      ctx.patchState({
        periods: res,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loaded: true,
        loading: false,
      });
    }
  }

  @Action(DeleteBenefitsForPeriods)
  async deleteCompensationForPeriods(
    ctx: StateContext<BenefitsConfigStateModel>,
    { payload }: DeleteBenefitsForPeriods
  ) {
    const { periods, benefitCodes, employeeNumbers, dryRun } = payload;

    try {
      ctx.patchState({
        loading: true,
        error: null,
      });

      const res = await delayRequest(
        lastValueFrom(
          this.http.post<
            {
              period: string;
              code: string;
              employeeNumber: string;
              deletedCount: number;
            }[]
          >(`${environment.apiUrl}/v1/benefits-config/delete-periods`, {
            periods,
            benefitCodes,
            employeeNumbers,
            dryRun,
          })
        )
      );

      ctx.patchState({
        deletePeriodsResult: res,
      });

      return ctx.dispatch(new LoadBenefitPeriods());
    } catch (err) {
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }
}
