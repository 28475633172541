import { Component, Input, OnInit } from '@angular/core';
import { ZxcvbnResult, zxcvbn } from '@zxcvbn-ts/core';
import { setUpZxcvbn } from '../../../shared/form-validators/password.validator';

@Component({
  selector: 'app-password-strength',
  template: `
    <!-- score bar -->
    <div
      class="flex justify-between items-center relative rounded h-10 w-full bg-gray-500 shadow-inner px-4 text-sm"
    >
      <div
        class="absolute left-0 z-10 h-full rounded transition-width duration-300 ease-in-out"
        [style.width.%]="(score / 4) * 100"
        [class.bg-red-500]="score <= 1"
        [class.bg-yellow-500]="score >= 2 && score <= 3"
        [class.bg-green-500]="score >= 4"
      ></div>

      <div class="relative text-white z-20">Password strength</div>
      <div
        class="relative z-20 text-white bg-gray-200 bg-opacity-50 backdrop-blur-md rounded px-2 py-1"
      >
        {{ score }} / 4
      </div>
    </div>
    <ng-container *ngIf="result">
      <!-- warning -->
      <div
        *ngIf="result.feedback.warning as warning"
        class="flex items-center mt-2 flex-nowrap text-sm rounded px-4 py-2 bg-red-200 text-red-900 gap-x-2"
      >
        <mat-icon>error_outline</mat-icon>
        <div>{{ warning }}</div>
      </div>
      <!-- suggestions -->
      <div
        *ngIf="
          result.feedback.suggestions?.length &&
          result.feedback.suggestions as suggestions
        "
        class="relative flex flex-col mt-2 text-sm rounded bg-gray-50 py-2 px-4 text-gray-800 gap-y-2 divide-y"
      >
        <div
          *ngFor="let suggestion of suggestions"
          class="flex items-center gap-x-2 py-2"
        >
          <mat-icon class="shrink-0 text-gray-500">lightbulb</mat-icon>
          <div>{{ suggestion }}</div>
        </div>
      </div>
    </ng-container>
  `,
  styles: [],
})
export class PasswordStrengthComponent implements OnInit {
  @Input() zxcvbn: ZxcvbnResult | undefined;
  @Input() password: string | null | undefined;

  get result() {
    return this.zxcvbn ?? zxcvbn(this.password ?? '');
  }

  get score() {
    return this.result.score;
  }

  constructor() {}

  ngOnInit(): void {
    setUpZxcvbn();

    if (!this.zxcvbn) {
      console.warn('PasswordStrengthComponent zxcvbn input is required');
    }
  }
}
