<form (ngSubmit)="submit()" [formGroup]="form">
  <app-dialog>
    <ng-container slot="title">Contact support</ng-container>
    <ng-container slot="subtitle"></ng-container>
    <ng-container slot="body">
      <mat-form-field class="mb-6">
        <mat-label>Subject</mat-label>
        <input
          matInput
          type="text"
          formControlName="subject"
          placeholder="E.g. Missing benefits information"
        />
        <mat-error *ngIf="form.controls['subject'].hasError('required')">
          Subject is required
        </mat-error>
      </mat-form-field>

      <mat-form-field class="mb-6">
        <mat-label>Message</mat-label>
        <textarea
          matInput
          type="text"
          formControlName="message"
          placeholder="E.g. Good day, I am experiencing issues with X"
          class="h-20"
        ></textarea>
        <mat-error *ngIf="form.controls['message'].hasError('required')">
          Message is required
        </mat-error>
      </mat-form-field>

      <app-api-error
        *ngIf="error$ | async"
        [error]="error$ | async"
      ></app-api-error>
    </ng-container>

    <ng-container slot="buttons">
      <button mat-button (click)="cancel()" type="button">Cancel</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="sending"
        type="submit"
      >
        <span>Send</span>
        <app-loading-spinner *ngIf="sending"></app-loading-spinner>
      </button>
    </ng-container>
  </app-dialog>
</form>
