import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss'],
})
export class SidebarItemComponent implements OnInit {
  @Input() link: string | string[] | null = null;
  @Input() icon = 'home_outline';
  @Input() noActive = false; // prevent the link from appearing active

  constructor() {}

  ngOnInit(): void {}
}
