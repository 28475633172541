import { Component, Injectable, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { externalUrls } from '../../../../environments/constants';
import { AuthState } from '../../../auth/auth.state';
import { HelpServiceTsService } from '../../services/help.service.ts.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
@Injectable()
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  employeeRole$ = this.store.select(AuthState.role);
  employeeName$ = this.store.select(AuthState.fullName);
  employeeEmail$ = this.store.select(AuthState.userEmail);

  bwlLogoFlag = false;

  urls = externalUrls;

  constructor(private store: Store, public helpService: HelpServiceTsService) {}

  ngOnInit(): void {}
}
