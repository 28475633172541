import {
  Benefit as BenefitPrisma,
  BenefitType as BenefitTypePrisma,
} from '@prisma/client';

export const BenefitType = BenefitTypePrisma;
export type BenefitType = BenefitTypePrisma;

export type Benefit = Omit<BenefitPrisma, 'paymentDate'> & {
  paymentDate: Date;
  name: string;
  providerName: string;
  providerLink: string;
  documentationLink: string;
  description: string;
  type: BenefitType;
  rank: number;
};
