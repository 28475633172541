import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GlobalDateRangeState } from '../../global-date-range.state';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import {
  mapCreatedUpdated,
  mostRecentUpdateOfCollection,
} from '../../interfaces/base.interface';
import { isWithinPeriod } from '../../shared/util/is-within-period.util';
import { Benefit, BenefitType } from '../interfaces/benefit.interface';

export class LoadBenefits {
  static readonly type = '[Benefits] Load Benefits';
}

export interface BenefitsStateModel extends BaseStateModel {
  benefits: Benefit[];
}

@State<BenefitsStateModel>({
  name: 'benefits',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    benefits: [],
  },
})
@Injectable()
export class BenefitsState {
  @Selector()
  static history(state: BenefitsStateModel) {
    return state.benefits.slice().sort((a, b) => {
      const rankDiff = a.rank - b.rank;
      if (rankDiff !== 0) return rankDiff;

      return (
        b.employerContribution - a.employerContribution ||
        b.employeeContribution - a.employeeContribution
      );
    });
  }

  @Selector([BenefitsState.history])
  static benefits(state: BenefitsStateModel, benefits: Benefit[]) {
    return benefits.filter((benefit) => benefit.type === BenefitType.BENEFIT);
  }

  @Selector([BenefitsState.history])
  static workLifeBenefits(state: BenefitsStateModel, benefits: Benefit[]) {
    return benefits.filter(
      (benefit) => benefit.type === BenefitType.WORK_LIFE_BENEFIT
    );
  }

  @Selector([
    BenefitsState.benefits,
    GlobalDateRangeState.startDate,
    GlobalDateRangeState.endDate,
  ])
  static selectionBenefitsHistory(
    state: BenefitsStateModel,
    benefits: Benefit[],
    startDate: Date,
    endDate: Date
  ) {
    return benefits.filter((benefit) => {
      return isWithinPeriod({
        eventStartDate: benefit.paymentDate,
        eventEndDate: benefit.paymentDate,
        periodStartDate: startDate,
        periodEndDate: endDate,
      });
    });
  }

  @Selector([BenefitsState.selectionBenefitsHistory])
  static selectionBenefitsHistoryTotal(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return benefits.reduce(
      (acc, curr) =>
        acc + curr.employerContribution + curr.employeeContribution,
      0
    );
  }

  @Selector([BenefitsState.selectionBenefitsHistory])
  static selectionBenefitsHistoryEmployerContributionTotal(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return benefits.reduce(
      (acc, benefit) => acc + benefit.employerContribution,
      0
    );
  }

  private static aggregateBenefits(benefits: Benefit[]) {
    const benefitsMap = benefits.reduce((acc, benefit) => {
      const key = `${benefit.code}`;

      if (!acc[key]) {
        acc[key] = {
          ...benefit,
        };
      } else {
        acc[key].employeeContribution += benefit.employeeContribution;
        acc[key].employerContribution += benefit.employerContribution;
      }

      return acc;
    }, {} as Record<string, Benefit>);

    return Object.values(benefitsMap);
  }

  @Selector([BenefitsState.selectionBenefitsHistory])
  static selectionBenefitsHistoryAggregated(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return this.aggregateBenefits(benefits);
  }

  @Selector([BenefitsState.selectionBenefitsHistory])
  static benefitsForSelectionTotalEmployeeContribution(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return benefits.reduce((acc, curr) => acc + curr.employeeContribution, 0);
  }

  @Selector([BenefitsState.selectionBenefitsHistory])
  static benefitsForSelectionTotalEmployerContribution(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return benefits.reduce((acc, curr) => acc + curr.employerContribution, 0);
  }

  @Selector([BenefitsState.selectionWorkLifeBenefitsHistory])
  static workLifeBenefitsForSelectionTotalEmployeeContribution(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return benefits.reduce((acc, curr) => acc + curr.employeeContribution, 0);
  }

  @Selector([BenefitsState.selectionWorkLifeBenefitsHistory])
  static workLifeBenefitsForSelectionTotalEmployerContribution(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return benefits.reduce((acc, curr) => acc + curr.employerContribution, 0);
  }

  @Selector([
    BenefitsState.workLifeBenefits,
    GlobalDateRangeState.startDate,
    GlobalDateRangeState.endDate,
  ])
  static selectionWorkLifeBenefitsHistory(
    state: BenefitsStateModel,
    benefits: Benefit[],
    startDate: Date,
    endDate: Date
  ) {
    return benefits.filter((benefit) => {
      return isWithinPeriod({
        eventStartDate: benefit.paymentDate,
        eventEndDate: benefit.paymentDate,
        periodStartDate: startDate,
        periodEndDate: endDate,
      });
    });
  }

  @Selector([BenefitsState.selectionWorkLifeBenefitsHistory])
  static selectionWorkLifeBenefitsTotal(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return benefits.reduce(
      (acc, curr) =>
        acc + curr.employerContribution + curr.employeeContribution,
      0
    );
  }

  @Selector([BenefitsState.selectionWorkLifeBenefitsHistory])
  static selectionWorkLifeBenefitsHistoryAggregated(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return this.aggregateBenefits(benefits);
  }

  @Selector([BenefitsState.selectionWorkLifeBenefitsHistory])
  static selectionWorkLifeBenefitsTotalEmployeeContribution(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return benefits.reduce((acc, curr) => acc + curr.employeeContribution, 0);
  }

  @Selector([BenefitsState.selectionWorkLifeBenefitsHistory])
  static selectionWorkLifeBenefitsTotalEmployerContribution(
    state: BenefitsStateModel,
    benefits: Benefit[]
  ) {
    return benefits.reduce((acc, curr) => acc + curr.employerContribution, 0);
  }

  @Selector()
  static loading(state: BenefitsStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: BenefitsStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: BenefitsStateModel) {
    return state.error;
  }

  @Selector([BenefitsState.benefits])
  static lastUpdated(state: BenefitsStateModel, benefits: Benefit[]) {
    return mostRecentUpdateOfCollection(benefits);
  }

  constructor(private http: HttpClient) {}

  @Action(LoadBenefits)
  async loadBenefits(ctx: StateContext<BenefitsStateModel>, _: LoadBenefits) {
    ctx.patchState({
      loading: true,
      error: null,
    });

    try {
      const benefits = await lastValueFrom(
        this.http.get<any[]>(`${environment.apiUrl}/v1/benefits`)
      );
      const historyMapped: Benefit[] = benefits.map((benefit) =>
        this.mapBenefit(benefit)
      );
      const mergedHistory =
        this.mergeCompensationForTheSameCodeAndDate(historyMapped);
      ctx.patchState({
        benefits: mergedHistory,
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
      throw err;
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  private mapBenefit(benefit: any): Benefit {
    return {
      ...benefit,
      id: benefit.id,
      code: benefit.code,
      type: benefit.type,
      name: benefit.name || benefit.code,
      employerContribution: benefit.employerContribution,
      employeeContribution: benefit.employeeContribution,
      providerName: benefit.providerName,
      providerLink: benefit.providerLink,
      startDate: new Date(benefit.startDate),
      endDate: new Date(benefit.endDate),
      paymentDate: new Date(benefit.paymentDate),
      ...mapCreatedUpdated(benefit),
    };
  }

  /**
   * We may get multiple compensation records for the same pay component and date.
   * This method merges them into a single record by summing the amounts.
   * @param compensation
   * @returns
   */
  private mergeCompensationForTheSameCodeAndDate(compensation: Benefit[]) {
    const groupedByPayComponentAndDate = compensation.reduce((acc, c) => {
      const key = `${c.code}-${c.paymentDate}`;
      if (acc[key]) {
        acc[key].push(c);
      } else {
        acc[key] = [c];
      }
      return acc;
    }, {} as Record<string, Benefit[]>);

    return Object.values(groupedByPayComponentAndDate)
      .map((payComponentComps) => {
        const [firstComp] = payComponentComps;
        return {
          ...firstComp,
          employeeContribution: payComponentComps.reduce(
            (acc, c) => acc + c.employeeContribution,
            0
          ),
          employerContribution: payComponentComps.reduce(
            (acc, c) => acc + c.employerContribution,
            0
          ),
        };
      })
      .sort((a, b) => {
        return (
          (a.paymentDate ?? a.endDate).getTime() -
          (b.paymentDate ?? b.endDate).getTime()
        );
      });
  }
}
