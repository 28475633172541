import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates that every specified field has a different value.
 * @param fields name of the form fields that should be checked
 */
export function differs(...fields: string[]) {
  const fn = (fg: FormGroup): ValidationErrors | null => {
    let prevValue: any = null;

    return fields.every((fieldName) => {
      const field = fg.get(fieldName);
      if (!field) throw new Error(`Field ${fieldName} not found in form`);

      const value = field?.value;

      if (!prevValue) {
        prevValue = value;
        return true;
      }

      return value !== prevValue;
    })
      ? null
      : ({
          differs: 'ERRORS.PASSWORD_MATCH',
        } as ValidationErrors);
  };

  return fn as ValidatorFn;
}
