import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LogoComponent } from '../logo/logo.component';

@Component({
  selector: 'app-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
})
export class CompanyLogoComponent implements OnInit {
  @Input() dark = false;
  @Input() companyId!: string;
  @Input() isBeyondWorkLife: boolean = false;

  ngOnInit(): void {}

  makeCompanyLogoUrl() {
    return `${environment.apiUrl}/v1/company/${this.companyId}/logo/${
      this.dark ? 'dark' : 'light'
    }`;
  }

  handleError: Function | null = ($event: ErrorEvent) => {
    const el = $event.target as HTMLImageElement;
    el.src = LogoComponent.getLogoUrl(this.dark, this.isBeyondWorkLife);
    this.handleError = null;
  };
}
