import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { BehaviorSubject, catchError, lastValueFrom, of } from 'rxjs';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogOptions,
  DialogConfirmationButtonRole,
} from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { differs } from '../../../shared/form-validators/differs.validator';
import { matches } from '../../../shared/form-validators/matches.validator';
import {
  AuthState,
  ChangeEmail,
  ChangePassword,
  LoadUserDetails,
  RequestAccountDeletion,
  RequestAllUserInformation,
} from '../../auth.state';

@UntilDestroy()
@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit {
  dialogRef: MatDialogRef<ProfilePageComponent> = inject(MatDialogRef);
  private store = inject(Store);
  private fb = inject(FormBuilder);
  private toast = inject(HotToastService);
  private matDialog = inject(MatDialog);

  userDetails$ = this.store.select(AuthState.userDetails);
  userDetailsLoading$ = this.store.select(AuthState.loadingUserDetails);
  userDetailsLoaded$ = this.store.select(AuthState.loadedUserDetails);
  userDetailsError$ = this.store.select(AuthState.userDetailsError);
  emailChangeLoading$ = new BehaviorSubject(false);
  emailChangeError$ = new BehaviorSubject(null);
  passwordChangeLoading$ = new BehaviorSubject(false);
  passwordChangeError$ = new BehaviorSubject(null);

  fetchingUserInformation$ = new BehaviorSubject(false);
  userInformationRequestError$ = new BehaviorSubject(null);

  emailChangeForm = this.fb.group(
    {
      newEmail: new FormControl<string>('', [
        Validators.required,
        Validators.email,
      ]),
      newEmailRepeat: new FormControl<string>('', [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl<string>('', [Validators.required]),
      confirmCorrectEmail: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
      confirmBelongsToMe: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
      confirmUnderstand: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
    },
    {
      validators: [matches('newEmail', 'newEmailRepeat')],
    }
  );

  passwordChangeForm = this.fb.group(
    {
      currentPassword: new FormControl<string>('', [Validators.required]),
      newPassword: new FormControl<string>('', [Validators.required]),
      newPasswordRepeat: new FormControl<string>('', [Validators.required]),
      confirmPassword: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
      confirmUnderstand: new FormControl<boolean>(false, [
        Validators.requiredTrue,
      ]),
    },
    {
      validators: [
        matches('newPassword', 'newPasswordRepeat'),
        differs('currentPassword', 'newPassword'),
      ],
    }
  );

  deleteAccountForm = this.fb.group({
    password: new FormControl<string>('', [Validators.required]),
  });

  ngOnInit(): void {}

  refresh() {
    this.store.dispatch(new LoadUserDetails());
  }

  changeEmail() {
    this.emailChangeError$.next(null);

    this.emailChangeForm.markAsDirty();
    this.emailChangeForm.markAllAsTouched();

    if (!this.emailChangeForm.valid) {
      return;
    }

    this.emailChangeLoading$.next(true);

    this.store
      .dispatch(new ChangeEmail(this.emailChangeForm.value as any))
      .pipe(
        this.toast.observe({
          loading: 'Sending confirmation email...',
          success: 'Confirmation email delivered',
          error: 'An error occurred',
        }),
        untilDestroyed(this),
        catchError((err) => {
          this.emailChangeError$.next(err);
          this.emailChangeLoading$.next(false);
          return of();
        })
      )
      .subscribe(() => {
        this.emailChangeLoading$.next(false);

        const dialogOptions: ConfirmationDialogOptions = {
          title: 'Email change confirmation',
          body: 'An email has been sent to your new email address. Please follow the instructions in the email to confirm the change.',
          buttons: [
            {
              text: 'Close',
              secondary: true,
              role: DialogConfirmationButtonRole.Accept,
            },
          ],
        };
        this.matDialog.open(ConfirmationDialogComponent, {
          data: dialogOptions,
        });
      });
  }

  changePassword() {
    this.passwordChangeError$.next(null);

    this.passwordChangeForm.markAsDirty();
    this.passwordChangeForm.markAllAsTouched();

    if (!this.passwordChangeForm.valid) {
      return;
    }

    this.passwordChangeLoading$.next(true);

    this.store
      .dispatch(new ChangePassword(this.passwordChangeForm.value as any))
      .pipe(
        this.toast.observe({
          loading: 'Awaiting Password Update...',
          success: 'Password Updated',
          error: 'An error occurred',
        }),
        untilDestroyed(this),
        catchError((err) => {
          this.passwordChangeError$.next(err);
          this.passwordChangeLoading$.next(false);
          return of();
        })
      )
      .subscribe(() => {
        this.passwordChangeLoading$.next(false);
        this.dialogRef.close();
      });
  }

  requestAllMyData() {
    this.fetchingUserInformation$.next(true);
    this.store
      .dispatch(new RequestAllUserInformation())
      .pipe(
        untilDestroyed(this),
        catchError((err) => {
          this.userInformationRequestError$.next(err);
          this.fetchingUserInformation$.next(false);
          return of();
        })
      )
      .subscribe((data) => {
        this.fetchingUserInformation$.next(false);
      });
  }

  async requestAccountDeletion() {
    this.deleteAccountForm.markAllAsTouched();
    this.deleteAccountForm.markAsDirty();
    if (!this.deleteAccountForm.valid) return;

    const dialog = await this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Delete account',
        subtitle:
          'Are you sure you want to delete your account? This cannot be undone.',
        body: 'Company administrators will be notified that you wish for your data to be removed.',
        confirmationValue: 'DELETE ACCOUNT',
        buttons: [
          {
            text: 'Cancel',
            secondary: true,
            role: DialogConfirmationButtonRole.Cancel,
          },
          {
            text: 'Delete account',
            primary: true,
            role: DialogConfirmationButtonRole.Accept,
          },
        ],
      } as ConfirmationDialogOptions,
    });

    const res = await lastValueFrom(dialog.afterClosed());
    if (res !== DialogConfirmationButtonRole.Accept) return;

    this.store
      .dispatch(
        new RequestAccountDeletion({
          password: this.deleteAccountForm.value.password as string,
        })
      )
      .pipe(
        untilDestroyed(this),
        this.toast.observe({
          loading: 'Requesting account deletion...',
          success: 'Account deleted',
          error: 'Error requesting account deletion',
        })
      )
      .subscribe();
  }
}
