import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-help-form',
  templateUrl: './help-form.component.html',
  styleUrls: ['./help-form.component.scss'],
})
export class HelpFormComponent implements OnInit {
  form = this.fb.group({
    subject: ['', Validators.required],
    message: ['', Validators.required],
  });
  error$ = new BehaviorSubject<any>(null);

  sending = false;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private dialogRef: MatDialogRef<HelpFormComponent>,
    private toast: HotToastService
  ) {}

  ngOnInit(): void {}

  async submit() {
    this.form.markAsDirty();
    this.form.markAllAsTouched();

    if (!this.form.valid) return;

    try {
      this.sending = true;
      const res = await lastValueFrom(
        this.http.post(`${environment.apiUrl}/v1/help`, this.form.value)
      );
      this.toast.success('Your message has been sent', {
        dismissible: true,
        duration: undefined,
      });
      this.dialogRef.close();
    } catch (err) {
      this.error$.next(err);
      console.error(err);
    } finally {
      this.sending = false;
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
