<ng-container *ngIf="getErrors() as errors">
  <ng-container *ngIf="errors.length > 1">
    <app-error *ngFor="let error of errors">
      {{ error | translate }}
    </app-error>
  </ng-container>
  <app-error *ngIf="errors.length === 1">
    {{ errors[0] | translate }}
  </app-error>
</ng-container>
