import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-intro',
  templateUrl: './login-intro.component.html',
  styleUrls: ['./login-intro.component.scss']
})
export class LoginIntroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
