<div
  class="flex flex-col justify-between w-full overflow-x-hidden max-h-[80vh]"
>
  <!-- content -->
  <div class="relative px-4 mt-4">
    <!-- title -->
    <div class="text-lg font-bold">
      <ng-content select="[slot=title]"></ng-content>
    </div>
    <!-- subtitle -->
    <div class="my-3 font-bold">
      <ng-content select="[slot=subtitle]"></ng-content>
    </div>
    <!-- body -->
    <div class="overflow-auto">
      <ng-content select="[slot=body]"></ng-content>
    </div>
  </div>

  <!-- buttons -->
  <div
    class="sticky bottom-0 flex flex-row justify-end px-4 py-2 mt-4 space-x-2 bg-gray-100"
  >
    <ng-content select="[slot=buttons]"></ng-content>
  </div>
</div>
