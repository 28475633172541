import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { CompanyHoliday } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { mapCreatedUpdated } from '../../interfaces/base.interface';

export interface CompanyHolidayStateModel extends BaseStateModel {
  companyHoliday: CompanyHoliday[];
}

export class LoadCompanyHoliday {
  static readonly type = '[CompanyHoliday] Load Company Holiday';
}

@State<CompanyHolidayStateModel>({
  name: 'companyHoliday',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    companyHoliday: [],
  },
})
@Injectable()
export class CompanyHolidayState {
  private http = inject(HttpClient);

  @Selector()
  static companyHoliday(state: CompanyHolidayStateModel) {
    return state.companyHoliday;
  }

  @Selector()
  static loading(state: CompanyHolidayStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: CompanyHolidayStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: CompanyHolidayStateModel) {
    return state.error;
  }

  @Action(LoadCompanyHoliday)
  async loadCompanyHoliday(ctx: StateContext<CompanyHolidayStateModel>) {
    try {
      ctx.patchState({
        loading: true,
      });

      const holidays = await lastValueFrom(
        this.http.get<CompanyHoliday[]>(
          `${environment.apiUrl}/v1/company-holiday/my`
        )
      );

      ctx.patchState({
        companyHoliday: this.mapHolidays(holidays),
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  private mapHolidays(holidays: any[]): CompanyHoliday[] {
    return holidays.map((holiday) => ({
      ...holiday,
      ...mapCreatedUpdated(holiday),
      date: new Date(holiday.date),
    }));
  }
}
