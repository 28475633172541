import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import type { CompanyHoliday } from '@prisma/client';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  BASE_STATE_DEFAULTS,
  BaseStateModel,
} from '../../interfaces/base-state-model.interface';
import { mapCreatedUpdated } from '../../interfaces/base.interface';
import { delayRequest } from '../../shared/util/delayed-request.util';
import { CompanyHolidayUpdate } from '../interface/company-holiday-update.interface';

export interface CompanyHolidaysConfigStateModel extends BaseStateModel {
  companyHolidays: CompanyHoliday[];
}

export class LoadCompanyHolidaysConfig {
  static readonly type = '[CompanyHolidaysConfig] Load Company Holidays';
}

export class SaveCompanyHolidaysConfig {
  static readonly type = '[CompanyHolidaysConfig] Save Company Holidays';
  constructor(public companyHolidays: CompanyHolidayUpdate[]) {}
}

@State<CompanyHolidaysConfigStateModel>({
  name: 'companyHolidaysConfig',
  defaults: {
    ...BASE_STATE_DEFAULTS,
    companyHolidays: [],
  },
})
@Injectable()
export class CompanyHolidaysConfigState {
  private http = inject(HttpClient);

  @Selector()
  static companyHolidays(state: CompanyHolidaysConfigStateModel) {
    return state.companyHolidays;
  }

  @Selector()
  static loading(state: CompanyHolidaysConfigStateModel) {
    return state.loading;
  }

  @Selector()
  static loaded(state: CompanyHolidaysConfigStateModel) {
    return state.loaded;
  }

  @Selector()
  static error(state: CompanyHolidaysConfigStateModel) {
    return state.error;
  }

  @Action(LoadCompanyHolidaysConfig)
  async loadCompanyHolidays(
    ctx: StateContext<CompanyHolidaysConfigStateModel>
  ) {
    try {
      ctx.patchState({
        loading: true,
      });

      const holidays = await lastValueFrom(
        this.http.get<CompanyHoliday[]>(
          `${environment.apiUrl}/v1/company-holiday`
        )
      );

      ctx.patchState({
        companyHolidays: this.mapHolidays(holidays),
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
    } finally {
      ctx.patchState({
        loading: false,
        loaded: true,
      });
    }
  }

  @Action(SaveCompanyHolidaysConfig)
  async saveCompanyHolidays(
    ctx: StateContext<CompanyHolidaysConfigStateModel>,
    payload: SaveCompanyHolidaysConfig
  ) {
    try {
      ctx.patchState({
        loading: true,
      });

      const res = await delayRequest(
        lastValueFrom(
          this.http.put<any[]>(
            `${environment.apiUrl}/v1/company-holiday`,
            payload.companyHolidays
          )
        )
      );

      ctx.patchState({
        companyHolidays: this.mapHolidays(res),
      });
    } catch (err) {
      ctx.patchState({
        error: err,
      });
    } finally {
      ctx.patchState({
        loaded: true,
        loading: false,
      });
    }
  }

  private mapHolidays(holidays: any[]): CompanyHoliday[] {
    return holidays.map((holiday) => ({
      ...holiday,
      ...mapCreatedUpdated(holiday),
      date: new Date(holiday.date),
    }));
  }
}
